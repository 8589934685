import Header from "../../../../../components/Header/Header"
import Footer from "../../../../../components/Footer/Footer"
import PageTitle from "../../../components/PageTitle"
import SideBarDashbordAdmin from "../../../Sidebar"
import { Link,useNavigate } from "react-router-dom"
import React, {useEffect, useRef,useState} from 'react'
import Swal from 'sweetalert2';
import axios from 'axios'
import dns from "../../../../../utils/dns"
const CreateUserAdmin = () => {
    const Navigate =   useNavigate()//
    const [roles, setRole] = useState([])
    const [selectedRole, setSelectedRole] = useState('');
    const LastNameInput = useRef(null)
    const FirstNameInput = useRef(null)
    const ContactPaiement = useRef(null)
    const phoneNumberInput = useRef(null)
    const EmailInput = useRef(null)
    const passwordInput = useRef(null)
    const passwordInput2 = useRef(null)
    const SchoolInput = useRef(null)
    const sexInput = useRef(null)
    const PrenomsParentsInput = useRef(null)
    const ContactParentsInput = useRef(null)
    const NiveauInput = useRef(null)

    const handleUserTypeChange = (e) => {
        setSelectedRole(e.target.value);
    }

    const submitHandler = async(e) => {
        e.preventDefault()
        const selectedRoleId = roles.find(role => role.libelle_role === selectedRole)?.id;
        const formData = new FormData();
        if (selectedRole === "Proprietaire") {
            formData.append('last_name', LastNameInput.current.value);
            formData.append('first_name', FirstNameInput.current.value);
            formData.append('username', phoneNumberInput.current.value);
            formData.append('numero_telephone', phoneNumberInput.current.value);
            formData.append('email', EmailInput.current.value);
            formData.append('password', passwordInput.current.value);
            formData.append('genre', sexInput.current.value);
            formData.append('role', selectedRoleId);
            formData.append('contact_payement', ContactPaiement.current.value);
        } else {
            formData.append('last_name', LastNameInput.current.value);
            formData.append('first_name',  FirstNameInput.current.value);
            formData.append('numero_telephone', phoneNumberInput.current.value);
            formData.append('email', EmailInput.current.value);
            formData.append('password', passwordInput.current.value);
            formData.append('genre', sexInput.current.value);
            formData.append('username', phoneNumberInput.current.value);
            formData.append('contact_parent',  ContactParentsInput.current.value);
            formData.append('noms_prenoms_parent',  PrenomsParentsInput.current.value);
            formData.append('etablissement',  SchoolInput.current.value);
            formData.append('niveau_etude', NiveauInput.current.value);
            formData.append('role', selectedRoleId);
        }
        const password = passwordInput.current.value
        if (password.length>=8) {
            try {
                const response = await axios.post(dns + '/api/auth/register', formData)
                if (response.status === 201) {
                    Swal.fire({
                        title: 'Création de compte',
                        text: 'Le compte a été créé avec succès!',
                        icon: 'success',
                        button: 'OK',
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading();
                        },
                    })
                    Navigate("/admin/dashbord/users/liste")
                        
                }else {
                    Swal.fire({
                        title:'Création de compte', 
                        text:`Nous avons rencontré un problème lors du traitement de votre requête`, 
                        icon: 'error', 
                        button:'OK',
                        timer: 3000
                    })
                }
                    
            } catch (error) {
                console.error(error);
                Swal.fire({
                    title:'Création de compte', 
                    text:`Nous avons rencontré un problème lors du traitement de votre requête`, 
                    icon: 'error', 
                    button:'OK',
                    timer: 4000
                })
            }
        } else {
            Swal.fire({
                title:'Mot de passe', 
                text:`Le mot de passe doit conténir au moins 8 caractères`, 
                icon: 'error', 
                button:'OK',
                timer: 3000
            })
        }
    }

    const GetRoles = async () => {
        try {
            const response = await axios.get(dns + `/api/auth/role/`)
            if (response.status === 200) {
                //.filter(role => role.libelle_role !== "Admin")
                const rolesSansAdmin = response.data.reverse();
                console.log(rolesSansAdmin)
                setRole(rolesSansAdmin)
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
       GetRoles()
    }, []);
    return (

        <>
            <Header />
            <PageTitle title={"Ajouter un utilisateur"} />
            <section className="gray-simple">
                <div className="container-fluid">
                    <div className="row">
                        <SideBarDashbordAdmin />
                        <div className="col-lg-8 col-md-12">
                            <div id="app">
                                <div className="dashboard-wraper settings crop-avatar">
                                    <div className="form-submit">
                                        <div className="row">
                                            <div className="col-12">
                                                <h4 className="with-actions">Enregsitrer un utilisateur</h4>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <form onSubmit={submitHandler} class="simple-form mt-3">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6">
                                                    <div class="form-group">
                                                        <div class="input-with-icon">
                                                            <input id="first_name" type="text" ref={LastNameInput}  required="required" autoFocus="autofocus" placeholder="Entrer votre prénom" class="form-control" />
                                                            <i class="bi bi-person"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            <div class="col-lg-6 col-md-6">
                                                <div class="form-group">
                                                    <div class="input-with-icon">
                                                        <input id="last_name" type="text" ref={FirstNameInput} required="required" placeholder="Entrer entrer votre nom" class="form-control" />
                                                        <i class="bi bi-person"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6">
                                                <div class="form-group">
                                                    <div class="input-with-icon"><input id="email" type="email" ref={EmailInput}  required="required" placeholder="Entrer votre adresse email" class="form-control" /> <i class="bi bi-envelope"></i></div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div class="form-group">
                                                    <select ref={sexInput} id="gender" class="form-control is-valid" aria-invalid="false">
                                                        <option>Selectioner le genre</option>
                                                        <option value="M">Homme</option>
                                                        <option value="F">Femme</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6">
                                                <div class="form-group">
                                                    <div class="input-with-icon">
                                                        <input id="username" type="text" ref={phoneNumberInput} required="required" placeholder="Entrer votre numéro" class="form-control" />
                                                        <i class="bi bi-phone"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div class="col-lg-6 col-md-6">
                                                <div class="form-group">
                                                    <div class="input-with-icon">
                                                        <input id="username" type="text" ref={PrenomsParentsInput} required="required" placeholder="Veuillez entrer le nom d'un parents" class="form-control" />
                                                        <i class="bi bi-phone"></i>
                                                    </div>
                                                </div>
                                            </div>  */}
                                            {/* {selectedRole === 'Proprietaire' && (
                                                <>
                                                    <div class="col-lg-6 col-md-6">
                                                        <div class="form-group">
                                                            <div class="input-with-icon">
                                                                <input  type="text" ref={ContactPaiement} placeholder="Entrer le contact de paiment" class="form-control" />
                                                            <i class="bi bi-person-fill"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                </> 
                                            )} */}
                                            {selectedRole === "Proprietaire" ?
                                               <>
                                                <div class="col-lg-6 col-md-6">
                                                    <div class="form-group">
                                                        <div class="input-with-icon">
                                                            <input id="username" type="text" ref={ContactPaiement} required="required" placeholder="Entrer le contact de paiement" class="form-control" />
                                                            <i class="bi bi-phone"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                </>
                                               :''}
                                            <div class="col-lg-6 col-md-6">
                                                <div class="form-group">
                                                    <div class="input-with-icon">
                                                        <select class="form-control is-valid" aria-invalid="false" value={selectedRole} onChange={handleUserTypeChange}>
                                                        <option value="etudiant" selected>-- Sectionner un role --</option>
                                                            {roles.map(role => (
                                                                <option key={role?.id} value={role?.libelle_role}>{ role?.libelle_role}</option>
                                                            ))}   
                                                            {/* <option value="etudiant">Etudiant</option>
                                                            <option value="particulier">Particulier</option> */}
                                                        </select> 
                                                        <i class="bi bi-phone"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            {selectedRole === 'Etudiant' && (
                                                <>
                                                    <div class="col-lg-6 col-md-6">
                                                        <div class="form-group">
                                                            <div class="input-with-icon">
                                                                <input  type="text" ref={SchoolInput} placeholder="Votre établissement" class="form-control" />
                                                            <i class="bi bi-person-fill"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6">
                                                    <div class="form-group">
                                                        <div class="input-with-icon">
                                                            <input type="text" ref={PrenomsParentsInput}  placeholder="Entrer le nom d'un parent" class="form-control" />
                                                            <i class="bi bi-phone"></i>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="form-group">
                                                            <select ref={NiveauInput}  class="form-control is-valid" aria-invalid="false">
                                                                <option value="Bts">Bts</option>
                                                                <option value="Licence">Licence</option>
                                                                <option value="Master">Master</option>
                                                                <option value="Doctorat">Doctorat</option>
                                                            </select> 
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <div class="form-group">
                                                            <div class="input-with-icon">
                                                                <input  type="text" ref={ContactParentsInput}  placeholder="Entrer le numéro d'un parent" class="form-control" />
                                                                <i class="bi bi-phone"></i>
                                                            </div>
                                                        </div>
                                                    </div> 
                                                </> 
                                            )}
                                            <div class="col-lg-6 col-md-6">
                                                <div class="form-group">
                                                    <div class="input-with-icon">
                                                        <input id="password" type="password" ref={passwordInput} required="required" placeholder="Entrer votre mot de passe" class="form-control" />
                                                            <i class=" bi bi-lock"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div class="col-lg-6 col-md-6">
                                                <div class="form-group">
                                                    <div class="input-with-icon">
                                                        <input id="password-confirm" type="password" ref={passwordInput2} required="required" placeholder="Confirmer votre mot de passe" class="form-control" />
                                                        <i class="bi bi-lock"></i>
                                                    </div>
                                                </div>
                                            </div> */}
                                            </div>
                                            <div className="form-group mt-3 text-center">
                                                <button type="submit" className="btn btn-lg btn-primary rounded">
                                                    Enregistrer
                                                </button>
                                            </div>
                                        </form>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default CreateUserAdmin
