import React, { useState, useEffect } from 'react';
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Link } from "react-router-dom";
import { InputText } from 'primereact/inputtext';

const DataTablePanneAdmin = ({ pannes }) => {
    console.log(pannes)
    const [loading, setLoading] = useState(true);
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        // Simulate an asynchronous data loading process
        setTimeout(() => {
            setLoading(false);
        }, 2000); // Simulating a 2-second loading time
    }, []);

    const statusBodyTemplate = (rowData) => {
        return rowData.statut_panne === 'en_attente' ? 'En attente' : rowData.statut_panne === 'en_cours' ? 'En cours' : 'Résolue';
    };

    const typePanneBodyTemplate = (rowData) => {
        switch (rowData.type_panne_id) {
            case 1:
                return 'Plomberie';
            case 2:
                return 'Menuiserie';
            case 3:
                return 'Charpenterie';
            case 4:
                return 'Mobiliers';
            case 5:
                return 'Electricité';
            default:
                return '';
        }
    };

    const filteredPannes = pannes.filter((panne) =>
        panne.description_panne.toLowerCase().includes(searchText.toLowerCase()) ||
        panne.client.username.toLowerCase().includes(searchText.toLowerCase()) ||
        panne.logement.quartier_logement.toLowerCase().includes(searchText.toLowerCase()) ||
        panne.date_creation.split('T')[0].includes(searchText) ||
        statusBodyTemplate(panne).toLowerCase().includes(searchText.toLowerCase()) ||
        typePanneBodyTemplate(panne).toLowerCase().includes(searchText.toLowerCase())
    );

    return (
        <>
            <div className="card-body table-responsive">
                <h4>Liste des pannes</h4>
                <Card>
                    {/* <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col">
                            <label htmlFor="searchText">Rechercher :</label>
                            <InputText
                                id="searchText"
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                        </div>
                    </div> */}
                    <DataTable value={filteredPannes} loading={loading} paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }} emptyMessage="Aucune donnée disponible pour le moment">
                        <Column field="client.username" header="Client" />
                        <Column field="logement.quartier_logement" header="Quartier" />
                        {/* <Column field="logement.commune_logement" header="Commune" /> */}
                        <Column field="description_panne" header="Description" />
                        <Column field="date_creation" header="Date" body={(rowData) => rowData?.date_creation?.split('T')[0]} />
                        <Column header="Statut" body={statusBodyTemplate} />
                        {/* <Column header="Type de panne" body={typePanneBodyTemplate} /> */}
                         <Column field="type_panne.libelle_type_panne" header="Type de panne" />
                        <Column body={(rowData) => (
                            <div className="fr-grid-footer-flex-right">
                                <Link to={`/admin/dashbord/pannes/${rowData?.id}/details`} className="prt-view">
                                    <i className="bi bi-eye"></i>
                                </Link>
                            </div>
                        )} />
                    </DataTable>
                </Card>
            </div>
        </>
    )
}

export default DataTablePanneAdmin;
