import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import React ,{useRef,useEffect, useState} from 'react'
import  {useNavigate,Link } from 'react-router-dom'
import axios from 'axios'
import dns from '../utils/dns'
import { TarifsOptions, nombrepieces } from "./helpers"
import ResidenceItem from "../components/ResidenceItemScreen"
import LoadingSpinnerCircular from "../components/spiner"
const Poperties = () => {

    //recherche des residences
    const [residences,setResidences] = useState([])
    const [loading, SetLoading] = useState(true);
    const [allSearch, setAllSearch] = useState('')
    const [minTarif, setMinTarif] = useState(0)
    const [maxTarif, setMaxTarif] = useState(0)
    const [nbPieces, setNbPieces] = useState('')
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        SetLoading(true)
        const FetchResidences = async () => {
            try {
                const {data} = await axios.get(`${dns}/api/logement-list/`,{
                    headers: {
                        'Content-Type': 'application/json',
                        //'Authorization': 'Bearer ' + token
                    },
                })
                const res = data.filter((residence) => residence.libelletypelogement ==="Residences")
                res.sort((a, b) => {
                    // Convertir les dates en objets Date pour pouvoir les comparer
                    const dateA = new Date(a.date_creation);
                    const dateB = new Date(b.date_creation);
                    
                    // Comparer les dates et retourner le résultat du tri
                    return dateB - dateA;
                });
                setResidences(res)
                SetLoading(false)
            } catch (error) {
                 console.log(error)
            }
        }
        FetchResidences()
        },[])

        let filteredResidences = [];

        if (allSearch == '') {
            filteredResidences = residences;
          } else {
            filteredResidences = residences.filter((logement) =>
              Object.values(logement).some(
                (value) => typeof value === 'string' && value.toLowerCase().includes(allSearch.toLowerCase())
              )
            );
        }
        
        if (nbPieces == '') {
            filteredResidences = filteredResidences;
          } else {
            filteredResidences = filteredResidences.filter((logement) => {
                if (logement && logement.nombre_pieces) {
                return logement.nombre_pieces.includes(nbPieces);
                }
                return false;
            });
        }

        if (minTarif == 0 || maxTarif == 0) {
            filteredResidences = filteredResidences;
          } else {
            filteredResidences = filteredResidences.filter((logement) => {
                if (logement && logement.tarif_mensuel) {
                return (Number(logement.tarif_mensuel) >= minTarif && Number(logement.tarif_mensuel) <= maxTarif )
                }
                return false;
            });
        }





    const itemsPerPage = 4; // You can adjust the number of items per page
    const totalPages = Math.ceil(filteredResidences.length / itemsPerPage);
    
    // Step 2: Paginate the data
    const paginatedresidences = filteredResidences.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );
    // Function to handle the pagination navigation
    const handlePageChange = (pageNumber) => {
        // Ensure the page number is within the valid range
        if (pageNumber < 1) {
        pageNumber = 1;
        } else if (pageNumber > totalPages) {
        pageNumber = totalPages;
        }
        setCurrentPage(pageNumber);
    };


    return (
        <>
            <Header />
                <div id="app">
                    <div class="page-title">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-lg-10 col-md-12">
                                    <h1 class="ipt-title">Nos Residences</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section class="gray">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <div class="filter_search_opt">
                                        <a href="javascript:void(0);" className="open_search_menu">
                                            Rechercher un logement
                                            <i class="ml-2 bi bi-house"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12 list-layout">
                                    <div class="row justify-content-center">

                                    <div class="col-lg-12 col-md-12">
                                        <div class="item-sorting-box">
                                            <div class="item-sorting clearfix">                                                
                                                { filteredResidences.length > 0 ? 
                                                    <div class="left-column pull-left">
                                                        <h4 class="m-0">
                                                            {filteredResidences.length} résultats correspondent a votre recherche
                                                        </h4>
                                                    </div>
                                                    :
                                                    <div class="left-column pull-left">
                                                        <h4 class="m-0">
                                                           Liste des residences disponibles
                                                        </h4>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <div class="item-sorting-box">
                                            <div class="item-sorting-box-right">
                                                <div class="sorting-by">
                                                    <select class="form-control ui-select is-valid" onChange={(e) => setNbPieces(e.target.value)} id="type_id" aria-invalid="false">
                                                        <option value={''}>Nombres de pièces / Voir tout</option>
                                                        {nombrepieces.map((nbpiece, index)=>(
                                                            <option key={index} value={nbpiece.value}>{nbpiece.key}</option>
                                                        ))}
                                                    </select>
                                                </div>      
                                            </div>
                                            <div class="item-sorting-box-right">
                                                 <div class="sorting-by">
                                                    <input type="text" value={allSearch} onChange={(e)=> setAllSearch(e.target.value)}  class="form-control select2-hidden-accessible"  placeholder="Rechercher une residence ici...." />
                                                </div>
                                            </div>

                                            
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12">
                                    <div class="item-sorting-box">
                                        <div class="item-sorting-box-right">
                                                 <div class="sorting-by">
                                                
                                                <select class="form-control ui-select is-valid" onChange={(e) => setMinTarif(e.target.value)} id="type_id" aria-invalid="false">
                                                    <option value={0}>Montant minimum / Voir tout</option>
                                                    {TarifsOptions.map((tarif, index)=>(
                                                        <option key={index} value={tarif.value}>{tarif.key}</option>
                                                    ))}
                                                </select>
                                                </div>
                                            </div>

                                            <div class="item-sorting-box-right">
                                                 <div class="sorting-by">
                                                
                                                <select class="form-control ui-select is-valid" onChange={(e) => setMaxTarif(e.target.value)} id="type_id" aria-invalid="false">
                                                    <option value={0}>Montant maximum / Voir tout</option>
                                                    {TarifsOptions.map((tarif, index)=>(
                                                        <option key={index} value={tarif.value}>{tarif.key}</option>
                                                    ))}
                                                </select>
                                                </div> 
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                    <div className="row">
                                        {paginatedresidences.length > 0 ? (
                                            paginatedresidences.map((residence) => (
                                             <ResidenceItem key={residence.id} residence={residence} />
                                            ))
                                        ) : paginatedresidences.length <= 0 ? (
                                            <LoadingSpinnerCircular />
                                        ) : (
                                            filteredResidences.map((residence) => (
                                            <ResidenceItem key={residence.id} residence={residence} />
                                            ))
                                        )}
                                        {filteredResidences.length === 0 && (
                                            <div className="row" role="alert">
                                                <h4 className="text-center">Aucun résultat ne correspond à la recherche.</h4>
                                            </div>
                                        )}
                                    </div>

                                {filteredResidences.length > 4 ?
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <nav class="d-flex justify-content-center pt-3" aria-label="Page navigation">
                                                <nav>
                                                   <ul class="pagination">
                                                    {/* "Previous" button */}
                                                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                        <span
                                                        className="page-link"
                                                        onClick={() => handlePageChange(currentPage - 1)}
                                                        disabled={currentPage === 1}
                                                        >‹</span>
                                                    </li>
                                                    {Array.from({ length: totalPages }).map((_, index) => (
                                                        <li
                                                        key={index}
                                                        className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
                                                        >
                                                        <span
                                                            className="page-link"
                                                            onClick={() => handlePageChange(index + 1)}
                                                        >
                                                            {index + 1}
                                                        </span>
                                                        </li>
                                                    ))}

                                                    {/* "Next" button */}
                                                    <li
                                                        className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}
                                                    >
                                                        <span
                                                        className="page-link"
                                                        onClick={() => handlePageChange(currentPage + 1)}
                                                        disabled={currentPage === totalPages}
                                                        >
                                                        ›
                                                        </span>
                                                    </li> 
                                                </ul>
                                                </nav>
                                            </nav>
                                        </div>
                                    </div> : ''}
                            </div>
                        </div>
                    </div>
                    </section>
                </div>
            <Footer />
        </>
    )
}

export default Poperties