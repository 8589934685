import React, { useEffect, useState } from "react";
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Link } from "react-router-dom";
import { InputText } from 'primereact/inputtext'; // Importez InputText
import { Button } from "primereact/button";
import Swal from "sweetalert2";
import axios from "axios";
import dns from "../../../utils/dns";
import { useSelector } from 'react-redux';
const AdminTableReservations = ({ reservations }) => {
    const [loading, setLoading] = useState(true);
    const [searchText, setSearchText] = useState('');
    const userData = useSelector((state) => state.user.user);
    const token = useSelector((state) => state.user.token);
    useEffect(() => {
        // Simulate an asynchronous data loading process
        setTimeout(() => {
            setLoading(false);
        }, 2000); // Simulating a 2-second loading time
    }, []);

    const statusBodyTemplate = (rowData) => {
        return rowData?.statut_reservation === 'en_attente' ? 'En attente' : rowData?.statut_reservation === 'confirmee' ? 'Confirmée' : 'Annulée';
    };

    const DeleteReservation = (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: true
        })

        swalWithBootstrapButtons.fire({
            title: 'Etes vous sûr de supprimer cette reservation ?',
            text: "Vous ne pourrez pas revenir en arrière !",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirmer',
            cancelButtonText: 'Annuler!',
            reverseButtons: true
        })
        .then((result) => {
            if (result.isConfirmed) {
                axios.delete(dns + `/api/reservation/${id}/`, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    }
                })
                .then((response) => {
                    if (response.status === 204) {
                        Swal.fire({
                            title: 'Suppression de reservation',
                            text: `Votre reservation a été supprimée avec succès`,
                            icon: 'success',
                            button: 'OK',
                            timer: 4000
                        })
                        window.location.reload();
                    } else {
                        Swal.fire({
                            title: 'Suppression de reservation',
                            text: `Nous avons rencontré une erreur lors du traitement de votre requête`,
                            icon: 'error',
                            button: 'OK',
                            timer: 4000
                        })
                    }
                })
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                swalWithBootstrapButtons.fire(
                    'Annulé',
                    "l'action a échoué:)",
                    'warning'
                )
            }
        })
    }


    const actionBodyTemplate = (rowData) => {
        return (
            <div className="fr-grid-footer-flex-right">
                <Link to={`/admin/dashbord/reservations/${rowData?.id}/details`} className="prt-view">
                    <i className="bi bi-eye"></i>
                </Link>
                 <Button type="button" icon="bi bi-trash" className="prt-danger" style={{ marginLeft: '5px' }} onClick={() => DeleteReservation(rowData?.id)} />
            </div>
        );
    };

    return (
        <>
            <div className="card-body table-responsive">
                <h4>Liste des reservations</h4>
                <Card>
                {/* <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col">
                        <label htmlFor="searchText">Rechercher :</label>
                        <InputText
                            id="searchText"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                    </div>
                </div> */}
                    <DataTable value={reservations} loading={loading} paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }} emptyMessage="Aucune donnée disponible pour le moment">
                        <Column field="client.username" header="Client" />
                        <Column field="logement.commune_logement" header="Commune" />
                        <Column field="logement.quartier_logement" header="Logement" body={(rowData) => rowData?.logement?.quartier_logement?.substring(0, 9) + '...'} />
                        <Column field="statut_reservation" header="Statut" body={statusBodyTemplate} />
                        <Column field="date_reservation" header="Réserver le" body={(rowData) => rowData?.date_reservation?.split('T')[0]} />
                        <Column field="date_creation" header="Date"  body={(rowData) => rowData?.date_creation?.split('T')[0]}/>
                        <Column body={actionBodyTemplate} />
                        <Column filter={true} filterPlaceholder="Rechercher..." filterValue={searchText} />
                    </DataTable>
                </Card>
            </div>
        </>
    )
}

export default AdminTableReservations;
