import React, { useEffect, useState } from 'react';
import { GoogleMap, useLoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import Swal from "sweetalert2";
import { GOOGLE_KEY } from '../utils/map';

const libraries = ['places'];

const mapContainerStyle = {
  width: '100%',
  height: '100vh',
};

const Map = ({ position, contentInfo }) => {
  const [location, setLocation] = useState({ lat: 0, lng: 0 });
  const [isDirectionsLoaded, setDirectionsLoaded] = useState(false);
  const [showDefaultMap, setShowDefaultMap] = useState(true);
  const coords = {
    lat: parseFloat(position.split(",")[0]),
    lng: parseFloat(position.split(",")[1]),
  };
  const center = {
    lat: 5.305214214944959, // default latitude
    lng: -4.007436038379436, // default longitude
  };

  const [infoWindowPosition, setInfoWindowPosition] = useState(coords || center);
  const [isInfoWindowOpen, setInfoWindowOpen] = useState(true);
  const handleGetDirections = () => {
    const directionsService = new window.google.maps.DirectionsService();
    const directionsRenderer = new window.google.maps.DirectionsRenderer();

    const map = new window.google.maps.Map(document.getElementById("map"), {
      center: coords ? { lat: coords.lat, lng: coords.lng } : center,
      zoom: 17,
    });

    directionsRenderer.setMap(map);

    const request = {
      origin: { lat: location.lat, lng: location.lng },
      destination: position &&
        typeof position === 'string' &&
        position.includes(',')
        ? coords
        : center,
      travelMode: window.google.maps.TravelMode.DRIVING,
    };

    directionsService.route(request, (result, status) => {
      if (status === window.google.maps.DirectionsStatus.OK) {
        setShowDefaultMap(false);
        directionsRenderer.setDirections(result);
      } else {
          setShowDefaultMap(true);
           directionsRenderer.setMap(map);
            Swal.fire({
                title: 'Calcul d\'itinéraire',
                text: `Impossible de calculer de l'itinéraire pour ce lieu`,
                icon: 'error',
                button: 'OK',
                timer: 3000,
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading();
                }
            });
        console.error("Erreur de calcul de l'itinéraire:", status);
      }
    });
  };

  useEffect(() => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        setLocation({
          ...position,
          lat: latitude,
          lng: longitude,
        });
      });
    } else {
      alert('La géolocalisation n\'est pas prise en charge par votre navigateur.');
    }
  }, []);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_KEY,
    libraries,
  });

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Chargement de la carte...</div>;
  }

  return (
    <>
     
      <div id="map">
        <button onClick={handleGetDirections} className="mb-3 btn btn-primary">Obtenir l'itinéraire</button>
        
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={17}
          center={
            position &&
            typeof position === 'string' &&
            position.includes(',')
              ? coords
              : center
          }
          options={{
            zoomControl: true,
            streetViewControl: true,
            mapTypeControl: true,
            fullscreenControl: true,
            draggable: true
          }}
        >
          <Marker
            position={coords || center}
            onClick={() => {
              setInfoWindowOpen(true);
            }}
          />

          {isInfoWindowOpen && (
            <InfoWindow
              position={infoWindowPosition}
              onCloseClick={() => setInfoWindowOpen(false)}
            >
              <div>{contentInfo}</div>
            </InfoWindow>
          )}
        </GoogleMap>
      </div>
    </>
  );
};

export default Map;
