// reducers/userSlice.js
import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    connected:0,  
    user: {},
    token:''
  },
  reducers: {
    setConnected: (state, action) => {
      state.connected = action.payload.connected;
    },
    setUserToken: (state, action) => {
      state.token = action.payload;
    },
    loginSuccess: (state, action) => {
      state.user = action.payload;
    },
    logout: (state) => {
      state.user = {};
    },
  },
});

export const {setConnected,setUserToken, loginSuccess, logout } = userSlice.actions;

export default userSlice.reducer;
