import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom"
import Swal from "sweetalert2"
import axios from "axios"
import dns from '../utils/dns';
import { useSelector } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';

const DataTableVisites = ({ visites }) => {
    const [loading, setLoading] = useState(true);
    const userData = useSelector((state) => state.user.user);
    const token = useSelector((state) => state.user.token);

    const AnnulerVisite = (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: true
        })

        swalWithBootstrapButtons.fire({
            title: "Etes vous sûr d'annuler cette visite ?",
            text: "Vous ne pourrez pas revenir en arrière !",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirmer',
            cancelButtonText: 'Annuler!',
            reverseButtons: true
        })
        .then((result) => {
            if (result.isConfirmed) {
                axios.delete(dns + `/api/visites/${id}/`, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    }
                })
                .then((response) => {
                    if (response.status === 204) {
                        Swal.fire({
                            title: 'Annulation de visite',
                            text: `Votre visite a été annulée avec succès`,
                            icon: 'success',
                            button: 'OK',
                            timer: 4000
                        })
                        window.location.reload();
                    } else {
                        Swal.fire({
                            title: 'Annulation de visite',
                            text: `Nous avons rencontré une erreur lors du traitement de votre requête`,
                            icon: 'error',
                            button: 'OK',
                            timer: 4000
                        })
                    }
                })
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                swalWithBootstrapButtons.fire(
                    'Annulé',
                    "L'action a échoué :)",
                    'warning'
                )
            }
        })
    }

    useEffect(() => {
        // Simulate an asynchronous data loading process
        setTimeout(() => {
            setLoading(false);
        }, 2000); // Simulating a 2-second loading time
    }, []);

    return (
        <div className="card-body table-responsive">
            <DataTable value={visites}  loading={loading}   paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} emptyMessage="Aucune donnée disponible pour le moment">
                {/* <Column field="id" header="#ID"></Column> */}
                <Column field="lieu_rendez_vous" header="Lieux" body={(rowData) => rowData?.lieu_rendez_vous?.substring(0, 10) +"..."}></Column>
                <Column field="date_visite" header="Date"></Column>
                <Column field="prix_visite" header="Prix"></Column>
                <Column field="observations" header="Observations" body={(rowData) => rowData?.observations?.substring(0, 10) +"..."}></Column>
                <Column field="date_creation" header="Date" body={(rowData) => rowData?.date_creation.split('T')[0]}></Column>
                <Column field="statut_visite" header="Statut"></Column>
                <Column body={(rowData) => (
                    <div className="fr-grid-footer-flex-right">
                        <Link to={`/user/details-visite/${rowData?.id}`} className="prt-view">
                            <i className="bi bi-eye"></i>
                        </Link>
                        <Button type="button" icon="bi bi-trash" className="prt-danger" style={{ marginLeft: '5px' }} onClick={() => AnnulerVisite(rowData?.id)} />
                        {/* <button type="button" icon="bi bi-trash" className="p-button-danger" style={{ marginLeft: '5px' }} onClick={() => AnnulerVisite(rowData.id)} ></button> */}
                    </div>
                )}></Column>
            </DataTable>
        </div>
    )
}

export default DataTableVisites;
