import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import { Link } from "react-router-dom"
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import dns from "../utils/dns";
import banner from '../public/img/banners/banner-svg.jpg'
import ResidenceItem from "../components/ResidenceItemScreen";
import LoadingSpinnerCircular from "../components/spiner";
import { useSelector } from "react-redux"
const Home = () => {
    const [data, setData] = useState([]);
    //const [residences, setResidence] = useState([]);
    const token = useSelector((state)=> state.user.token)
    
    const fetchData = async () => {
        try {
            const response = await axios.get(dns +'/api/logement-list/');
            const dataList = response.data.filter((residence) => residence?.libelletypelogement === "Etudiants").reverse();
            const counts = {};
            for (const logement of dataList) {
                const commune = logement?.commune_logement;

                if (counts[commune]) {
                    counts[commune].count += 1;
                } else {
                    counts[commune] = { count: 1,  image: null };
                }

                // Ajoutez le chemin d'image uniquement si elle n'a pas déjà été ajoutée
                if (!counts[commune].image) {
                    const imageUrl = logement[`image_logement`];
                    if (imageUrl) {
                        counts[commune].image = imageUrl;
                    }
                }
                }

            const listeDeValeurs = Object.keys(counts).map(commune => ({ 
                commune, 
                nb: counts[commune].count, 
                images: counts[commune].image
            }));

            //console.log(listeDeValeurs);
            //setResidence(listeDeValeurs);
            const firstThreeItems = dataList.slice(0, 4);
            setData(firstThreeItems);
            
        } catch (error) {
        console.error(error);
        }
    };

    useEffect(() => {
        fetchData();
        //FecthData();
     }, []);
    return (
        <>
            <Header />
            <div id="app"> 
                <div id="ismain-homes">
                    <div class="ck-content">
                        <div>
                            <div class="image-cover hero-banner"
                                style={{backgroundImage:`url(${banner})` ,backgroundRepeat: 'no-repeat' }}>
                                <div class="container">
                                <div class="row justify-content-center">
                                    <div class="col-lg-9 col-md-11 col-sm-12">
                                        <div class="inner-banner-text text-center" style={{color:'#ffff' }}>
                                            <h2 style={{color:'#ffff' }}>Bienvenue sur Residence IT (résidences partagées)</h2>
                                            <p class="lead-i">Nous mettons à votre diposition des chambres meublées ,situées à quelques minutes de marche de votre établissement,dotées d'équipements fonctionnels vous premettant d'avoir un séjour agréable , et surtout de vous concentrer sur vos études.</p>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <section class="bg-light">
                                <div class="container">
                                <div class="row justify-content-center">
                                    <div class="col-lg-12 text-center">
                                        <div class="sec-heading center">
                                            <h2>Decouvrez toutes nos residences</h2>
                                            <p>Découvrez notre collection exclusive de résidences soigneusement sélectionnées pour votre confort.Explorez nos options et choisissez celle qui correspond le mieux à vos besoins.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row list-layout">
                                    {data.length >0 ? data.map((item, index) => (
                                        <ResidenceItem key={index} residence={item}/>
                                    )):<LoadingSpinnerCircular />
                                    }
                                </div>
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12 text-center">
                                        <Link to="/residence/logement-etudiants/" className="btn btn-theme-light rounded">Voir les résidences</Link>
                                    </div>
                                </div>
                                </div>
                            </section>
                        </div>
                        {/* <div>
                            <section>
                                <div class="container">
                                <div class="row justify-content-center">
                                    <div class="col-lg-7 col-md-10 text-center">
                                        <div class="sec-heading center">
                                            <h2>Residences par communes</h2>
                                            <p>
                                                Nos résidences sont équipées d'une gamme de services et de commodités pour vous garantir pour un séjour sans souci.
                                                Nos propriétés sont conçues pour vous offrir le confort de la maison, même lorsque vous êtes loin.
                                            </p>

                                        </div>
                                    </div>
                                </div>
                                <div class="row list-layout">
                                    {residences.length>0 ? residences.map((residence) => (
                                        <div class="col-lg-4 col-md-4">
                                            <div class="location-property-wrap">
                                                <div class="location-property-thumb">
                                                    <Link to="/ours-residences">
                                                        <img src={residence?.images} style={{height:270 +"px"}}
                                                        class="w-100 lazy" alt={residence?.commune}/>
                                                    </Link>
                                                </div>
                                                    <div class="location-property-content">
                                                        <div class="lp-content-flex">
                                                        <h4 class="lp-content-title">{residence?.commune}</h4>
                                                        <span>
                                                            {residence?.nb}
                                                            {residence?.nb=== 1 ? "  logement" : " logements" }
                                                        </span>
                                                    </div>
                                                    <div class="lp-content-right">
                                                        <Link to="/ours-residences"
                                                            class="lp-property-view">
                                                            <i class="bi bi-arrow-right"></i>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )):<LoadingSpinnerCircular />}
                                </div>
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12 text-center">
                                        <NavLink to="/ours-residences" className="btn btn-theme-light rounded">Voir plus</NavLink>
                                    </div>
                                </div>
                                </div>
                            </section>
                        </div> */}
                        <div>
                            <section id="pricing-section">
                                <div class="container">
                                <div class="row justify-content-center">
                                    <div class="col-lg-7 col-md-10 text-center">
                                        <div class="sec-heading center">
                                            <h2>Tarifs étudiants</h2>
                                            <p>Nos tarifs standard offrent un excellent rapport qualité-prix pour des séjours tout au long de l'année. Profitez de nos résidences haut de gamme et de nos services exceptionnels sans compromettre votre budget. </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-4 col-md-4">
                                        <div class="pricing-wrap  standard-pr ">
                                            <div class="pricing-header">
                                            <h4 class="pr-value">
                                                35.000 cfa
                                            </h4>
                                            <h4 class="pr-title">Offre IT-6</h4>
                                            </div>
                                            <div class="pricing-body">
                                            <ul>
                                                <li class="available">6 par chambre</li>
                                                <li class="available">factures d'eau prise en charge</li>
                                                <li class="available">facture electricité prise en charge</li>
                                                <li class="available">Chambre de grande taille</li>
                                                <li class="available">Nb: ces tarifs inclus(loyer + wifi+ service de menage)</li>
                                            </ul>
                                            </div>
                                            <div class="pricing-bottom">
                                            {!token? <Link to="/auth/login"
                                                class="btn-pricing">Choisir ce forfait</Link> : ""}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4">
                                        <div class="pricing-wrap  platinum-pr recommended ">
                                            <div class="pricing-header">
                                            <h4 class="pr-value">
                                                45.000 cfa
                                            </h4>
                                            <h4 class="pr-title">Offre IT-4</h4>
                                            </div>
                                            <div class="pricing-body">
                                            <ul>
                                                <li class="available">4 personnes par chambre</li>
                                                <li class="available">factures d'eau prise en charge</li>
                                                <li class="available">facture electricité prise en charge</li>
                                                <li class="available">Accès aux commodités</li>
                                                <li class="available">Nb: ces tarifs inclus(loyer + wifi+ service de menage)</li>
                                            </ul>
                                            </div>
                                            <div class="pricing-bottom">
                                            {!token? <Link to="/auth/login"
                                                class="btn-pricing">Choisir ce forfait</Link> : ""}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4">
                                        <div class="pricing-wrap  standard-pr ">
                                            <div class="pricing-header">
                                            <h4 class="pr-value">
                                                50.000 cfa
                                            </h4>
                                            <h4 class="pr-title">Offre IT-3</h4>
                                            </div>
                                            <div class="pricing-body">
                                                <ul>
                                                    <li class="available">3 personnes par chambre</li>
                                                    <li class="available">factures d'eau prise en charge</li>
                                                    <li class="available">facture electricité prise en charge</li>
                                                    <li class="available">Accès aux commodités</li>
                                                    <li class="available">Nb: ces tarifs inclus(loyer + wifi+ service de menage)</li>
                                                </ul>
                                            </div>
                                            <div class="pricing-bottom">
                                            {!token? <Link to="/auth/login"
                                                class="btn-pricing">Choisir ce forfait</Link> : ""}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </section>
                        </div>
                        <div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Home