import Header from "../../../components/Header/Header"
import Footer from "../../../components/Footer/Footer"
import SideBarDashbord from "../common"
import { useSelector, useDispatch } from 'react-redux';
import DataTableVisitesAgent from "../../../components/TableVisiteAgent";
import React, { useEffect, useState } from 'react';
import axios from "axios";
import dns from "../../../utils/dns";
const ListeVisiteAgent = () => {
    const userData = useSelector((state) => state.user.user);
    const token = useSelector((state) => state.user.token);
    const [data, setData] = useState([]);

    

    useEffect(() => {
        ///api/visites/${userData.id}/visites/
        const fetchData = async () => {
            try {
                const response = await axios.get(dns +`/api/visites/proprietaire/${userData.id}/`,{
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
                );
                const sortedData = response.data.sort((a, b) => {
                    // Convertir les dates en objets Date pour pouvoir les comparer
                    const idA = a.id
                    const idB = b.id
                    
                    // Comparer les dates et retourner le résultat du tri
                    return idB - idA;
                });
                setData(sortedData);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    },[token]);
    
    return (
        <>
            <Header />
            <div className="page-title">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <h1 className="ipt-title">Liste des visites</h1>
                            <span className="ipn-subtitle"></span>
                        </div>
                    </div>
                </div>
            </div>
             <section className="bg-light">
                <div className="container-fluid">
                    <div className="row">
                        <SideBarDashbord />
                        <div class="col-lg-8 col-md-12">
                            <div id="app">
                                <DataTableVisitesAgent visites={data} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default ListeVisiteAgent