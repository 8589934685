
import Footer from "../components/Footer/Footer"
import Header from "../components/Header/Header"
import React, {useRef} from 'react'
import Swal from 'sweetalert2';
import axios from 'axios'
import dns from '../utils/dns'
import clearFormFields from "../utils/cleanform";
import Map from "../components/MapComponent"

const Contact = () => {
    const NameInput = useRef(null);
    const EmailInput = useRef(null);
    const SubjectInput = useRef(null);
    const MessageInput = useRef(null);
    const formref = useRef(null);
    const HandleSubmit = async (event) => {
        event.preventDefault();
        const name = NameInput.current.value
        const email = EmailInput.current.value
        const subject = SubjectInput.current.value
        const message = MessageInput.current.value

        const formdata = new FormData();
        if (name !== '' &&  email !== '' && subject !== '' && message !== '') {
            formdata.append("name", name)
            formdata.append("email", email)
            formdata.append("subject", subject)
            formdata.append("message", message)
            clearFormFields(formref)
            try {
                const  response = await axios.post(dns + '/api/contact/', formdata)
                if (response.status === 201) {
                    Swal.fire({
                        title:'Envoie de message', 
                        text:`Votre message à été envoyer avec succes, nous vous repondrons au plus vite`, 
                        icon: 'success', 
                        button:'OK',
                        timer: 4000,
                        timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                        },
                    })
                }
            } catch (error) {
                Swal.fire({
                    title:'Envoie de message', 
                    text:`Nous avons rencontré un problème lors du traitement de votre requête`, 
                    icon: 'error', 
                    button:'OK',
                    timer: 4000,
                    timerProgressBar: true,
                            didOpen: () => {
                                Swal.showLoading()
                            },
                })
            }
        } else {
            Swal.fire({
                title:'Envoie de message', 
                text:`Veuilez remplir tout les champs du formulaire`, 
                icon: 'error', 
                button:'OK',
                timer: 4000
            })
        }
    }
    return (
        <>
            <Header />
            <div id="app" >
                <div class="page-title">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <h1 class="ipt-title">Contactez-nous</h1>
                                <span class="ipn-subtitle"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <section>
                    <div class="container">
                        <div class="row">
                            <div class="col text-center">
                                <div class="sec-heading center">
                                    <h2>Formulaire de contact</h2>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="scontent">
                                    <div class="ck-content">
                                        <div id="contact" className="row">
                                            <div className="col-lg-7 col-md-7">
                                                <form onSubmit={HandleSubmit} className="contact-form generic-form" ref={formref}>
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-6">
                                                            <div className="form-group">
                                                                <input type="text" ref={NameInput} className="form-control simple" placeholder="Entrer votre nom"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6">
                                                            <div className="form-group">
                                                                <input type="email" ref={EmailInput} className="form-control simple" placeholder="Entrer votre adresse email"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="form-group">
                                                            <input type="text" ref={SubjectInput} class="form-control simple" placeholder="Entrer le sujet de votre message"/>
                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <textarea ref={MessageInput} rows="6" minlength="10" className="form-control simple focus:outline-none" placeholder="Ecrivez votre message ici..."></textarea>
                                                    </div>
                                                    <div class="contact-form-group">
                                                        <div class="contact-message contact-success-message" style={{display: "none"}}>
                                                        </div>
                                                        <div class="contact-message contact-error-message" style={{ display: "none" }}></div>
                                                    </div>
                                                    <div class="form-actions form-group text-center">
                                                        <button type="submit" class="btn btn-theme-light-2 rounded">Envoyer le  message</button>
                                                    </div>
                                                </form>
                                            </div>
                                            <div class="col-lg-5 col-md-5">
                                                <div class="contact-info">
                                                    <h2>Avez vous une question?</h2>
                                                    <p>Veuillez nous laisser un message par mail, Nous sommes  disponible de 8h et 17h</p> <br/>
                                                        <div class="cn-info-detail">
                                                            <div class="cn-info-icon">
                                                                <i class="bi bi-house-add"></i>
                                                            </div>
                                                            <div class="cn-info-content">
                                                                <h4 class="cn-info-title">Notre emplacement</h4>
                                                                Treichville, Abidjan
                                                            </div></div>
                                                        <div class="cn-info-detail">
                                                            <div class="cn-info-icon">
                                                                <i class="bi bi-envelope-at"></i>
                                                            </div>
                                                            <div class="cn-info-content">
                                                                <h4 class="cn-info-title">Notre adresse email</h4>
                                                            <a href="mailto:resistanceit@residenceit.com">resistanceit@residenceit.com</a>
                                                            </div>
                                                        </div>
                                                        <div class="cn-info-detail">
                                                            <div class="cn-info-icon">
                                                                <i class="bi bi-phone"></i>
                                                            </div>
                                                            <div class="cn-info-content">
                                                                <h4 class="cn-info-title">Contactez-nous</h4>
                                                                      (+225)   <a href="https://wa.me/2250787089013">07 87 08 90 13</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> 
                                            </div>
                                        <br/>
                                            &nbsp;
                                        <p></p>
                                        <h3>Notre Localisation</h3>
                                        <p></p>
                                        <div style={{height: 400 + 'px', width: 100 + '%', position: "relative", textAlign: "right"}}>
                                            <div style={{width: 100 + "%", overflow: "hidden", background: "none!important" }}>
                                                {/* <Map/> */}
                                                <iframe src="https://maps.google.com/maps?q=Treichville,yamousso Abidjan%20&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed" Scrolling="no" marginheight="0" marginwidth="0" width="100%" height="500" frameborder="0" title="map"></iframe>
                                            </div> 
                                        </div> 
                                        <p></p>
                                        <p>&nbsp;</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div> 
            <Footer />
        </>
    )
}

export default Contact 