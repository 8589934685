import SideBarDashbord from "./common"
import Header from "../../components/Header/Header"
import Footer from "../../components/Footer/Footer"
import axios from "axios"
import dns from "../../utils/dns"
import React, { useEffect, useState } from 'react'
import Swal from "sweetalert2"
import {useNavigate,Link } from "react-router-dom";
import DataTableReservation from "../../components/TableReservation"
import { useSelector } from 'react-redux';

const Reservations = () => {

    const [reservations, SetReservations] = useState([]);
    const userData = useSelector((state) => state.user.user);
    const token = useSelector((state) => state.user.token);
    const Navigate = useNavigate();

    const GetReservations = () => {
        if (token) {
            const response = axios.get(dns + `/api/reservation/${userData.id}/reservations/`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                }
            })
            console.log("response", response)
            response.then((res) => {
                if (res.status === 200) {
                    const responseData = res.data.filter((bien) =>bien.bien==null)
                    responseData.sort((a, b) => {
                        // Convertir les dates en objets Date pour pouvoir les comparer
                        const idA = a.id
                        const idB = b.id
                        
                        // Comparer les dates et retourner le résultat du tri
                        return idB - idA;
                    });
                    SetReservations(responseData)
                } 
            })
            .catch(error => {
                console.error("nous avons rencontrer une erreur:", error)
            })
        } else {
            Swal.fire({
                title: 'Connexion requise',
                text: `Vous devez être connecté pour acceder a cette vue`,
                icon: 'error',
                button: 'OK',
                timer: 4000
            })
            .then(
                Navigate("/auth/login")
            )
        }
    }

    useEffect(() => {
        GetReservations();
    }, [])
    return (
        <>
            <Header />
            <div class="page-title">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <h1 class="ipt-title">Liste des réservations</h1>
                            <span class="ipn-subtitle"></span>
                        </div>
                    </div>
                </div>
            </div>
            <section className="gray-simple">
                <div className="container-fluid">
                    <div className="row">
                        <SideBarDashbord />
                        <div className="col-lg-8 col-md-12">
                            <div id="app">
                                <div className="row">
                                    <DataTableReservation  reservations={reservations}/>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Reservations;