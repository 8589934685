import Footer from "../components/Footer/Footer"
import Header from "../components/Header/Header"
import about from '../public/img/sb.png'
const About = () => {
    return (
        <>
            <Header />
                <div id="app">
                    <div class="page-title">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                <h1 class="ipt-title">A propos de nous</h1>
                                <span class="ipn-subtitle">Qui nous sommes et notre mission  ?</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section>
                        <div class="container p-0">
                             <div class="row">
                                <div class="col-sm-12">
                                    <div class="scontent">
                                        <div class="ck-content">
                                                <div class="raw-html-embed">
                                                    <section>
                                                        <div class="container">
                                                            <div class="row align-items-center">

                                                                <div class="col-lg-6 col-md-6">
                                                                    <img src={about} class="img-fluid" alt="" />
                                                                </div>
                                                                
                                                                <div class="col-lg-6 col-md-6">
                                                                    <div class="story-wrap explore-content">
                                                                        <h2>Notre Histoire</h2>
                                                                        <p className='mb-6 text-3xl md:text-5xl lg:text-6xl leading-tight font-bold tracking-tight' >Nous sommes ce que vous recherchez en matiere de <span className='text-blue-500 font-bold'>logement estudiantin</span> en Côte d'ivoire. </p>
                                                                        <p>Lancé depuis septembre 2020 avec 18 étudiants , Résidence IT connaît un bon succès, Nous hébergeons aujourd’hui plus d'une centaines d'étudiants dans nos locaux.
                                                                        Nous disposons actuellement de cinq (5) résidences estudiantinnes dans la zone d'Abidjan sud. Aujourd'hui résidence IT est la destination favorite des étudiants de nombreuses école tels que l'ESATIC et l'INFAS.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </div>
                                            </div>
                                            <div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="scontent">
                                        <div class="ck-content">
                                            <div class="raw-html-embed">
                                                <section>
                                                    <div class="container">
                                                        <div class="row">
                                                            <div class="col-lg-12 col-md-12">
                                                            <div class="sec-heading center">
                                                                <h2>Comment ça <span className='text-blue-500 font-bold'> marche</span>  ?</h2>
                                                                <h5>Ici ,sont descrits les differentes etapes pour avoir accés a residence IT. </h5>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                    <div class="icon-mi-left">
                                                                    
                                                                    <div class="icon-mi-left-content">
                                                                        <h4>Chercher une résidence <br /><br /><i class="bi bi-search theme-cl"></i></h4>
                                                                        <p>
                                                                            La première étape consiste a vérifier en fonction de l'emplacement de votre établissement et de votre budget, la disponibilité d'une résidence Les résidences des hommes et des femmes sont distinctes. Vous devez bien évidemment choisir la residence en tenant compte de ce paramètre . 
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="icon-mi-left">
                                                                    
                                                                    <div class="icon-mi-left-content">
                                                                        <h4>Choisir son forfait <br /><i class="bi bi-house-up-fill"></i> </h4>
                                                                            <p>
                                                                            ➢ Offre IT-6

                                                                                6 par chambre, factures d'eau et electricité prises en charge

                                                                                35.000cfa / mois / personne
                                                                                <br />
                                                                                ➢ Offre IT-4

                                                                                4 par chambre ,factures d'eau et electricité prises en charge

                                                                                45.000fcfa / mois / personne
                                                                                <br />
                                                                                ➢ Offre IT-3

                                                                                3 par chambre, factures d'eau et electricité prises en charge

                                                                                50.000fcfa / mois / personne 
                                                                            </p>
                                                                    </div>
                                                                </div> 
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="icon-mi-left">
                                                                    
                                                                    <div class="icon-mi-left-content">
                                                                        <h4>Enregistrer ses coordonnées <br /><br /><i class="bi bi-person-fill-add"></i></h4>
                                                                        <p>
                                                                            Prenez le soin d'enregistrer soigneusement vos coordonnées et surtout de préciser votre établissement. Tâchez aussi de mettre un mail et un contact valide afin que vous puissiez être contacter en cas de nécessité.  
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="icon-mi-left">
                                                                    
                                                                    <div class="icon-mi-left-content">
                                                                        <h4>Se rencontrer et signer les contrats <br /><br /><i class="bi bi-pencil-square"></i></h4>
                                                                        <p>
                                                                            La dernière étape consiste à visiter votre chambre choisir un lit, effectuer les paiements et signer le contrat.
                                                                            Aussitôt vous recevez  les clés et un reçu de paiement.
                                                                            Si vous êtes hors de la ville, pas de panique. Vous avez également la possibilité d'effectuer vos inscriptions à travers la plateforme, sans vous déplacer.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            <Footer />
        </>
    )
}

export default About