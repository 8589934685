// eslint-disable-next-line
import SideBarDashbord from "./common"
import Header from "../../components/Header/Header"
import Footer from "../../components/Footer/Footer"
import React, { useEffect ,useState,useRef} from 'react'
import { useNavigate } from 'react-router-dom'
import dns from "../../utils/dns"
import Swal from 'sweetalert2'
import axios from "axios"
import clearFormFields from "../../utils/cleanform"
import { useSelector } from 'react-redux';

const UpdatePassword = () => {
    const Navigate = useNavigate();
    const Old_passwordpasswordInput = useRef(null);
    const passwordInput1 = useRef(null);
    const passwordInput2 = useRef(null);
    const formRef = useRef(null);
    const token = useSelector((state) => state.user.token);
    const HandleSubmit = (event) => {
          event.preventDefault(); // Prevent default form submission
        const formData = new FormData();
        const old_password = Old_passwordpasswordInput.current.value;
        const password1 = passwordInput1.current.value;
        const password2 = passwordInput2.current.value;
        formData.append("old_password", old_password);
        formData.append("new_password", password1);
        
        if (password1 === password2) {
            try {
                const response = axios.put(dns + '/api/auth/password/change/', formData,{
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                response.then(
                    (res) => {
                        if (res.status === 200) {
                            clearFormFields(formRef)
                            Swal.fire({
                                title:'Mise à jour du mot de passe', 
                                text:`Votre mot de passe à été modifié avec succès`, 
                                icon: 'success', 
                                button:'OK',
                                timer: 3000,
                                timerProgressBar: true,
                                didOpen: () => {
                                    Swal.showLoading()
                                },
                            }).then(Navigate("/user/profile"))

                        } else {
                            Swal.fire({
                                title:'Mise à jour du mot de passe', 
                                text:`Informations non valide, veuillez réessayer`, 
                                icon: 'error', 
                                button:'OK',
                                timer: 4000
                            })
                        }
                    }
                )
                .catch(error => {
                    console.log(error)
                    Swal.fire({
                        title:'Mise à jour du mot de passe', 
                        text:`Le serveur à rencontrer une erreur lors du traitement de votre requête`, 
                        icon: 'error', 
                        button:'OK',
                        timer: 4000
                    })
                })

            } catch {
                Swal.fire({
                    title:'Mise à jour du mot de passe', 
                    text:`Nous avons rencontrer une erreur lors du traitement de votre requête`, 
                    icon: 'error', 
                    button:'OK',
                    timer: 4000
                })
            }
        } else {
            Swal.fire({
                title:'Mise à jour du mot de passe', 
                text:`Les mots de passe ne sont pas identiques...veuillez réessayer`, 
                icon: 'error', 
                button:'OK',
                timer: 4000
            })
        }
    }

    useEffect(() => { 
      ///
    }, [])

    return (
        <>
            <Header />
            <div className="page-title">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <h1 className="ipt-title">Mot de passe</h1>
                            <span className="ipn-subtitle"></span>
                        </div>
                    </div>
                </div>
            </div>
             <section className="bg-light">
                <div className="container-fluid">
                    <div className="row">
                            <SideBarDashbord />
                            <div className="col-lg-8 col-md-12">
                                <div id="app">
                                    <div className="dashboard-wraper settings crop-avatar">
                                        <div className="form-submit">
                                            <div className="row">
                                                <div className="col-12">
                                                    <h4 className="with-actions">Veuillez remplir le formulaire</h4>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-8 order-lg-0">
                                                    <form onSubmit={HandleSubmit}  ref={formRef}>
                                                        <div class="form-group">
                                                            <label for="password">Mot de passe actuel</label>
                                                            <input type="password" ref={Old_passwordpasswordInput} id="password" required="required"  class="form-control"  placeholder="*********************************" />
                                                        </div>
                                                        <div class="form-group">
                                                            <label for="password1">Nouveau mot de passe</label>
                                                            <input type="password" ref={passwordInput1} id="password1" required="required"  class="form-control"  placeholder="*********************************" />
                                                        </div>
                                                        <div class="form-group">
                                                            <label for="password2">Confirmation du mot de passe</label>
                                                            <input type="password" ref={passwordInput2} id="password2" required="required"  class="form-control"  placeholder="*********************************" />
                                                        </div>
                                                        <button type="submit" className="btn btn-primary fw6">Modifier</button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
    
}

export default UpdatePassword;