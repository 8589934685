import axios from "axios"
import dns from "../../utils/dns"
import React, { useEffect, useState } from 'react'
import HotelItem from "../HotelItemSreen"
import HotelItemOne from "../HotelItemOneScreen"
import LoadingSpinnerCircular from "../spiner"
import { TarifsOptions, nombrepieces } from "../../screens/helpers"

const ResidenceSideBar = () => {
    const [hotels, setHotels] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [allSearch, setAllSearch] = useState('')
    const [minTarif, setMinTarif] = useState(0)
    const [maxTarif, setMaxTarif] = useState(0)
    const [nbPieces, setNbPieces] = useState('')

    const GetHotels = async () => {
        const response = axios.get(dns + `/api/logement-list/`)
        response.then((res) => {
            if (res.status === 200) {
                const data = res.data.filter((hotel) => hotel.libelletypelogement ==="Hotels")
              
                data.sort((a, b) => {
                    // Convertir les dates en objets Date pour pouvoir les comparer
                    const dateA = new Date(a.date_creation);
                    const dateB = new Date(b.date_creation);
                    
                    // Comparer les dates et retourner le résultat du tri
                    return dateB - dateA;
                });
                setHotels(data);
            }
        })
        .catch(error => {
            console.error("error: ", error)
        })
    }

    useEffect(() => {
        GetHotels();
    },[])

    let filteredHotels = [];

        if (allSearch == '') {
            filteredHotels = hotels;
          } else {
            filteredHotels = hotels.filter((logement) =>
              Object.values(logement).some(
                (value) => typeof value === 'string' && value.toLowerCase().includes(allSearch.toLowerCase())
              )
            );
        }
        
        if (nbPieces == '') {
            filteredHotels = filteredHotels;
          } else {
            
            filteredHotels = filteredHotels.filter((logement) => {
                if (logement && logement.nombre_pieces) {
                return logement.nombre_pieces.includes(nbPieces);
                }
                return false;
            });
        }

        if (minTarif == 0 || maxTarif == 0) {
            filteredHotels = filteredHotels;
          } else {
            
            filteredHotels = filteredHotels.filter((logement) => {
                if (logement && logement.tarif_mensuel) {
                return (logement.tarif_mensuel >= minTarif && logement.tarif_mensuel <= maxTarif )
                }
                return false;
            });
        }
        
    const itemsPerPage = 4; 
    const totalPages = Math.ceil(filteredHotels.length / itemsPerPage);
    // Step 2: Paginate the data
    const paginatedHotels = filteredHotels.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );
    // Function to handle the pagination navigation
     const handlePageChange = (pageNumber) => {
        // Ensure the page number is within the valid range
        if (pageNumber < 1) {
        pageNumber = 1;
        } else if (pageNumber > totalPages) {
        pageNumber = totalPages;
        }
        setCurrentPage(pageNumber);
    };

    return (
        <>
            <div class="page-title">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <h1 class="ipt-title">Nos Hotels</h1>
                            <span class="ipn-subtitle"></span>
                        </div>
                    </div>
                </div>
            </div>
            <section className="gray mb-50">
                <div className="container">
                    <div className="row">
                        <div class="col-lg-12 col-md-12 list-layout">
                        <div class="row justify-content-center">

                                    <div class="col-lg-12 col-md-12">
                                        <div class="item-sorting-box">
                                            <div class="item-sorting clearfix">                                                
                                                { filteredHotels.length > 0 ? 
                                                    <div class="left-column pull-left">
                                                        <h4 class="m-0">
                                                            {filteredHotels.length} résultats correspondent a votre recherche
                                                        </h4>
                                                    </div>
                                                    :
                                                    <div class="left-column pull-left">
                                                        <h4 class="m-0">
                                                           Liste des hotels disponibles
                                                        </h4>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <div class="item-sorting-box">
                                            <div class="item-sorting-box-right">
                                                <div class="sorting-by">
                                                    <select class="form-control ui-select is-valid" onChange={(e) => setNbPieces(e.target.value)} id="type_id" aria-invalid="false">
                                                        <option value={''}>Nombres de pièces / Voir tout</option>
                                                        {nombrepieces.map((nbpiece, index)=>(
                                                            <option key={index} value={nbpiece.value}>{nbpiece.key}</option>
                                                        ))}
                                                    </select>
                                                </div>      
                                            </div>
                                            <div class="item-sorting-box-right">
                                                 <div class="sorting-by">
                                                    <input type="text" value={allSearch} onChange={(e)=> setAllSearch(e.target.value)}  class="form-control select2-hidden-accessible"  placeholder="Rechercher un hotel ici...." />
                                                </div>
                                            </div>

                                            
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12">
                                    <div class="item-sorting-box">
                                        <div class="item-sorting-box-right">
                                                 <div class="sorting-by">
                                                
                                                <select class="form-control ui-select is-valid" onChange={(e) => setMinTarif(e.target.value)} id="type_id" aria-invalid="false">
                                                    <option value={0}>Montant minimum / Voir tout</option>
                                                    {TarifsOptions.map((tarif, index)=>(
                                                        <option key={index} value={tarif.value}>{tarif.key}</option>
                                                    ))}
                                                </select>
                                                </div>
                                            </div>

                                            <div class="item-sorting-box-right">
                                                 <div class="sorting-by">
                                                
                                                <select class="form-control ui-select is-valid" onChange={(e) => setMaxTarif(e.target.value)} id="type_id" aria-invalid="false">
                                                    <option value={0}>Montant maximum / Voir tout</option>
                                                    {TarifsOptions.map((tarif, index)=>(
                                                        <option key={index} value={tarif.value}>{tarif.key}</option>
                                                    ))}
                                                </select>
                                                </div> 
                                            </div>

                                        </div>
                                    </div>
                                </div>
               
                    
                        <div class="row">
                            {paginatedHotels.length>0 ? (paginatedHotels.map((hotel) => (
                                <HotelItemOne key={hotel.id} hotel={hotel} />
                            ))
                       
                            ) : paginatedHotels.length <= 0 ? (
                                <LoadingSpinnerCircular />
                            ) : (
                                filteredHotels.map((hotel) => (
                                <HotelItemOne key={hotel.id} hotel={hotel} />
                                ))
                            )}
                    
                            {filteredHotels.length === 0 && (
                                <div className="row" role="alert">
                                    <h4 className="text-center">Aucun résultat ne correspond à la recherche.</h4>
                                </div>
                            )}

                    {filteredHotels.length > 2 ?
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <nav class="d-flex justify-content-center pt-3" aria-label="Page navigation">
                                    <nav>
                                        <ul class="pagination">
                                            {/* "Previous" button */}
                                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                <span
                                                className="page-link"
                                                onClick={() => handlePageChange(currentPage - 1)}
                                                disabled={currentPage === 1}
                                                >‹</span>
                                            </li>
                                            {Array.from({ length: totalPages }).map((_, index) => (
                                                <li
                                                key={index}
                                                className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
                                                >
                                                <span
                                                    className="page-link"
                                                    onClick={() => handlePageChange(index + 1)}
                                                >
                                                    {index + 1}
                                                </span>
                                                </li>
                                            ))}

                                            {/* "Next" button */}
                                            <li
                                                className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}
                                            >
                                                <span
                                                className="page-link"
                                                onClick={() => handlePageChange(currentPage + 1)}
                                                disabled={currentPage === totalPages}
                                                >
                                                ›
                                                </span>
                                            </li>
                                        </ul>
                                    </nav>
                                </nav>
                            </div>
                        </div>
                        : ''
                    }
                    </div>
                </div>
                </div>
                </div>
        </section>
            
        </>
    )
}

export default ResidenceSideBar