// PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
const PrivateRoute = ({
    token,
    redirectPath = '/auth/login',
    children,
  }) => {
    
    if (!token) {
      return <Navigate to={redirectPath} replace />;
    }
  
    return children;
  };
  
export default PrivateRoute;
