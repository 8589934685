import { initializeApp } from 'firebase/app';
import { getMessaging,getToken ,onMessage } from "firebase/messaging";
import axios from "axios"
import dns from './utils/dns';

// Replace this firebaseConfig object with the congurations for the project you created on your firebase console. 
// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBnjSSKQUVKba4hA6cT-GQKN_E4Ab-dABg",
  authDomain: "residenceit-dce7e.firebaseapp.com",
  projectId: "residenceit-dce7e",
  storageBucket: "residenceit-dce7e.appspot.com",
  messagingSenderId: "1087097286845",
  appId: "1:1087097286845:web:63a40cf6aa377ef8a3c7d4",
  vapidKey: "BAYiv1xJqQ0neW2vn37EobW4gs9JhRqz8tDbMmdSCN9LyIC3buAEpBDx9pUYY5l48TQfbrIxBbL8uPK8FOA8Qwc" // Add this line
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestForToken = async () => {
  try {
    const currentToken = await getToken(messaging, { vapidKey: "BAYiv1xJqQ0neW2vn37EobW4gs9JhRqz8tDbMmdSCN9LyIC3buAEpBDx9pUYY5l48TQfbrIxBbL8uPK8FOA8Qwc" });
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      // Perform any other necessary action with the token
    } else {
      // Show permission request UI
      console.log('No registration token available. Request permission to generate one.');
    }
  } catch (err) {
    console.log('An error occurred while retrieving token. ', err);
  }
};


export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload)
      resolve(payload);
    });
  });