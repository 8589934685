import React from 'react';
import './PolitiqueConfidentialite.css'; // Fichier CSS pour le style
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';

const PolitiqueConfidentialite = () => {
    return (
        <>
            <Header />
            <div id="app">
                <div class="page-title mb-4">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-10 col-md-12">
                                <h1 class="ipt-title">Politique de Confidentialité</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="politique-confidentialite mb-3">
                <h1>Politique de Confidentialité pour l'Application de ResidenceIT</h1>

                <p>Cette Politique de Confidentialité décrit comment ResidenceIT recueille, utilise et protège les informations personnelles des utilisateurs de notre application mobile de gestion de logements. La confidentialité et la sécurité de vos données personnelles sont de la plus haute importance pour nous. Veuillez lire attentivement cette Politique de Confidentialité pour comprendre comment nous utilisons et protégeons vos informations.</p>

                <h2>Collecte des Informations Personnelles</h2>
                <p>Lorsque vous utilisez notre application, nous pouvons collecter certaines informations personnelles que vous choisissez de nous fournir. Cela peut inclure, mais n'est pas limité à :</p>
                <ul>
                    <li>Votre nom et vos prénoms</li>
                    <li>Votre adresse e-mail</li>
                    <li>Votre numéro de téléphone</li>
                    <li>Votre établissement (pour les étudiants)</li>
                    <li>Votre niveau d’étude</li>
                    <li>Votre genre</li>
                    <li>Des informations de facturation</li>
                    <li>Des détails de paiement</li>
                </ul>
                <p>Nous recueillons ces informations lorsque vous vous inscrivez sur notre application, effectuez des transactions, remplissez des formulaires ou utilisez nos services. Vos données personnelles peuvent être nécessaires pour vous fournir des services de gestion de logements et pour améliorer votre expérience utilisateur.</p>

                <h2>Utilisation des Informations Collectées</h2>
                <p>Les informations que nous recueillons peuvent être utilisées aux fins suivantes :</p>
                <ul>
                    <li>Pour gérer votre compte utilisateur et vous fournir les services demandés.</li>
                    <li>Pour personnaliser votre expérience et répondre à vos besoins individuels.</li>
                    <li>Pour traiter les transactions et effectuer des paiements.</li>
                    <li>Pour améliorer notre application et nos services en fonction des retours et des besoins des utilisateurs.</li>
                    <li>Pour vous envoyer des informations concernant votre compte, des mises à jour sur nos services ou des offres spéciales.</li>
                </ul>

                <h2>Protection des Informations Personnelles</h2>
                <p>Nous nous engageons à assurer la sécurité et la confidentialité de vos informations personnelles. Nous mettons en place des mesures techniques, administratives et physiques pour protéger vos données contre tout accès non autorisé, altération, divulgation ou destruction.</p>

                <h2>Partage des Informations</h2>
                <p>Nous ne vendons, n'échangeons ou ne transférons pas vos informations personnelles identifiables à des tiers sans votre consentement, sauf si cela est nécessaire pour fournir nos services ou si la loi l'exige.</p>

                <h2>Conservation des Données</h2>
                <p>Nous conservons vos données personnelles aussi longtemps que nécessaire pour remplir les objectifs pour lesquels elles ont été collectées, ou comme requis par la loi.</p>

                <h2>Consentement</h2>
                <p>En utilisant notre application, vous consentez à notre Politique de Confidentialité et à notre collecte, utilisation et protection de vos informations personnelles.</p>

                <h2>Mises à Jour de la Politique de Confidentialité</h2>
                <p>Nous nous réservons le droit de mettre à jour, de modifier ou de changer notre Politique de Confidentialité à tout moment. Les modifications seront publiées sur cette page.</p>

                <p>Si vous avez des questions concernant cette Politique de Confidentialité, veuillez nous contacter à l’adresse suivante : <a href="mailto:itsynergycontact@gmail.com">itsynergycontact@gmail.com</a></p>

                <p>Dernière mise à jour : 20/11/2023</p>
                </div>
            </div>
            <Footer />
        </>
  );
}

export default PolitiqueConfidentialite;