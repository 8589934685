import SideBarDashbordAdmin from "./Sidebar"
import Header from "../../components/Header/Header"
import Footer from "../../components/Footer/Footer"
import AdminTableVisites from "./components/TableVisite"
import axios from "axios"
import { UseSelector, useSelector } from "react-redux"
import { useState, useEffect } from "react"
import dns from "../../utils/dns"
import AdminTableReservations from "./components/TableReservations"
import DataTablePanneAdmin from "./components/TablePannes"
import DataTableUserAdmin from "./pages/auth/users/TableUserListe"
const DashboardAdmin = () => {
    const [visites, SetVisites] = useState([])
    const [logements, SetLogements] = useState([])
    const [pannes, SetPanne] = useState([])
    const [biens, SetBiens] = useState([])
    const [users, Setusers] = useState([])
    const [reservations, SetRerservations] = useState([])
    const userData = useSelector((state) => state.user.user)
    const token = useSelector((state) => state.user.token);

    const GetAllVisites =async () => {
        try {
            const response = await axios.get(dns + `/api/visites/`, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`,
                },
            })
            if (response.status === 200) {

                const sortedData = response.data.sort((a, b) => {
                    // Convertir les dates en objets Date pour pouvoir les comparer
                    const idA = a.id
                    const idB = b.id
                    
                    // Comparer les dates et retourner le résultat du tri
                    return idB - idA;
                });

                SetVisites(sortedData)
            }
        } catch (error) {
            console.error(error);
        }
    }

    const GetAllUsers =async () => {
        try {
            const response = await axios.get(dns + `/api/auth/users/`, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`,
                },
            })
            if (response.status === 200) {
                const sortedData = response.data.sort((a, b) => {
                    // Convertir les dates en objets Date pour pouvoir les comparer
                    const idA = a.id
                    const idB = b.id
                    
                    // Comparer les dates et retourner le résultat du tri
                    return idB - idA;
                });
                Setusers(sortedData)
            }
        } catch (error) {
            console.error(error);
        }
    }

    const GetAllReservations =async () => {
        try {
            const response = await axios.get(dns + `/api/reservation/`, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`,
                },
            })
            if (response.status === 200) {
                const sortedData = response.data.sort((a, b) => {
                    // Convertir les dates en objets Date pour pouvoir les comparer
                    const idA = a.id
                    const idB = b.id
                    
                    // Comparer les dates et retourner le résultat du tri
                    return idB - idA;
                });
                SetRerservations(sortedData)
            }
        } catch (error) {
            console.error(error);
        }
    }

    const GetAllPannes =async () => {
        try {
            const response = await axios.get(dns + `/api/pannes/`, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`,
                },
            })
            if (response.status === 200) {
                const sortedData = response.data.filter((panne) =>panne.logement !== null).sort((a, b) => {
                    // Convertir les dates en objets Date pour pouvoir les comparer
                    const idA = a.id
                    const idB = b.id
                    
                    // Comparer les dates et retourner le résultat du tri
                    return idB - idA;
                });
                SetPanne(sortedData)
            }
        } catch (error) {
            console.error(error);
        }
    }

    const GetAllBiens =async () => {
        try {
            const response = await axios.get(dns + `/api/adhesion/`, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`,
                },
            })
            if (response.status === 200) {
                const sortedData = response.data.sort((a, b) => {
                    // Convertir les dates en objets Date pour pouvoir les comparer
                    const idA = a.id
                    const idB = b.id
                    
                    // Comparer les dates et retourner le résultat du tri
                    return idB - idA;
                });
                SetBiens(sortedData)
            }
        } catch (error) {
            console.error(error);
        }
    }

    const GetAllLogements=async () => {
        try {
            const response = await axios.get(dns + `/api/logement/`, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`,
                },
            })
            if (response.status === 200) {
                const sortedData = response.data.sort((a, b) => {
                    // Convertir les dates en objets Date pour pouvoir les comparer
                    const idA = a.id
                    const idB = b.id
                    
                    // Comparer les dates et retourner le résultat du tri
                    return idB - idA;
                });
                SetLogements(sortedData)
            }
        } catch (error) {
            console.error(error);
        }
    }
    useEffect(() => {
        GetAllVisites()
        GetAllReservations()
        GetAllPannes()
        GetAllBiens()
        GetAllLogements()
        GetAllUsers()
    }, []);
    return (
        <>
           <Header />
            <div class="page-title">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <h1 class="ipt-title">Tableau de bord Admin</h1>
                            <span class="ipn-subtitle"></span>
                        </div>
                    </div>
                </div>
            </div>
            <section className="bg-light">
                <div className="container-fluid">
                    <div className="row">
                        <SideBarDashbordAdmin />
                        <div class="col-lg-8 col-md-12">
                            <div id="app">
                                {userData.libelle_role === "Admin" ?
                                <div class="row">
                                    <div class="col-lg-4 col-md-6 col-sm-12">
                                        <div class="dashboard-stat widget-1">
                                            <div class="dashboard-stat-content">
                                                <h4>{logements.length }</h4>
                                            <span>Logements</span>
                                            </div>
                                                <div class="dashboard-stat-icon">
                                                    <i class="bi bi-house-check-fill"></i>
                                                </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12">
                                        <div class="dashboard-stat widget-2">
                                            <div class="dashboard-stat-content">
                                                    <h4>{reservations.length }</h4>
                                            <span>Reservations</span>
                                            </div>
                                                <div class="dashboard-stat-icon">
                                                    <i class="bi bi-list-stars"></i>
                                                </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12">
                                        <div class="dashboard-stat widget-3">
                                            <div class="dashboard-stat-content">
                                                    <h4>{pannes.length }</h4>
                                            <span>Pannes</span>
                                            </div>
                                                <div class="dashboard-stat-icon">
                                                    <i class="bi bi-exclamation-circle-fill"></i>
                                                </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12">
                                        <div class="dashboard-stat widget-4">
                                            <div class="dashboard-stat-content">
                                            <h4>{users.length}</h4>
                                            <span>Utilisateurs</span>
                                            </div>
                                                <div class="dashboard-stat-icon">
                                                    <i class="bi bi-people-fill"></i>
                                                </div>
                                        </div>
                                        </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12">
                                        <div class="dashboard-stat widget-5">
                                            <div class="dashboard-stat-content">
                                                <h4>{visites.length }</h4>
                                                <span>Visites</span>
                                            </div>
                                                <div class="dashboard-stat-icon">
                                                    <i class="bi bi-person-vcard"></i>
                                                </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12">
                                        <div class="dashboard-stat widget-7">
                                            <div class="dashboard-stat-content">
                                                    <h4>{ biens.length}</h4>
                                                <span>Adhesions</span>
                                            </div>
                                                <div class="dashboard-stat-icon">
                                                    <i class="bi bi-houses"></i>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                                : 
                                    ''}
                                <div className="row">
                                    <AdminTableVisites visites={visites.slice(0,4)} />
                                    <AdminTableReservations reservations={reservations.slice(0, 4)} />
                                    <DataTablePanneAdmin  pannes={pannes.slice(0, 4)}/>
                                    <DataTableUserAdmin users={users.slice(0, 4)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )

}

export default DashboardAdmin