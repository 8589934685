import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from 'primereact/inputtext';
import { Card } from 'primereact/card';

const TableRolesAdmin = ({ roles }) => {
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');

  const filteredroles = roles.filter((role) =>
    role.libelle_role.toLowerCase().includes(searchText.toLowerCase())
        
  );
  useEffect(() => {
    // Simulate an asynchronous data loading process
    setTimeout(() => {
      setLoading(false);
    }, 2000); // Simulating a 2-second loading time
  }, []);

  return (
    <div className="card-body table-responsive">
      {/* <h4>
        Liste des rôles{" "}
        <Link to="/admin/dashbord/roles/create">
          <Button label="Ajouter un rôle" className="p-button-primary" />
        </Link>{" "}
      </h4> */}
      <Card>
        <div className="p-fluid p-formgrid p-grid">
            <div className="p-field p-col">
                <label htmlFor="searchText">Rechercher :</label>
                <InputText
                    id="searchText"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                />
            </div>
        </div>
        <DataTable value={filteredroles} loading={loading} paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} emptyMessage="Aucune donnée disponible pour le moment">
            <Column field="id" header="#ID" />
            <Column field="libelle_role" header="Libellé" />
            {/* <Column field="description" header="Description" /> */}
            <Column
            body={(rowData) => (
                <div className="fr-grid-footer-flex-right">
                    <Link to={`#`} className="prt-view ml-3">
                        <i className="bi bi-plus-circle" />
                    </Link>
                    <Link to={`#`} className="prt-danger ml-3">
                        <i className="bi bi-trash" />
                    </Link>
                </div>
            )}
            />
        </DataTable>
      </Card>
      
    </div>
  );
};

export default TableRolesAdmin;
