import Header from "../../../components/Header/Header"
import Footer from "../../../components/Footer/Footer"
import SideBarDashbord from "../common"
import DataTablePanneAgent from "../../../components/TablePannesAgent";
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import axios from "axios";
import dns from "../../../utils/dns";

const PannesResidences = () => {
    
    const userData = useSelector((state) => state.user.user);
    const token = useSelector((state) => state.user.token);
    const [data, setData] = useState([]);
    

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(dns +'/api/pannes/user/pannes/',{
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
                );
                if(response){
                    setData(response.data.reserve());
                    console.log("response.data")
                    console.log(response?.data)
                }
                
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    },[token]);

    return (
        <>
            <Header />
                <div className="page-title">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <h1 className="ipt-title">Liste des pannes signalées</h1>
                                <span className="ipn-subtitle"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="bg-light">
                    <div className="container-fluid">
                        <div className="row">
                            <SideBarDashbord />
                            <div class="col-lg-8 col-md-12">
                                <div id="app">
                                    <DataTablePanneAgent pannes={data} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            <Footer />
        </>
    )
}

export default PannesResidences