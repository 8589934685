import Footer from "../components/Footer/Footer"
import Header from "../components/Header/Header"
import React, { useEffect, useState ,useRef} from 'react';
import axios from 'axios';
import dns from "../utils/dns";
import {useNavigate,useParams } from "react-router-dom";
import Swal from 'sweetalert2';
// eslint-disable-next-line jsx-a11y/iframe-has-title
import logo from '../public/img/logo.png'
import clearFormFields from "../utils/cleanform";
import Map from "../components/MapComponent";
import './modale.css';
import { useSelector } from "react-redux";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
const DetailsPopertie = () => {
    const [DetailsResidence, setRedidenceDetails] = useState({});
    const [position, setPosition] = useState([]);
     const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [caption, setCaption] = useState('');
    const  Navigate = useNavigate()
    const  Residence  = useParams();
    const DateInput = useRef(null);
    const formRef = useRef(null);
    const LieuxRdvInput = useRef(null);
    const currentUrl = window.location.href;
    const whatsappMessage = `Bonjour j'aimerais visiter l'une de vos résidences.\nLien de la résidence Cliquez ici pour voir la résidence: ${currentUrl} `;
    //const whatsappLink = `https://api.whatsapp.com/send?phone=225${DetailsResidence?.contact_payement}&text=${encodeURIComponent(whatsappMessage)}`;
    const whatsappLink = DetailsResidence?.contact_payement?.startsWith("+225") ?`https://api.whatsapp.com/send?phone=${DetailsResidence?.contact_payement}&text=${encodeURIComponent(whatsappMessage)}` : `https://api.whatsapp.com/send?phone=225${DetailsResidence?.contact_payement}&text=${encodeURIComponent(whatsappMessage)}`;
    const MessageInput = useRef(null);

    const userData = useSelector((state) => state.user.user);
    const token = useSelector((state) => state.user.token);
    var modal = document.getElementById("myModal");
    const openModal = (imageSrc, altText) => {
        modal.style.display = "block";
        setSelectedImage(imageSrc);
        setCaption(altText);
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(false);
        modal.style.display = "none";
    };
    
    const MakeApointement = async (e) => {
        e.preventDefault();
        
        if (token) {
            if (userData.libelle_role !== "Proprietaire" || userData.libelle_role !== "Admin"){
                const date = DateInput.current.value;
                const message = MessageInput.current.value;
                const formData = new FormData();
                const prix_visite = 2000;
                formData.append('date_visite', date);
                formData.append('statut_visite', "planifiee");
                formData.append('lieu_rendez_vous',  LieuxRdvInput.current.value);
                formData.append('observations', message);
                formData.append('prix_visite', prix_visite);
                formData.append('client_id', userData.id);
                formData.append('logement_id',Residence.IdResidence);
                 try {
                axios.post(dns + `/api/visites/`,formData,{
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then(res => {
                    if (res.status === 201) {
                        clearFormFields(formRef);
                        Swal.fire({
                            title:'Creation de visite', 
                            text:`Votre soumission à été envoyer avec succès`, 
                            icon: 'success', 
                            button:'OK',
                            timer: 3000,
                            timerProgressBar:true,
                            didOpen:()=>{
                                Swal.showLoading();
                            }
                        })
                        .then(Navigate("/user/visites/"))
                    } else {
                        Swal.fire({
                            title:'Creation de visite', 
                            text:`Nous avons rencontré un problème lors du traitement de votre requête`, 
                            icon: 'error', 
                            button:'OK',
                            timer: 3000
                        })
                    }
                })
                .catch(error => { 
                    console.error(error);
                    Swal.fire({
                        title:'Creation de visite', 
                        text:`Nous avons rencontré un problème lors du traitement de votre requête`, 
                        icon: 'error', 
                        button:'OK',
                        timer: 3000,
                        timerProgressBar:true,
                        didOpen:()=>{
                            Swal.showLoading();
                        }
                    })
                })
            } catch (error) {
                console.error(error);
                Swal.fire({
                    title:'Creation de visite', 
                    text:`Nous avons rencontré un problème lors du traitement de votre requête`, 
                    icon: 'error', 
                    button:'OK',
                    timer: 3000,
                    timerProgressBar:true,
                    didOpen:()=>{
                        Swal.showLoading();
                    }
                })
            }
            }else{
                Swal.fire({
                    title:'Réservation de residence', 
                    text:`Vous n'êtes pas autorisé a faire cette action.`, 
                    icon: 'error', 
                    button:'OK',
                    timer: 5000,
                    timerProgressBar:true,
                    didOpen:()=>{
                        Swal.showLoading();
                    }
                })
            }
        } else {
            Swal.fire({
                title:'Creation de visite', 
                text:`Vous devez etre connecter pour demander une visite`, 
                icon: 'error', 
                button:'OK',
                timer: 4000,
                timerProgressBar:true,
                didOpen:()=>{
                    Swal.showLoading();
                }
            })
            .then(Navigate(`/auth/login?from=/details-residence/${Residence.IdResidence}`))
        }
    }
    const PostulerHebergement = async () => {
        setLoading(true)
        //&& userData.libelle_role !== "Proprietaire" && userData.libelle_role !== "Proprietaire"
        if (token) {
            if (userData.libelle_role !== "Proprietaire" || userData.libelle_role !== "Admin"){
                if (DetailsResidence.statut_logement  === "disponible") {
                    try {
                        const formData = new FormData();
                        formData.append('logement_id', Residence.IdResidence);
                        formData.append('client_id', userData.id);
                        formData.append('statut_reservation', "en_attente");
                        const response = axios.post(dns + `/api/reservation/`, formData,{
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${token}`,
                            },
                        })
                        response.then(res => {
                            if (res.status === 201) { 
                                setLoading(false)
                                Swal.fire({
                                    title:'Réservation de residence', 
                                    text:`Votre avons notifié le propriétaire de cette résidence que vous êtes interessé`, 
                                    icon: 'success', 
                                    button:'OK',
                                    timer: 4000,
                                    timerProgressBar:true,
                                    didOpen:()=>{
                                        Swal.showLoading();
                                    }
                                })
                                .then(Navigate('/user/reservations/'))
                            } else {
                                setLoading(false)
                                Swal.fire({
                                    title:'Réservation de residence', 
                                    text:`Veuillez vous connecter pour postuler pour cette residence`, 
                                    icon: 'error', 
                                    button:'OK',
                                    timer: 5000 
                                })
                            }
                        })
                        .catch(error => {
                            setLoading(false) 
                            console.error(error);
                            Swal.fire({
                                title:'Creation de visite', 
                                text:`Nous avons rencontré un problème lors du traitement de votre requête`, 
                                icon: 'error', 
                                button:'OK',
                                timer: 3000
                            })
                        })
                    }catch(error){
                        setLoading(false)
                        console.log(error);
                        Swal.fire({
                            title:'Creation de visite', 
                            text:`Nous avons rencontré un problème lors du traitement de votre requête`, 
                            icon: 'error', 
                            button:'OK',
                            timer: 3000
                        })
                    }
                } else {
                    setLoading(false)
                    Swal.fire({
                        title:'Réservation de residence', 
                        text:`Vous ne pouvez pas postuler pour cette residence, car elle est indisponible`, 
                        icon: 'error', 
                        button:'OK',
                        timer: 5000
                    })
                }
            }else{
                 setLoading(false)
                 Swal.fire({
                    title:'Réservation de residence', 
                    text:`Vous n'êtes pas autorisé a faire cette action.`, 
                    icon: 'error', 
                    button:'OK',
                    timer: 5000,
                    timerProgressBar:true,
                    didOpen:()=>{
                        Swal.showLoading();
                    }
                })
            }
            
        } else {
             setLoading(false)
            Swal.fire({
                title:'Réservation de residence', 
                text:`Veuillez vous connecter pour postuler pour cette residence`, 
                icon: 'error', 
                button:'OK',
                timer: 5000
            })
            .then(Navigate(`/auth/login?from=/details-residence/${Residence?.IdResidence}`))
        }
    }
    
    useEffect(() => {
        const fetchEntityDetails = async () => {
            try {
                axios.get(dns + `/api/logements/${Residence?.IdResidence}/`)
                .then(res => {
                    const data = res.data;
                    console.log(res.data)
                    //console.log(res.data.localisation.split(',').map((chaine) =>{ return parseFloat(chaine);}))
                    setRedidenceDetails(data);
                    LieuxRdvInput.current.value = res?.data?.quartier_logement;
                    // const pos = res.data.localisation.split(',').map((chaine) =>{ return parseFloat(chaine);})
                    // setPosition(pos)
                })
                .catch(error => { 
                    console.error(error);
                })
            } catch (error) {
                console.error(error);
            }
        };

        fetchEntityDetails(); // Call the fetchEntityDetails function directly
        //FecthUserProfile();
}, [Residence]); // Include entityId as a dependency in the dependency array

    return (
        <>
            <Header />
            <div id="app">  
                <section class="bg-title">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-11 col-md-12">
                                <div class="property_block_wrap style-4">
                                <div class="prt-detail-title-desc">
                                    <span class="prt-types sale rent">{DetailsResidence?.statut_logement}</span>
                                    <h3 class="text-light">{DetailsResidence?.quartier_logement} {DetailsResidence?.libelletypelogement === "Etudiants" ? '(Résidence partagé)':DetailsResidence?.libelletypelogement === "Residences" ? '(Résidence)':'(Hotel)'}</h3>
                                    <span><i class="bi bi-geo-alt"></i> {DetailsResidence.commune_logement}</span>
                                    {DetailsResidence?.libelletypelogement === "Etudiants" || "Residences" ? (
                                        <h3 class="prt-price-fix">{DetailsResidence?.libelletypelogement === "Etudiants"  ? `${DetailsResidence?.tarif_mensuel?.toString().replace(".00", "")} cfa / mois` : `${DetailsResidence?.tarif_mensuel?.toString().replace(".00", "")} cfa / jour`} </h3>
                                        ) : ''}
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="property-detail gray-simple">
                    <div data-property-id="11"></div>
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-8 col-md-12 col-sm-12">
                                <div class="property_block_wrap style-2">
                                    <div class="property_block_wrap_header">
                                        <h4 class="property_block_title">Location du logement</h4>
                                    </div>
                                    <div id="clSix" class="panel-collapse collapse show">
                                        <div class="block-body">
                                            <div id="zxcvbnm">
                                                <div class="traffic-map-container">  
                                                    <Map position={`${DetailsResidence?.localisation}`} contentInfo={DetailsResidence?.commune_logement + " , " +DetailsResidence?.quartier_logement} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="property_block_wrap style-2">
                                <div class="property_block_wrap_header">
                                            <h4 class="property_block_title">Nombre de pièces</h4>
                                    </div>
                                    <div id="clTwo" class="panel-collapse collapse show">
                                        <div class="block-body">
                                            <p>{DetailsResidence.nombre_pieces ? DetailsResidence.nombre_pieces : "x"} pièces</p>
                                        </div>
                                    </div>
                                    <div class="property_block_wrap_header">
                                            <h4 class="property_block_title">Description</h4>
                                    </div>
                                    <div id="clTwo" class="panel-collapse collapse show">
                                        <div class="block-body">
                                            <p>{DetailsResidence?.description}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="property_block_wrap style-2">
                                    <div class="property_block_wrap_header">
                                        {DetailsResidence?.libelletypelogement === "Etudiants" || DetailsResidence?.libelletypelogement === "Residences" ? (
                                        <h4 className="property_block_title">Photos de la residence</h4>
                                        ) : (
                                        <h4 className="property_block_title">Photos de la chambre d'hotel</h4>
                                        )}
                                    </div>
                                    <div id="clSev" class="panel-collapse collapse show">
                                        <div class="block-body">
                                            <ul class="list-gallery-inline">
                                                <li><img src={DetailsResidence?.image_logement}   className="img-fluid mx-auto lazy entered loaded"   alt=""  onClick={() => openModal(DetailsResidence?.image_logement, "Image Alt Text")}/></li>
                                                <li><img src={DetailsResidence?.image_logement1}  className="img-fluid mx-auto lazy entered loaded"   alt="" onClick={() => openModal(DetailsResidence?.image_logement1, "Image Alt Text")}/></li>
                                                <li><img src={DetailsResidence?.image_logement2}  className="img-fluid mx-auto lazy entered loaded"   alt="" onClick={() => openModal(DetailsResidence?.image_logement2, "Image Alt Text")} /></li>
                                                <li><img src={DetailsResidence?.image_logement3}  className="img-fluid mx-auto lazy entered loaded"   alt=""  onClick={() => openModal(DetailsResidence?.image_logement3, "Image Alt Text")}/></li>
                                                <li><img src={DetailsResidence?.image_logement4}  className="img-fluid mx-auto lazy entered loaded"   alt=""  onClick={() => openModal(DetailsResidence?.image_logement4, "Image Alt Text")}/></li>
                                                <li><img src={DetailsResidence?.image_logement5}  className="img-fluid mx-auto lazy entered loaded"   alt="" onClick={() => openModal(DetailsResidence?.image_logement5, "Image Alt Text")}/></li>
                                            </ul>
                                            {DetailsResidence?.libelletypelogement === "Etudiants" ?( <button type="button" className="btn btn-primary fw6 mt-4" onClick={setVisible} disabled={loading}>{loading ? "Réservation en cours..." : "Reserver ce logement"}</button>): <button type="button" className="btn btn-primary fw6 mt-4" onClick={PostulerHebergement}>{loading ? "Réservation en cours..." : "Reserver ce logement"}</button>}
                                        </div>
                                    </div>
                                        <div id="myModal" class="modal">
                                            <span class="close" onClick={closeModal}>&times;</span>
                                            <img class="modal-content" id="img01" src={selectedImage} alt={caption}/>
                                            <div id="caption"></div>
                                        </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 col-sm-12">
                                <div class="like_share_wrap b-0">
                                    <ul class="like_share_list justify-content-center">
                                        <li class="social_share_list">
                                            <a href={whatsappLink} data-bs-toggle="tooltip" data-original-title="Share" class="btn btn-likes"  target="_blank" rel="noopener noreferrer">
                                                <i class="bi bi-whatsapp"></i>
                                                Whatsapp
                                            </a> 
                                            <div class="social_share_panel">
                                                <a href={whatsappLink}  target="_blank" class="cl-linkedin" rel="noopener noreferrer">
                                                    <i class="bi bi-whatsapp"></i>
                                                </a>
                                            </div>
                                        </li> 
                                        <li>
                                            <a href={`tel:+225${DetailsResidence?.contact_payement? DetailsResidence?.contact_payement:"+2250787089013"}`} data-id="12" data-bs-toggle="tooltip" data-original-title="Save" class="btn btn-likes add-to-wishlist">
                                            <i class="bi bi-phone-fill"></i>Appeler</a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="details-sidebar"> 
                                    <div class="sides-widget">
                                        <div class="sides-widget-header">
                                            <div class="agent-photo"><img src={logo} alt="" /></div>
                                            <div class="sides-widget-details">
                                            <h4>{DetailsResidence?.nom_proprietaire? DetailsResidence?.nom_proprietaire : "Residence IT team"}</h4>
                                                 <a href={`tel:+225${DetailsResidence?.contact_payement? DetailsResidence?.contact_payement:"+2250787089013"}`}><span><i class="bi bi-phone"></i>{DetailsResidence?.contact_payement? DetailsResidence?.contact_payement:"+2250787089013"}</span></a>
                                            </div>
                                            <div class="clearfix"></div>
                                        </div>
                                        <div class="sides-widget-body simple-form">
                                            <form onSubmit={MakeApointement} id="consult-form" class="consult-form" ref={formRef}>
                                                <div class="row">
                                                    <div class="form-group">
                                                        <label htmlFor="">Date de visite</label>
                                                        <input ref={DateInput} id="date" type="date"  required="required" class="form-control" />
                                                    </div>
                                                    <div class="form-group">
                                                        <label htmlFor="">Lieu de visite</label>
                                                        <input type="text" ref={LieuxRdvInput} placeholder="Lieux du rendez-vous *" data-validation-engine="validate[required]" data-errormessage-value-missing="Please enter phone number!" class="form-control" disabled/>
                                                    </div>
                                                    {/* <div class="form-group"><input  ref={PrixInput}  type="text"  class="form-control" value="5000 cfa"/></div> */}
                                                    {/* <div class="form-group"><input type="text" placeholder="Subject *"   class="form-control" /></div> */}
                                                    <div class="form-group">
                                                        <textarea ref={MessageInput} rows="5" placeholder="Message *" class="form-control" ></textarea>
                                                    </div>
                                                    <div class="form-group">
                                                       <button type="submit" class="btn btn-primary btn-md rounded full-width">Prendre rendez-vous</button>
                                                    </div>
                                                    <div class="clearfix">
                                                        {DetailsResidence?.libelletypelogement === "Etudiants"? <p> NB: le prix de la visite est de 2000f</p> : ''}
                                                    </div>
                                                    <br /> 
                                                    <div class="alert alert-success text-success text-left" style={{ display: "none" }}><span></span></div>
                                                    <div class="alert alert-danger text-danger text-left" style={{ display: "none" }}><span></span></div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </>
    )
}

export default DetailsPopertie