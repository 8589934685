import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Link } from "react-router-dom"
import dns from '../utils/dns';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useSelector } from 'react-redux';

const DataTableLogements = ({ Logements }) => {
    const [loading, setLoading] = useState(true);
    const token = useSelector((state) => state.user.token);

    const DeleteLogement = (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: true
        })

        swalWithBootstrapButtons.fire({
            title: 'Etes vous sur de supprimer ce logement ?',
            text: "Vous ne pourrez pas revenir en arrière !",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirmer',
            cancelButtonText: 'Annuler!',
            reverseButtons: true
        })
        .then((result) => {
            if (result.isConfirmed) {
                axios.delete(dns + `/api/logement/${id}/`, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    }
                })
                .then((response) => {
                    if (response.status === 204) {
                        Swal.fire({
                            title: 'Suppression de logement',
                            text: `Votre logement à été supprimé avec succès`,
                            icon: 'success',
                            button: 'OK',
                            timer: 4000
                        })
                        window.location.reload();
                    } else {
                        Swal.fire({
                            title: 'Suppression de logement',
                            text: `Nous avons rencontré une erreur lors du traitement de votre requête`,
                            icon: 'error',
                            button: 'OK',
                            timer: 4000
                        })
                    }
                })
            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'Annulé',
                    "l'action a échoué:)",
                    'warning'
                )
            }
        })
    }

    useEffect(() => {
        // Simulate an asynchronous data loading process
        setTimeout(() => {
            setLoading(false);
        }, 3000); // Simulating a 3-second loading time
    }, []);

    return (
        <>
            <div className='card-body'>
                <div className="table-responsive">
                    <DataTable value={Logements} sortOrder={1} sortField='commune_logement' paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} loading={loading} emptyMessage="Aucune donnée disponible pour le moment">
                        <Column header="Image" body={(rowData) => <img src={rowData?.image_logement} alt="" className="img-fluid" style={{ width: '50px', height: '50px' }} />} />
                        <Column field="quartier_logement" header="Quartier" body={(rowData) => rowData?.quartier_logement?.substring(0, 10) + '...'}></Column>
                        <Column field="commune_logement" header="Commune"></Column>
                        <Column field="description" header="Description" body={(rowData) => rowData?.description?.substring(0, 10) + '...'}></Column>
                        <Column field="statut_logement" header="Disponibilité"></Column>
                        <Column field="tarif_mensuel" header="Tarif" body={(rowData) => rowData?.tarif_mensuel?.toString().replace(".00", "")}></Column>
                        <Column field="date_creation" header="Date" body={(rowData) => rowData?.date_creation?.split('T')[0]}></Column>
                        <Column body={(rowData) => (
                            <div className="fr-grid-footer-flex-right">
                                <Link to={`/user/agent/details-residence/${rowData?.id}/`} className="prt-view">
                                    <i className="bi bi-eye"></i>
                                </Link>
                                <Link to="#" className="prt-danger" style={{ marginLeft: '2px' }} onClick={() => { DeleteLogement(rowData?.id) }}>
                                    <i className="bi bi-trash"></i>
                                </Link>
                            </div>
                        )}></Column>
                    </DataTable>
                </div>
            </div>
        </>
    )
}

export default DataTableLogements;
