import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import ResidenceSideBar from "../components/Main/ResidenceSideBar"
const Hotels = () => {
    
    return (
        <>
            <Header />
            <div id="app">
                <div >
                    <ResidenceSideBar />
                </div>
            </div>  
            <Footer />
        </>
    )
}

export default Hotels