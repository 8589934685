import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import { NavLink } from "react-router-dom"
import { useRef } from "react"
import Swal from "sweetalert2"
import axios from "axios"
import dns from "../utils/dns"
import Config from "../config/config"
const PasswordResset = () => {
    const emailInput = useRef(null);
    
    let redirect_url = Config.REACT_APP_API_URL; // Définir une valeur par défaut
    const SendEmailLink = async(e) => {
        e.preventDefault();
        const email = emailInput.current.value;
        const emailRegx = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if (emailRegx.test(email)) {
            try {
                
                const data = {
                    email:email,
                    redirect_url:redirect_url
                }

                const response = await axios.post(dns + `/api/auth/request-reset-email/`,data);
                if (response.status === 201) {
                    emailInput.current.value = ""; 
                    Swal.fire({
                        title:'Reinitialisation de mot de passe', 
                        text:`Nous avons envoyé un mail a l'adresse mail renseigner`, 
                        icon: 'success', 
                        button:'OK',
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                        },
                    })                    
                } else {
                    Swal.fire({
                        title:'Reinitialisation de mot de passe', 
                        text:`L'adresse email entrer n'a pas de compte chez Résidence IT`, 
                        icon: 'error', 
                        button:'OK',
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                        },
                    })
                }
            } catch (error) {
                
            }
        } else {
            Swal.fire({
                title:'Reinitialisation de mot de passe', 
                text:`L'adresse email entrer n'est pas valide`, 
                icon: 'error', 
                button:'OK',
                timer: 3000,
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading()
                },
            })
        }
            
        }

    return (
        <>
            <Header />
                <div id="app">
                    <section>
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-md-6">
                                <div class="modal-content">
                                    <div class="modal-body">
                                        <h2 class="text-center">Mot de passe oublié ?</h2>
                                        <p class="text-center">Veuillez entrer votre adresse mail pour recevoir les instructions de reinitialiations de mot de passe.</p>
                                        <br /> 
                                        <form onSubmit={SendEmailLink} class="simple-form">
                                            <div class="form-group">
                                                <div class="input-with-icon">
                                                    <input id="email" type="email" ref={emailInput} required="required" placeholder="Votre adresse email" class="form-control" />
                                                    <i class="bi bi-envelope"></i></div>
                                                </div>
                                                <div class="form-group mb-0">
                                                <button type="submit" class="btn btn-md full-width btn-primary rounded">
                                                    Reinitialiser mon mot de passe
                                                </button> 
                                                <div class="text-center">
                                                    <NavLink to='/auth/login' className="btn btn-link">Se connecter</NavLink>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            <Footer />
        </>
    )
}

export default PasswordResset