import SideBarDashbord from "../common"
import Header from "../../../components/Header/Header"
import Footer from "../../../components/Footer/Footer"
import axios from 'axios'
import React, { useEffect ,useState,useRef} from 'react'
import { useNavigate,useParams } from 'react-router-dom'
import dns from "../../../utils/dns"
import Swal from 'sweetalert2'
import { useSelector } from "react-redux"

const DetailsPannesAgent = () => {
    const Navigate = useNavigate();
    const [Detailspanne, SetDetailsPanne] = useState({})
    const Panne = useParams();
    const formRef = useRef(null);
    const token = useSelector((state) => state.user.token);
    const userdata = useSelector((state) => state.user.user);
    const Type_pannesInput = useRef(null);
    const descriptionInput = useRef(null);
    const StatutPanneInput = useRef(null)
     const ClientInput = useRef(null)
    
    const handleSubmit = async (e) => { 
        e.preventDefault();
        
        const type_panne = Type_pannesInput.current.value;
        const description_panne = descriptionInput.current.value;
        const formData = new FormData();
        formData.append('client_id', userdata.id);
        formData.append('type_panne_id', type_panne);
        formData.append('description_panne', description_panne);
        formData.append('statut_panne', StatutPanneInput.current.value);
        try {
            axios.patch(dns + `/api/pannes/${Panne.IdPanne}/`,formData,{
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(res => {
                if (res.status === 200) {
                    Swal.fire({
                        title: 'Signaler une panne',
                        text: `Votre panne à été modifier avec succès`,
                        icon: 'success',
                        button: 'OK',
                        timer: 3000,
                        timerProgressBar:true,
                        didOpen:()=>{
                            Swal.showLoading();
                        }
                    })
                    .then(Navigate("/user/liste-pannes"));
                } else {
                    Swal.fire({
                        title:'Signaler une panne', 
                        text:`Nous avons rencontré un problème lors du traitement de votre requête`, 
                        icon: 'error', 
                        button:'OK',
                        timer: 3000
                    })
               }
            })
            .catch(error => { 
                console.error(error.message);
            })
        } catch (error) {
        console.error(error);
        }
    }
    
    useEffect(() => { 
        const GetDetailsPanne = async () => {
            try {
                axios.get(dns + `/api/pannes/${Panne.IdPanne}/`,{
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                })
                .then(res => {
                    if (res.status === 200) {
                        SetDetailsPanne(res.data)
                        descriptionInput.current.value = res.data.description_panne;
                        ClientInput.current.value = res.data.client.username
                        //Type_pannesInput.current.value = res.data.libelle_type_panne;                        
                    } else {
                        Swal.fire({
                            title: 'Details panne',
                            text: `Cette panne n'existe pas!`,
                            icon: 'error',
                            button: 'OK',
                            timer: 3000
                        })
                        .then(Navigate("/user/liste-pannes/"))
                        }
                    })
            } catch (error) {
                console.error("error: ", error)
            }
        };
        GetDetailsPanne();
        
    }, [Panne,Navigate,token]) // Include entityId as a dependency in the dependency array

    return (
        <>
            <Header />
            <div className="page-title">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <h1 className="ipt-title">Details de la panne</h1>
                            <span className="ipn-subtitle"></span>
                        </div>
                    </div>
                </div>
            </div>
             <section className="bg-light">
                <div className="container-fluid">
                    <div className="row">
                            <SideBarDashbord />
                            <div className="col-lg-8 col-md-12">
                                <div id="app">
                                    <div className="dashboard-wraper settings crop-avatar">
                                        <div className="form-submit">
                                            <div className="row">
                                            <div className="col-12">
                                                <h4 className="with-actions">Details sur la panne</h4>
                                            </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-8 order-lg-0">
                                                    <form onSubmit={handleSubmit} noValidate ref={formRef}>
                                                        <div className="form-group">
                                                            <label htmlFor="pannes">Client</label>
                                                            <input type="text"  className="form-control is-valid" ref={ClientInput} />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="pannes">Types de pannes</label>
                                                            <select  id="pannes" ref={Type_pannesInput} className="form-control is-valid" aria-invalid="false" disabled>
                                                            <option value={4}>{Detailspanne.libelle_type_panne}</option>
                                                        </select>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="comment">Description de la panne</label>
                                                            <textarea ref={descriptionInput} id="comment" rows="5" placeholder="je décris la panne.." className="form-control" disabled></textarea>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="pannes">Statut</label>
                                                        <select id="pannes" ref={StatutPanneInput} className="form-control is-valid" aria-invalid="false">
                                                            <option value={Detailspanne.statut_panne}>{Detailspanne.statut_panne ==="en_attente"  ? " En attente " : Detailspanne.statut_panne=== "en_cours" ? " En cours" : 'Résolue'}</option>
                                                                {Detailspanne.statut_panne !== "en_attente" && <option value="en_attente">En attente</option>}
                                                                {Detailspanne.statut_panne !== "en_cours" && <option value="en_cours">En cours</option>}
                                                                {Detailspanne.statut_panne !== "resolue" && <option value="resolue">Résolue</option>}
                                                            </select>
                                                        </div>
                                                        <button type="submit" className="btn btn-primary fw6">Modifier</button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default DetailsPannesAgent;