
import { Link, useLocation } from "react-router-dom"
import axios from 'axios'
import React, { useEffect ,useState,useRef} from 'react'
import { useNavigate } from 'react-router-dom'
import dns from "../../utils/dns"
import user_picture from '../../public/img/user.png'
import Swal from "sweetalert2"
import { useSelector,useDispatch } from 'react-redux';
import { logout, setConnected,setUserToken } from "../../store/reducers/userSlice";

function isActive(routeName, location) {
  return location.pathname === routeName;
}

const SideBarDashbord = () => {
    const location = useLocation();
    const  Navigate = useNavigate()
    const userData = useSelector((state) => state.user.user);
    const token = useSelector((state) => state.user.token);
    const dispatch = useDispatch()
    const logoutHandler = () => { 
        axios.post(dns + `/api/auth/logout`)
        .then(res =>{
            if (res.status === 201) {
                dispatch(logout());
                dispatch(setUserToken(""));
                dispatch(setConnected({connected : 0}));
                Swal.fire({
                    title:'Deconnexion', 
                    text:`Vous avez été deconnecté avec succès`, 
                    icon: 'success', 
                    button:'OK',
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: () => {
                        Swal.showLoading()
                    },
                }).then(Navigate("/auth/login"))
               
            } else {
                 Swal.fire({
                    title:'Deconnexion', 
                    text:`Nus avons rencontrer un problème lors du traitement de votre requête`, 
                    icon: 'error', 
                    button:'OK',
                    timer: 3000
                })
            }
        })
        .catch(error => {
            console.error(error);
        })
        
    }

    useEffect(()=>{
       // console.log(userData)//FecthUserProfile()userData.libelle_role === "Proprietaire" ||Client/user/agent/visite/liste/
    },[])
    return (
        <>
            <div class="col-lg-3 col-md-12 ml-0">
                <div class="simple-sidebar sm-sidebar">
                    <div class="sidebar-widgets">
                        <div class="dashboard-navbar">
                            <div class="d-user-avater">
                                <img src={userData.picture ? userData.picture : user_picture } alt="user" className="img-fluid avater" style={{ width: 150 + 'px' }} />
                                <h4>{userData.last_name} {userData.first_name}</h4>
                                <span><b>{userData.libelle_role}</b> </span><br />
                                <span>{userData.numero_telephone}</span>
                            </div>
                            <div class="d-navigation">
                                <ul>
                                    <li className={`${isActive('/user/profile', location) ? 'active' : ''}`}>
                                        <Link to='/user/profile'>
                                            <i class="bi bi-person-bounding-box mr1"></i>
                                            Mon profile
                                        </Link>
                                    </li>
                                    {userData.libelle_role === "Proprietaire" ?
                                        <li className={`${isActive('/user/agent/logement/add/', location) ? 'active' : ''}`}>
                                        <Link to="/user/agent/logement/add/" >
                                            <i class="bi bi-plus-circle-fill mr1"></i>
                                            Ajouter un logement
                                        </Link>
                                        </li> : ''}
                                    {userData.libelle_role === "Proprietaire" ?
                                        <li className={`${isActive('/user/agent/biens/add/', location) ? 'active' : ''}`}>
                                        <Link to="/user/agent/biens/add/" >
                                            <i class="bi bi-plus-circle-fill mr1"></i>
                                            Ajouter un bien
                                        </Link>
                                    </li> : ''}
                                    {userData.libelle_role === "Proprietaire" ?
                                        <li className={`${isActive('/user/agent/logements/liste/', location) ? 'active' : ''}`}>
                                            <Link to="/user/agent/logements/liste/" >
                                                <i class="bi bi-card-checklist"></i>
                                                Lite des logements
                                            </Link>
                                        </li> : ''
                                    }
                                    {userData.libelle_role === "Proprietaire" ?
                                        <li className={`${isActive('/user/agent/biens-immobiliers/liste/', location) ? 'active' : ''}`}>
                                            <Link to="/user/agent/biens-immobiliers/liste/" >
                                                <i class="bi bi-card-checklist"></i>
                                                Liste des biens
                                            </Link>
                                        </li> : ''
                                    }
                                    {/* {userData.libelle_role === "Proprietaire" ?
                                        <li className={`${isActive('/user/agent/pannes-signales/liste/', location) ? 'active' : ''}`}>
                                            <Link to="/user/agent/pannes-signales/liste/" >
                                                <i class="bi bi-card-checklist"></i>
                                                Lite des biens réserves
                                            </Link>
                                        </li> :''    
                                    } */}
                                     
                                    {userData.libelle_role === "Proprietaire" ?
                                        <li className={`${isActive('/user/agent/liste-reservations/', location) ? 'active' : ''}`}>
                                                <Link to="/user/agent/liste-reservations/" >
                                                    <i class="bi bi-card-checklist"></i>
                                                    Liste des logements réservés
                                                </Link>
                                        </li>   :'' 
                                    }
                                    {userData.libelle_role === "Proprietaire" ?
                                        <li className={`${isActive('/user/agent/biens-immobiliers/reservations/', location) ? 'active' : ''}`}>
                                            <Link to="/user/agent/biens-immobiliers/reservations/" >
                                                <i class="bi bi-card-checklist"></i>
                                                Liste des biens réservés
                                            </Link>
                                        </li> :''    
                                    }
                                    {userData.libelle_role === "Proprietaire" ?
                                        <li className={`${isActive('/user/agent/visite/liste/', location) ? 'active' : ''}`}>
                                                <Link to="/user/agent/visite/liste/" >
                                                    <i class="bi bi-card-checklist"></i>
                                                    Liste des visites
                                                </Link>
                                        </li>   :'' 
                                    }
                                    {userData.libelle_role === "Etudiant" ? 
                                        <li className={`${isActive('/user/signaler-pannes', location) ? 'active' : ''}`}>
                                            <Link to="/user/signaler-pannes" >
                                                <i class="bi bi-exclamation-octagon mr1"></i>
                                                Signaler une panne
                                            </Link>
                                        </li>: ''
                                    }
                                    {userData.libelle_role === "Etudiant" ?
                                        <li className={`${isActive('/user/liste-pannes/', location) ? 'active' : ''}`}>
                                            <Link to="/user/liste-pannes/">
                                                <i class="bi bi-card-list"></i>
                                                Pannes enregistrés
                                            </Link>
                                        </li> :''
                                    }
                                    {userData.libelle_role === "Etudiant" || userData.libelle_role ==="SimpleClient" ? 
                                    <li className={`${isActive('/user/visites/', location) ? 'active' : ''}`}>
                                        <Link to="/user/visites/">
                                            <i class="bi bi-card-list"></i>
                                           Visites
                                        </Link>
                                    </li> :''
                                    }
                                    {userData.libelle_role === "Etudiant" || userData.libelle_role ==="SimpleClient" ?
                                        <li className={`${isActive('/user/liste-reservations/bien-immobilier', location) ? 'active' : ''}`}>
                                            <Link to="/user/liste-reservations/bien-immobilier" >
                                                <i class="bi bi-card-checklist"></i>
                                                Lite des biens réserves
                                            </Link>
                                        </li> :''    
                                    }
                                    {userData.libelle_role === "Etudiant" || userData.libelle_role ==="SimpleClient" ?
                                        <li className={`${isActive('/user/reservations/', location) ? 'active' : ''}`}>
                                            <Link to="/user/reservations/">
                                                <i class="bi bi-list-ul"></i>
                                               Logements Réservés
                                            </Link>
                                        </li> :''}
                                        {/* {userData.libelle_role === "Etudiant" || userData.libelle_role === "SimpleClient" ?
                                        <li className={`${isActive('/user/effectuer-paiement', location) ? 'active' : ''}`}>
                                            <Link to="/user/effectuer-paiement">
                                            <i className="bi bi-credit-card"></i>
                                            Effectuer un paiement
                                            </Link>
                                        </li>
                                        : ''
                                        } */}
                                    <li className={`${isActive('/user/update-password', location) ? 'active' : ''}`}>
                                        <Link to="/user/update-password">
                                            <i class="bi bi-lock"></i>
                                          Changer le mot de passe
                                        </Link>
                                    </li>
                                    {/* <li className={`${isActive('/', location) ? 'active' : ''}`}>
                                        <Link to="#">
                                            <i class="bi bi-bell"></i>
                                          Notifications 
                                        </Link>
                                    </li> */}
                                    {/* <li className={`${isActive('/user/chats/', location) ? 'active' : ''}`}>
                                        <Link to="/user/chats/">
                                            <i class="bi bi-chat-dots-fill"></i>
                                          Messages
                                        </Link>
                                    </li> */}
                                   {
                                    (userData.libelle_role === "Etudiant" || userData.libelle_role === "SimpleClient") && (
                                        <li className={`${isActive('/user/preferences/add', location) ? 'active' : ''}`}>
                                        <Link to="/user/preferences/add">
                                            <i className="bi bi-gear"></i>
                                            Mes préferences
                                        </Link>
                                        </li>
                                    )
                                    }


                                    <li>
                                        <a className="no-underline mr2 black-50 hover-black-70 pv1 ph2 db" href="#"  title="Logout">
                                            <i class="bi bi-box-arrow-right mr1"></i>
                                            <span onClick={() => logoutHandler}>Déconnexion</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default SideBarDashbord;
