import  {Link } from 'react-router-dom'
const ResidenceItem = ({ residence }) => {
    return (
        <>
            <div className="col-lg-6 col-md-12" key={residence.id}>
                <div className="property-listing property-1">
                    <div className="listing-img-wrapper">
                        <Link to={`/details-residence/${residence?.id}`}>
                            <img src={residence?.image_logement}  alt="" className="img-fluid mx-auto lazy entered loaded" data-ll-status="loaded" /></Link> 
                        <div className="icon-actions-wrapper"><a href="JavaScript:Void(0);" data-id="16" className="add-to-wishlist"><i className="far fa-heart"></i>
                        </a>
                        </div>
                    </div>
                    <div className="listing-content">
                        <div className="listing-detail-wrapper-box">
                        <div className="listing-detail-wrapper">
                            <div className="listing-short-detail">
                                <h4 className="listing-name">
                                    <Link to={`/details-residence/${residence?.id}`} >
                                        {residence?.commune_logement+  "   /"}
                                    </Link>
                                    {residence.nombre_pieces ? residence.nombre_pieces : "x"} pièces
                                </h4>
                            </div>
                            <div className="list-price">
                                <div>
                                    <div data-star="3.15" className="rating_wrap">
                                    </div>
                                    {residence?.statut_logement==="disponible" ?<span class="prt-types rent">{residence?.statut_logement}</span> :<span className="prt-types sale">{residence?.statut_logement}</span>}
                                </div>
                                
                               {residence?.libelletypelogement==="Etudiants" ? <h6 className="listing-card-info-price"> {residence?.tarif_mensuel.toString().replace(".00", "")} cfa </h6> : residence?.libelletypelogement==="Residences" || residence?.libelletypelogement==="Hotels" ? <h6 className="listing-card-info-price"> {residence.tarif_mensuel.toString().replace(".00", "")} /jour </h6> :""}
                            </div>
                        </div>
                        </div>
                        <div class="price-features-wrapper">
                        <div class="list-fx-features">
                            {/* <div class="listing-card-info-icon">
                                <div class="inc-fleat-icon">
                                    {residence?.is_male ? <i class="bi bi-gender-male"></i> : <i class="bi bi-gender-female"></i>}
                                </div>
                                {residence?.is_male===true ? "Homme" : "Femme"}
                            </div> */}
                            {/* <div class="listing-card-info-icon">
                                <div class="inc-fleat-icon"><img src="https://resido.thesky9.com/themes/resido/img/move.svg" alt="" width="13" /></div>
                                245 m²
                            </div> */}
                        </div>
                        </div>
                        <div className="listing-footer-wrapper">
                            <div class="footer-first">
                                <div class="foot-location d-flex">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
                                    <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10"/>
                                    <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
                                </svg>
                                    {residence?.quartier_logement}
                                </div>
                            </div>
                            <div className="listing-detail-btn">
                                <Link to={`/details-residence/${residence?.id}`} className="more-btn">Voir</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResidenceItem