import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import { NavLink, useNavigate,useLocation } from "react-router-dom"
import React, { useState } from "react";
import Swal from 'sweetalert2';
import axios from 'axios'
import dns from "../utils/dns";
import { loginSuccess, setUserToken,setConnected } from "../store/reducers/userSlice";
import { useDispatch } from 'react-redux';
import { PhoneInput } from 'react-international-phone';

const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const Navigate = useNavigate()
    const location = useLocation();

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        // Effectuez votre traitement de connexion ici avec les valeurs de username et password
        // par exemple, effectuer une requête POST vers votre API pour vérifier les informations d'identification
        const formData = new FormData();
        formData.append("username", username);
        formData.append("password", password);
        if (username !== " " && password !== "") {
            try {
                axios.post(dns + '/api/auth/login', formData)
                .then(res => {
                    // Faire quelque chose avec la réponse, comme rediriger l'utilisateur vers une autre page
                    if (res.status === 200) {
                        setLoading(false)
                        // Réinitialisez les champs du formulaire
                        setUsername("");
                        setPassword("");
                        dispatch(loginSuccess(res.data.user));
                        dispatch(setUserToken(res.data.token));
                        dispatch(setConnected({ connected: 1 })); 
                        Swal.fire({
                            title:'Connexion', 
                            text:`Bienvenu cher utilisateur sur votre compte`, 
                            icon: 'success', 
                            button:'OK',
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: () => {
                                Swal.showLoading()
                            },
                        })
                        
                        if (location.search) {
                        const queryParams = new URLSearchParams(location.search);
                        const fromPath = queryParams.get('from');
                        if (fromPath) {
                            Navigate(fromPath);
                            return;
                        }
                        } else {
                            // Rediriger vers une page par défaut si pas de page précédente
                            if (res.data.user.libelle_role === "Admin") {
                                Navigate('/admin/dashbord/')
                            } else {
                                Navigate('/user/profile')
                            }
                        //; // Par exemple, la page d'accueil
                        }
                        //then(Navigate('/user/profile'))
                    } else {
                        setLoading(false)
                        // Réinitialisez les champs du formulaire
                        setUsername("");
                        setPassword("");
                        Swal.fire({
                            title:'Connexion', 
                            text:`Identifiants de connexion incorrecte`, 
                            icon: 'error', 
                            button:'OK',
                            timer: 4000
                        })
                    }
                })
                .catch(error => {
                    setLoading(false)
                    // Réinitialisez les champs du formulaire
                    setUsername("");
                    setPassword("");
                    console.error("error:", error)
                    Swal.fire({
                        title:'Connexion', 
                        text:`Impossible de se connecter avec les identifiants fournis`, 
                        icon: 'error', 
                        button:'OK',
                        timer: 4000
                    })
                })
            
            } catch (error) {
                setLoading(false)
                // Réinitialisez les champs du formulaire
                setUsername("");
                setPassword("");
                console.error(error);
                Swal.fire({
                    title:'Connexion', 
                    text:`Impossible de se connecter avec les identifiants fournis`, 
                    icon: 'error', 
                    button:'OK',
                    timer:4000
                })
            }
        } else {
            setLoading(false)
            // Réinitialisez les champs du formulaire
            setUsername("");
            setPassword("");
            Swal.fire({
                title:'Connexion', 
                text:`Veuillez remplir correctement le formulaire`, 
                icon: 'error', 
                button:'OK',
                timer: 3000
            })
        }
        
    };
    return (
        <>  
            <Header />
            <div id="app">
                <section>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-6">
                            <div id="registermodal" className="modal-content">
                                <div className="modal-body">
                                    <h2 className="text-center">Connexion</h2>
                                    <br/> 
                                    <div className="login-form">
                                        <form className="simple-form" onSubmit={handleSubmit}> 
                                        <div className="form-group">
                                            <label> Numéro de téléphone</label>
                                                <PhoneInput
                                                    defaultCountry="ci"
                                                    value={username}
                                                    onChange={(username) => setUsername(username)}
                                                    required
                                                    inputClassName="form-control"
                                                    className="form-control"
                                                />
                                            
                                        </div>
                                        <div className="form-group ">
                                            <label>Mot de passe</label> 
                                            <div className="input-with-icon">
                                                <input id="password" type="password" placeholder="************" value={password}  onChange={(e) => setPassword(e.target.value)} class="form-control" />
                                                <i className="bi bi-unlock"></i>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="checkbox">
                                                        <label>
                                                        <input type="checkbox" name="remember" className="ml-2" checked={isChecked} onChange={handleCheckboxChange} />
                                                            Se souvenir de moi
                                                    </label>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 text-md-center">
                                                    <NavLink to="/request-password-resset" className="link">
                                                            Mot de passe oublié?
                                                    </NavLink>                                                   
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            {/* <button type="submit" className="btn btn-primary btn-block fw6 mt-4">
                                                {loading ? <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span> : null}
                                                {loading ? "Enregsitrement en cours..." : "Enregistrer"}
                                            </button> */}
                                            <button type="submit" className="btn btn-md full-width  btn-primary rounded" disabled={loading}>
                                                {loading ? <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span> : null}
                                                {loading ? "Connexion en cours..." : " Se connecter"}
                                            </button>
                                        </div>
                                        <div class="form-group text-center">
                                            <p>Vous n'avez pas de compte? <NavLink to="/auth/register" class="link d-block d-sm-inline-block text-sm-left text-center">Créer un compte</NavLink></p>
                                        </div>
                                        
                                        <div className="text-center"></div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </>
    )
}

export default Login