import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import dns from '../../../../utils/dns';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useSelector } from 'react-redux';

const DataTableReservationBienAdmin = ({ Logements }) => {
    const [loading, setLoading] = useState(true);
    const token = useSelector((state) => state.user.token);

    const DeleteLogement = (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: true
        })

        swalWithBootstrapButtons.fire({
            title: 'Etes vous sur de supprimer cette reservation ?',
            text: "Vous ne pourrez pas revenir en arrière !",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirmer',
            cancelButtonText: 'Annuler!',
            reverseButtons: true
        })
        .then((result) => {
            if (result.isConfirmed) {
                axios.delete(dns + `/api/reservation-biens/${id}/`, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    }
                })
                .then((response) => {
                    if (response.status === 204) {
                        Swal.fire({
                            title: 'Suppression de réservation',
                            text: `La réservation a été supprimé avec succès`,
                            icon: 'success',
                            button: 'OK',
                            timer: 4000
                        })
                        window.location.reload();
                    } else {
                        Swal.fire({
                            title: 'Suppression de réservation',
                            text: `Nous avons rencontré une erreur lors du traitement de votre requête`,
                            icon: 'error',
                            button: 'OK',
                            timer: 4000
                        })
                    }
                })
            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'Annulé',
                    "L'action a échoué :)",
                    'warning'
                )
            }
        })
    }

    useEffect(() => {
        // Simulate an asynchronous data loading process
        setTimeout(() => {
            setLoading(false);
        }, 3000); // Simulating a 3-second loading time
    }, []);

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="fr-grid-footer-flex-right">
                <Link to={`/user/agent/reservations/${rowData?.id}/details-reservation-bien/`} className="prt-view">
                    <i className="bi bi-eye" />
                </Link>
                <Link
                    className="prt-danger"
                    style={{ marginLeft: '4px' }}
                    onClick={() => DeleteLogement(rowData?.id)}
                    to="#"
                >
                     <i className="bi bi-trash" />
                </Link>
            </div>
        );
    }

    return (
        <div className="card-body">
            <DataTable value={Logements} paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} loading={loading}  emptyMessage="Aucune donnée disponible pour le moment">
                <Column field="bien.image_bien1" header="Image" body={(rowData) => (
                    <img
                        src={rowData?.bien?.image_bien1}
                        alt=""
                        className="img-fluid"
                        style={{ width: '50px', height: '50px' }}
                    />
                )} />
                <Column field="client.username" header="Client" />
                <Column field="bien.libelle_type_bien" header="Type de bien" />
                <Column field="bien.localisation" header="Localisation" body={(rowData) => (rowData?.bien?.localisation?.substring(0,10) + "...")}/>
                <Column field="bien.prix" header="Prix" body={(rowData) => (rowData?.bien?.prix?.toString().replace(".00", ""))}/>
                <Column field="bien.surface" header="Surface (m²)" body={(rowData) => (rowData?.bien?.surface?.toString().replace(".00", ""))}/>
                {/* <Column field="bien.description" header="Description" body={(rowData) => (rowData?.bien?.description?.substring(0,20) + "...")}/> */}
                <Column field="statut_reservation" header="Statut" body={(rowData)=> rowData?.statut_reservation === "en_attente" ? "En attente" :  rowData?.statut_reservation === "confirmee" ? "Confirmée" : "Annulée"}></Column>
                <Column body={actionBodyTemplate} header="Actions" />
            </DataTable>
        </div>
    );
}

export default DataTableReservationBienAdmin;
