import Header from "../../../../components/Header/Header";
import Footer from "../../../../components/Footer/Footer"
import SideBarDashbordAdmin from "../../Sidebar";
import React, {useRef, useState,useEffect} from 'react'
import Swal from 'sweetalert2';
import axios from 'axios'
import dns from "../../../../utils/dns";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import icon from "../../../../public/img/position.png"
import { nombrepieces } from "../../../helpers";
import search_icon2 from "../../../../public/img/images/search-icon.svg"
import { GoogleMap, useLoadScript, Marker ,Autocomplete, DirectionsRenderer} from '@react-google-maps/api';
import LoadingSpinnerCircular from "../../../../components/spiner";
import { GOOGLE_KEY } from '../../../../utils/map';
const libraries = ['places'];
const mapContainerStyle = {
  width: '100%',
  height: '100vh',
};


const AddLogementAgent = () => {
    
    const userData = useSelector((state) => state.user.user);
    const token = useSelector((state) => state.user.token);
    const [fileList, setFileList] = useState([]);
    const [localisation, setLocalisation] = useState("");
    const [typelogements, setTypeLogement] = useState([]);
    const [map, setMap] = useState(null);
    const [marker, setMarker] = useState(null);
    const [loading, setLoading] = useState(false);
    const Navigate = useNavigate()
    const DescriptionInput = useRef(null)
    const SearchRef = useRef(null)
    const QuartierInput = useRef(null)
    const CommuneInput = useRef(null)
    const LocalisationInput = useRef(null)
    const GenreLogementInput = useRef(null)
    const TypelogementInput = useRef(null)
    const NombrepiecesInput = useRef(null)
    const checkboxInput = useRef(null)
    const TarifInput = useRef(null)
    const StatutLogementInput = useRef(null)
    const [searchResult, setSearchResult] = useState('')
    const autocompleteRef = useRef();
    const center = {
        lat: 5.305214214944959, // default latitude
        lng: -4.007436038379436, // default longitude
    };
    

    const onFileDrop = (e) => {
        const newFile = e.target.files;
        setFileList(newFile);
        
    }


    const handleSubmit = async (event) => {
        event.preventDefault()
        setLoading(true)
        const formdata = new FormData()
        const uploadedFile = fileList[0] ?? null;
        const uploadedFile1 = fileList[1] ?? null;
        const uploadedFile2 = fileList[2] ?? null;
        const uploadedFile3 = fileList[3] ?? null;
        const uploadedFile4 = fileList[4] ?? null;
        const is_male = isChecked ? true:false;
        formdata.append("client", userData.id)
        formdata.append("quartier_logement", QuartierInput.current.value)
        formdata.append("commune_logement", CommuneInput.current.value)
        formdata.append("type_logement", TypelogementInput.current.value)
        formdata.append("nombre_pieces", NombrepiecesInput.current.value)
        formdata.append("is_male", GenreLogementInput.current.value)
        if (LocalisationInput.current.value !==null){
            formdata.append("localisation", LocalisationInput.current.value)
        } 
        
        formdata.append("tarif_mensuel", TarifInput.current.value)
        formdata.append("is_male", is_male)
        formdata.append("description", DescriptionInput.current.value)
        formdata.append("statut_logement", StatutLogementInput.current.value)
        if (uploadedFile){
            formdata.append("image_logement",  uploadedFile)
        }
        if (uploadedFile1){
            formdata.append("image_logement1",  uploadedFile1)
        }
        if (uploadedFile2){
            formdata.append("image_logement2",  uploadedFile2)
        }
        if (uploadedFile3){
            formdata.append("image_logement3",  uploadedFile3)
        }
        if (uploadedFile4){
            formdata.append("image_logement4",  uploadedFile4)
        }

        if (formdata && token) {
            try {
                const response = await axios.post(dns + `/api/logement/`, formdata, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${token}`,
                    },
                })

                if (response.status === 201) {
                    setLoading(false)
                    Swal.fire({
                        title:'Enregistrerment de residence', 
                        text:`Le logement à été enregistrer avec succès`, 
                        icon: 'success', 
                        button:'OK',
                        timer: 3000,
                        timerProgressBar:true,
                        didOpen:()=>{
                            Swal.showLoading();
                        }
                    })
                    Navigate('/admin/dashbord/logements/liste')
                } else {
                    setLoading(false)
                    Swal.fire({
                        title:'Enregistrerment de residence', 
                        text:`Impossible d'enregistrer votre logement pour le moment`, 
                        icon: 'error', 
                        button:'OK',
                        timer: 3000
                    })
                }
            } catch (error) {
                setLoading(false)
                Swal.fire({
                    title:'Enregistrerment de residence', 
                    text:`Le serveur à rencontrer un problème lors du traitement de votre requête `, 
                    icon: 'error', 
                    button:'OK',
                    timer: 4000,
                    
                })
            }
        }
    }

    const [position, setPosition] = useState({ lat:5.305214214944959, lng: -4.007436038379436 });
    const [keyword, setKeyword] = useState('');
    const [isChecked, setIsChecked] = useState(false);
   
    const handleSearch = (event) => {
        const { value } = event.target;
        setKeyword(value);
    };
   
    const handleCheckboxChange = () => {
    setIsChecked(checkboxInput.current.checked);
  };
    function onLoad(autocomplete) {
        setSearchResult(autocomplete);
    }

  function onPlaceChanged() {
  if (searchResult != null) {
    const place = searchResult.getPlace();
    const name = place?.name;
    // const status = place.business_status;
    setKeyword(name);
    // Use place.geometry.location.lat() and place.geometry.location.lng()
    const lat = place?.geometry?.location?.lat();
    const lng = place?.geometry?.location?.lng();
    setPosition({ lat, lng });
    // Update the input field with the coordinates
    LocalisationInput.current.value = `${lat},${lng}`;
  } else {
    alert("Please enter text");
  }
}

   
    const searchPlaces = () => {
        const autocompleteService = new window.google.maps.places.AutocompleteService();
        const placesService = new window.google.maps.places.PlacesService(map);
    
        autocompleteService.getPlacePredictions({ input: keyword }, (predictions, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
                const firstPrediction = predictions[0];
                if (firstPrediction) {
                    placesService.getDetails({ placeId: firstPrediction.place_id }, (place, status) => {
                        if (status === window.google.maps.places.PlacesServiceStatus.OK && place) {
                            const location = place.geometry.location;
                            const lat = location.lat();
                            const lng = location.lng();
                            const position = { lat, lng };
                            // Do something with the position (e.g., setMarker(position))
                        }
                    });
                }
            }
        });
    }
    

    const GetTypeLogment = async() => {
        try {
            const response = await axios.get(dns + `/api/type-logement/`,{
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            if (response.status === 200) {
                setTypeLogement(response.data.reverse())
            }
        } catch (error) {
            console.error(error)
        }
    }
    
   useEffect(() => {
    GetTypeLogment()
}, []); 

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: GOOGLE_KEY,
        libraries,
        
    });

    if (loadError) {
        return <div>Error loading maps</div>;
    }

    if (!isLoaded) {
        return <div>Chargementde la carte..</div>;
    }
    

    return (

        <>
            <Header />
            <div className="page-title">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <h1 className="ipt-title">Ajouter un logement</h1>
                            <span className="ipn-subtitle"></span>
                        </div>
                    </div>
                </div>
            </div>
            <section className="bg-light">
                <div className="container-fluid">
                    <div className="row">
                        <SideBarDashbordAdmin />
                        <div class="col-lg-3 col-md-12">
                            <div id="app" >
                                <div class="dashboard-wraper1">
                                    <form class="js-base-form dirty-check" enctype="multipart/form-data" onSubmit={handleSubmit}>
                                        <div class="row">
                                            <div class="col-md">
                                                <div class="dashboard-wraper">
                                                    <div class="main-form">
                                                    <div class="form-body">
                                                        <div class="form-group mb-3">
                                                            <label for="images" class="control-label">Quartier</label> 
                                                                <div  class="dropzone needsclick dz-clickable">
                                                                    <div class="dz-message needsclick">
                                                                        <input type="text" ref={QuartierInput} required class="form-control" placeholder='Entrer le quartier'/>    
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form-group mb-3">
                                                            <label for="images" class="control-label">Commune</label>
                                                                <div  class="dropzone needsclick dz-clickable">
                                                                    <div class="dz-message needsclick">
                                                                        <input type="text" ref={CommuneInput} required class="form-control" placeholder='Entrer la commune'/>    
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form-group mb-3">
                                                            <label for="images" class="control-label">Nombre de pièces</label>
                                                                <div  class="dropzone needsclick dz-clickable">
                                                                    <div class="dz-message needsclick">
                                                                        <select class="form-control ui-select is-valid" ref={NombrepiecesInput} id="type_id" aria-invalid="false">
                                                                            {nombrepieces.length>0 ? nombrepieces.map((nbpiece)=>(
                                                                                <option value={nbpiece?.value}>{nbpiece?.key}</option>
                                                                            )) :""}
                                                                        </select>    
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form-group mb-3">
                                                                <label for="description" class="control-label">Description</label>
                                                                <textarea class="form-control" rows="4" placeholder="Decrivez la résidence" required data-counter="350" ref={DescriptionInput} cols="50" id="description"></textarea>
                                                            </div>
                                                            <div class="form-group mb-3">
                                                            <label for="images" class="control-label">Selectionner les images</label>
                                                                <div id="multiple-upload" class="dropzone needsclick dz-clickable">
                                                                    <div class="dz-message needsclick">
                                                                        <input type="file" multiple id="" required class="form-control" accept="image/*" onChange={onFileDrop} />    
                                                                    </div>
                                                                </div>
                                                            </div>
                                                                <div class="form-group mb-3">
                                                                <label for="images" class="control-label">Localisation</label>
                                                                    <div  class="dropzone needsclick dz-clickable">
                                                                        <div class="dz-message needsclick">
                                                                            <input type="text" ref={LocalisationInput} required class="form-control" placeholder='Entrer la localisation' disabled />    
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="form-group mb-3">
                                                                <label for="images" class="control-label">Logement homme ou femme ?</label>
                                                                    <div  class="dropzone needsclick dz-clickable">
                                                                        <div class="dz-message needsclick">
                                                                            <select class="form-control ui-select is-valid" defaultValue={true} ref={GenreLogementInput} id="type_id" aria-invalid="false">
                                                                                <option  value={true}>Résidence pour homme</option>
                                                                                <option  value={false}>Résidence pour femme </option>
                                                                            </select>    
                                                                        </div>
                                                                    </div>
                                                            </div>
                                                            <div class="form-group mb-3">
                                                            <label for="images" class="control-label">Type de logement</label>
                                                                <div  class="dropzone needsclick dz-clickable">
                                                                    <div class="dz-message needsclick">
                                                                        <select class="form-control ui-select is-valid" ref={TypelogementInput} id="type_id" aria-invalid="false">
                                                                            {typelogements.length>0 ? typelogements.map((typelogement)=>(
                                                                                <option value={typelogement?.id}>{typelogement?.libelle_type_logement}</option>
                                                                            )) :""}
                                                                        </select>    
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form-group mb-3">
                                                            <label for="images" class="control-label">Tarif</label>
                                                                <div  class="dropzone needsclick dz-clickable">
                                                                    <div class="dz-message needsclick">
                                                                        <input type="number" ref={TarifInput}  class="form-control" required placeholder='Entrer le tarif du bien'/>    
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* <div class="form-group mb-3">
                                                                <input id="features-1" class="input-filter checkbox-custom" type="checkbox" ref={checkboxInput}  onChange={handleCheckboxChange}/>
                                                                <label for="features-1" class="checkbox-custom-label">Résidence pour home ou femme ?</label>
                                                            </div> */}
                                                            <div class="form-group mb-3">
                                                            <label for="images" class="control-label">Statut du logement</label>
                                                                <div  class="dropzone needsclick dz-clickable">
                                                                    <div class="dz-message needsclick">
                                                                        <select class="form-control ui-select is-valid" required ref={StatutLogementInput} id="type_id" aria-invalid="false">
                                                                            <option data-code="sale" value="disponible">Disponible</option>
                                                                            <option data-code="rent" value="indisponible ">Indisponible </option>
                                                                        </select>    
                                                                    </div>
                                                                </div>
                                                        </div>
                                                           <button type="submit" className="btn btn-primary btn-block fw6 mt-4" disabled={loading}>
                                                                {loading ? <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span> : null}
                                                                {loading ? "Enregsitrement en cours..." : "Enregistrer"}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        {isLoaded ? <div className="col-lg-6">
                            <div className="card">
                                <div className="card-body">
                                    <div id="search-box">
                                        <Autocomplete
                                           onPlaceChanged={onPlaceChanged}
                                           onPlaceSelected={onPlaceChanged}
                                            onLoad = {onLoad}
                                        >
                                            <InputText id="search-input" value={keyword} ref={SearchRef}
                                                onChange={handleSearch} placeholder="Rechercher un lieux sur la carte..."/>
                                        </Autocomplete>
                                        <img src={search_icon2} alt="" onClick={searchPlaces} />
                                    </div>
                                    <GoogleMap
                                        ref={map}
                                        mapContainerStyle={mapContainerStyle}
                                        zoom={17}
                                        center={position}
                                        options={{
                                            zoomControl: true,
                                            streetViewControl: true,
                                            mapTypeControl: true,
                                            fullscreenControl: true,
                                        }}
                                        onLoad={map => setMap(map)}

                                        onClick={ev => {
                                            <Marker position={{lat:ev?.latLng?.lat(), lng:ev?.latLng?.lng()}}  icon={icon}/>
                                            }}
                                        >
                                        <Marker position={position}  icon={icon}/>
                                    </GoogleMap>
                                </div>
                            </div>
                        </div> : <LoadingSpinnerCircular />}
                    </div>
                </div>
            </section>   
            <Footer />
        </>
    )
}

export default AddLogementAgent;