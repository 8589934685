import Header from "../../components/Header/Header"
import Footer from "../../components/Footer/Footer"
import PageTitle from "../Admin/components/PageTitle"
import SideBarDashbord from "./common"
import { useRef,useEffect,useState} from "react"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import axios from "axios"
import dns from "../../utils/dns"
import Swal from "sweetalert2"
import { initializeApp } from 'firebase/app';
import { getMessaging,getToken  } from "firebase/messaging";
const CreatePreference = () => {
    const localisationInput= useRef(null)
    const Navigate= useNavigate()
    const prix_maxInput = useRef(null)
    const surface_minInput = useRef(null)

    const type_bienInput = useRef(null)
    const token = useSelector((state)=> state.user.token)
    const user = useSelector((state)=> state.user.user)
    const [typeDebiens, setTypesBiens] = useState([])
    const [navigatorToken, setNavigatorToken] = useState("");
    // Your web app's Firebase configuration
    const firebaseConfig = {
        apiKey: "AIzaSyBnjSSKQUVKba4hA6cT-GQKN_E4Ab-dABg",
        authDomain: "residenceit-dce7e.firebaseapp.com",
        projectId: "residenceit-dce7e",
        storageBucket: "residenceit-dce7e.appspot.com",
        messagingSenderId: "1087097286845",
        appId: "1:1087097286845:web:63a40cf6aa377ef8a3c7d4",
        vapidKey: "BAYiv1xJqQ0neW2vn37EobW4gs9JhRqz8tDbMmdSCN9LyIC3buAEpBDx9pUYY5l48TQfbrIxBbL8uPK8FOA8Qwc" // Add this line
    };
    
    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app);
    const requestForToken = async () => {
        try {
            const currentToken = await getToken(messaging, { vapidKey: "BAYiv1xJqQ0neW2vn37EobW4gs9JhRqz8tDbMmdSCN9LyIC3buAEpBDx9pUYY5l48TQfbrIxBbL8uPK8FOA8Qwc" });
            if (currentToken) {
                setNavigatorToken(currentToken)
                const response = await axios.get(dns + `/api/devices/${currentToken}/`, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (response.status === 404) {
                    console.log('No registration token available.');
                }
                // Perform any other necessary action with the token
            } else {
                // Show permission request UI
                console.log('No registration token available. Request permission to generate one.');
            }
        } catch (err) {
            console.log('An error occurred while retrieving token. ', err);
            let Data = new FormData()
            Data.append("name", "Registration_device")
            Data.append("registration_id",  localStorage.getItem("currentToken"))
            Data.append("type", "web")
            const res = await axios.post(dns + `/api/devices/`, Data, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });

            if (res.status === 201) {
                console.log('The device has been registered successfully!');
            } else {
                console.log('The device is already registered');
            }
        } 
    };


    const SaveUserPreference = async (e) => {
        e.preventDefault();
        if (localStorage.getItem("currentToken")) {
            try {
                let data ={
                localisation:localisationInput.current.value,
                prix_max:prix_maxInput.current.value,
                surface_min:surface_minInput.current.value,
                type_bien:type_bienInput.current.value,
                client:user.id
            }
                
            const response = await axios.post(dns + `/api/preferences/`,data,{
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            }) 
            if(response.status===201){
                Swal.fire({
                    title:'Préférence', 
                    text:`Votre préférence a été bien enregistrer avec succès`, 
                    icon: 'success', 
                    button:'OK',
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: () => {
                        Swal.showLoading()
                    },
                })
                
                Navigate("/user/profile")
            }
            
          } catch (error) {
            console.error(error); // Log the error for debugging purposes
            Swal.fire({
              title: 'Préférence',
              text: `Une erreur s'est produite lors de l'enregistrement de votre terminal`,
              icon: 'error',
              button: 'OK',
              timer: 3000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
              },
            });
          }
        }
      };
      

    const GetTypedeBiens = async() => {

        try {
            const response = await axios.get(dns + `/api/type-biens/`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            if (response.status === 200) {
                setTypesBiens(response.data)
            }
        } catch (error) {
            
        }
    }


    useEffect(() => {
        GetTypedeBiens();
        // const fetchData = async () => {
        //     await requestForToken();
        // };
        // fetchData();
    },[])
    return (

        <>
            <Header />
            <PageTitle title={"Ajouter une préference"} />
            <section className="gray-simple">
                <div className="container-fluid">
                    <div className="row">
                        <SideBarDashbord />
                        <div className="col-lg-8 col-md-12">
                            <div id="app">
                                <div className="row">
                                    <div className="dashboard-wraper settings crop-avatar">
                                        <div className="form-submit">
                                            <div className="row">
                                                <div className="col-12">
                                                    <h4 className="with-actions">Enregsitrer une préference</h4>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <form onSubmit={SaveUserPreference}>
                                                    <div className="form-group">
                                                        <label htmlFor="comment">Localisation *</label>
                                                        <input type="text" placeholder="Localisation.." className="form-control" ref={localisationInput}/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="comment">Prix maximun *</label>
                                                        <input type="text" placeholder="Le prix mmaximun du bien..." className="form-control" ref={prix_maxInput} />
                                                    </div>
                                                     <div className="form-group">
                                                        <label htmlFor="comment">Surface (m²) *</label>
                                                        <input type="text" placeholder="La superficie du bien..." className="form-control" ref={surface_minInput} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="bien">Type de bien *</label>
                                                        <select ref={type_bienInput} id="bien" className="form-control">
                                                           { typeDebiens.map((bien) =>(<option value={bien?.id}>{bien?.libelle}</option>))}
                                                       </select>
                                                    </div>
                                                    <button type="submit" className="btn btn-primary fw6">Enregistrer</button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default CreatePreference