import SideBarDashbord from "./common"
import Header from "../../components/Header/Header"
import Footer from "../../components/Footer/Footer"
import axios from "axios"
import dns from "../../utils/dns"
import React ,{useEffect, useState} from 'react'
import Swal from "sweetalert2"
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
const PaimenetScreenUser = ()=>{
    const user =useSelector((state) => state.user.user);
    const token =useSelector((state) => state.user.token);
    const [selectedItem, setSelectedItem] = useState(null);
    const values=[];
    const HandleChange = (event) => {
        const { id, checked } = event.target;
        if (checked) {
            if (selectedItem !== null) {
                Swal.fire({
                    title: 'Reservation de logement',
                    text: `Veuillez sélectionner un seul moyen de paiement`,
                    icon: 'error',
                    button: 'OK',
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: () => {
                    Swal.showLoading();
                    },
                });
                 document.getElementById(selectedItem).checked = false;
                return; // Empêche la sélection de plus d'un élément
            }
            setSelectedItem(id);
            values.push(id);
        } else {
            setSelectedItem(null);
        }
    };

    
    const typebien = "";
    return (
        <>
        <Header />
            <div id="app" >
                <div class="page-title">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <h1 class="ipt-title">Effectuer un paiement</h1>
                                <span class="ipn-subtitle"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <section>
                    <div className="container">
                        <div class="row">
                            <div class="col text-center">
                                <div class="sec-heading center">
                                    <h2>Nos moyens de paiements</h2>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="scontent">
                                    <div class="ck-content">
                                        <div class="row mb-5">
                                            <div class="col-lg-4">
                                                <h4 class="pt-4 fw-bold pb-3">Veuillez cochez un moyen de paiement</h4>
                                                <div class="card mb-2">
                                                    <div class="d-flex align-items-center justify-content-between">
                                                        <div>
                                                            <img src="https://resido.thesky9.com/storage/properties/p-11.jpg" alt="" style={{width:200 + "px"}}/>
                                                        </div>
                                                        <div class="d-flex align-items-center">
                                                            <div class="form-check form-switch"> 
                                                            <input class="form-check-input" type="checkbox" id="WaveCheck"  onChange={HandleChange}/> </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card mb-2">
                                                    <div class="d-flex align-items-center justify-content-between">
                                                        <div>
                                                            <img src="https://resido.thesky9.com/storage/properties/p-11.jpg" alt="" style={{width:200 + "px"}}/>
                                                        </div>
                                                        <div class="d-flex align-items-center">
                                                            <div class="form-check form-switch"> 
                                                            <input class="form-check-input" type="checkbox" id="OrangeCheck"  onChange={HandleChange}/> </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card mb-2">
                                                    <div class="d-flex align-items-center justify-content-between">
                                                        <div>
                                                            <img src="https://resido.thesky9.com/storage/properties/p-11.jpg" alt="" style={{width:200 + "px"}}/>
                                                        </div>
                                                        <div class="d-flex align-items-center">
                                                            <div class="form-check form-switch"> 
                                                            <input class="form-check-input" type="checkbox" id="MtnCheck"  onChange={HandleChange}/> </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card mb-2">
                                                    <div class="d-flex align-items-center justify-content-between">
                                                        <div>
                                                            <img src="https://resido.thesky9.com/storage/properties/p-11.jpg" alt="" style={{width:200 + "px"}}/>
                                                        </div>
                                                        <div class="d-flex align-items-center">
                                                            <div class="form-check form-switch"> 
                                                            <input class="form-check-input" type="checkbox" id="MoovCheck"  onChange={HandleChange}/> </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-8">
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        <Footer />
        </>
    )
}

export default PaimenetScreenUser;