
import { NavLink } from "react-router-dom"
import logo from '../../public/img/logo.png'
import { useSelector } from "react-redux"
const Footer = () => {
    const token = useSelector((state)=> state.user.token)
    return (
        <>
            <div className="widget_shortcode">
                <div className="raw-html-embed">
                <section className="theme-bg call-to-act-wrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="call-to-act">
                                    <div className="call-to-act-head">
                                        <h3>Voulez-vous devenir agent immobilier  sur RESIDENCE IT ?</h3>
                                        <span>Nous vous aiderons à développer votre business et ameliorer la gestion de vos biens.</span>
                                    </div>
                                    {!token? <NavLink className="btn btn-call-to-act" to="/auth/agent-immobilier/register">Devenir agent immobilier</NavLink> :""}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                </div>
            </div>
            <footer className="dark-footer skin-dark-footer">
          <div className="py-5">
    <div className="container">
        <div className="row">
            <div className="col-sm-12 col-md-4">
                <div className="footer-widget">
                    <img src={logo} className="img-footer" style={{ width: "100%", height: "150px" }} alt="" />
                    <div className="footer-add">
                        <p><i className="bi bi-map"></i> Treichville Nanan Yamousso (Avenue 13 Rue 23).</p>
                        <p><i className="bi bi-phone"></i> (+225) <a href="https://wa.me/2250787089013">07 87 08 90 13</a></p>
                        <p><i className="bi bi-envelope"></i> resistanceit@residenceit.com</p>
                    </div>
                </div>
            </div>
            <div className="col-sm-12 col-md-8">
                <div className="row">
                    <div className="col-sm-6 col-md-4">
                        <div className="footer-widget">
                            <h4 className="widget-title">INFORMATIONS</h4>
                            <ul>
                                <li>
                                    <NavLink to="/about-us"><span>A propos</span></NavLink>
                                </li>
                                <li>
                                    <NavLink to="/contact-us"><span>Contactez-nous</span></NavLink>
                                </li>
                                <li>
                                    <NavLink to="/privacy-policy" title="Terms & Conditions">
                                        <span>Termes & Conditions</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-4">
                        <div className="footer-widget">
                            <h4 className="widget-title">NOS SERVICES</h4>
                            <ul>
                                <li className="current ">
                                    <NavLink to='/ours-residences'><span>Location de residence</span></NavLink>
                                </li>
                                <li>
                                    <NavLink to="/nos-hotels"><span>Location d'hotels</span></NavLink>
                                </li>
                                <li>
                                    <NavLink to="/residence/logement-etudiants"><span>Residence Etudiant</span></NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4">
                        <div className="widget_shortcode">
                            <div className="footer-widget">
                                <h4 className="widget-title">Télécharger nos applications</h4>
                                <div className="other-store-app">
                                    <a href="https://play.google.com/store/apps/details?id=com.moiseodnell.residenceitmobile"  rel="noreferrer" target="_blank" className="other-store-link">
                                        <i className="bi bi-google-play theme-cl mr-3"></i>
                                        Google Play
                                    </a>
                                </div>
                                <div className="other-store-app mt-3">
                                    <a href="#" className="other-store-link">
                                        <i className="bi bi-apple theme-cl mr-3"></i>
                                        App Store
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


            <div className="footer-bottom">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6">
                            <p className="mb-0">©2023 Residence IT. All rights reserved by IT SYNERGY</p>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <ul className="footer-bottom-social">
                                <li><a href="https://www.facebook.com/residenceitabidjan/" target="_blank" title="Facebook"><i
                                            className=" bi bi-facebook"></i></a></li>
                                <li><a href="https://www.twitter.com/" target="_blank" title="Twitter"><i
                                            className="bi bi-twitter"></i></a></li>
                                <li><a href="https://www.instagram.com/" target="_blank" title="Instagram"><i
                                            className="bi bi-instagram"></i></a></li>
                                <li><a href="https://www.linkedin.com/" target="_blank" title="Linkedin"><i
                                            className="bi bi-linkedin"></i></a></li>
                                <li><a href="https://wa.me/2250787089013" target="_blank" title="Pinterest"><i
                                            className="bi bi-whatsapp"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
            <a id="back2Top" className="top-scroll"  href="#" ><i class="bi bi-arrow-up"></i></a>
        </>
    )
}

export default Footer