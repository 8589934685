import '../src/assets/bootstrap/bootstrap.min.css';
import '../src/assets/css/style.css';
import 'leaflet/dist/leaflet.css';
// importation des screens
import Home from "./screens/AccueilEtudiantScreen"
import Contact from '../src/screens/Contact'
import Poperties from './screens/ResidenceScreen';
import Login from './screens/Login';
import Register from './screens/RegisterScreen';
import About from './screens/AboutScreen';
import ProfileUser from './screens/dashbord/Dashbord';
import DetailsPopertie from './screens/DetailsLogementScreen';
import PasswordResset from './screens/PasswordResset';
import Hotels from './screens/HotelsSreen';
import Pannes from './screens/dashbord/Pannes';
import Paiements from './screens/dashbord/Paiements';
import Error404 from './screens/errors/404';
import UpdatePassword from './screens/dashbord/UpdatePasswordScreen';
import Reservations from './screens/dashbord/ReservationScreen';
import ListePannes from './screens/dashbord/ListePanneScreen';
import DetailsPannes from './screens/dashbord/DetailsPanne';
import ListeVisites from './screens/dashbord/ListeVisiteScreen';
import ListeBiens from './screens/dashbord/gerant/ListeBiensScreen';
import PannesResidences from './screens/dashbord/gerant/PanneResidenceSreen';
import ListeReservationAgent from './screens/dashbord/gerant/ListeReservationAgentSreen';
import ResidencesEtudiants from './screens/ResidencesEtudiantScreen';
import DetailsVisite from './screens/dashbord/DetailsVisiteScreen';
import DetailsResidence from './screens/dashbord/gerant/DetailsResidences';
import ListeVisiteAgent from './screens/dashbord/gerant/ListeVisiteAgentSreen';
import DetailsVisiteAgent from './screens/dashbord/gerant/DetailsVisiteScreenAgent';
import ImoblierScreen from './screens/ImobilierScreen';
import DetailsPannesAgent from './screens/dashbord/gerant/DetailsPanne';
import AddLogementAgent from './screens/dashbord/gerant/AddLogementScreenAgent';
import AddBiensAgent from './screens/dashbord/gerant/AddBienScreen';
import AppartementScreen from './screens/biens/AppartementsBienScreen';
import EntrepotsScreen from './screens/biens/EntrepotBienScreen';
import MagasinsScreen from './screens/biens/MagasinBienScreen';
import TerrainsScreen from './screens/biens/TerrainBienScreen';
import DetailsBienImmobilier from './screens/biens/DetailsBienImmobilier';
import DashboardAdmin from './screens/Admin/DashbordScreen';
import SendMessage from './screens/dashbord/messageScreen';
import ListeRolesAdmin from './screens/Admin/pages/roles/listeRoles';
import CreateRolesAdmin from './screens/Admin/pages/roles/CreateRole';
import AddLogementAdmin from './screens/Admin/pages/logements/AddLogementScreen';
import DetailsLogementScreenAdmin from './screens/Admin/pages/logements/DetailsLogementScreen';
import ListeUserAdmin from './screens/Admin/pages/auth/users/ListeUserScreen';
import ListeReservationAdmin from './screens/Admin/pages/logements/ListeReservationAdminSreen';
import CreateUserAdmin from './screens/Admin/pages/auth/users/CreateUser';
import ListeVisiteAdmin from './screens/Admin/pages/logements/ListeVisiteAdminSreen';
import PannesResidencesAdmin from './screens/Admin/pages/logements/PanneResidenceAdminSreen';
import ListeParticulierAdmin from './screens/Admin/pages/auth/users/ListeParticuliersScreen';
import ListeProprietaireAdmin from './screens/Admin/pages/auth/users/ListeProprietaireScreen';
import CreatePreference from './screens/dashbord/CreatePreferenceScreen';
import ListeLogementAdmin from './screens/Admin/pages/logements/ListeLogementAdmin';
import ListeAdhesionsAdmin from './screens/Admin/pages/logements/ListeAdhesionsAdmin';
import CreateAdhesionAdmin from './screens/Admin/pages/adhesions/CreateAdhesion';
import DetailsPannesAdmin from './screens/Admin/pages/DetailsPanne';
import DetailsResidenceAdin from './screens/Admin/pages/DetailsResidences';
import DetailsVisiteAdmin from './screens/Admin/pages/DetailsVisiteScreenAgent';
import DetailsReserationAdmin from './screens/Admin/pages/DetailsReservations';
import PrivateRoute from './utils/PrivateRoute';
import PaimenetScreenUser from './screens/dashbord/PaimentScreen';
import DetailsAdhesionAdmin from './screens/Admin/pages/adhesions/DetailsAdhesion';
import DetailsBiensAgent from './screens/dashbord/gerant/DetailsBienScreen';
import ListeBiensImmobilier from './screens/dashbord/gerant/ListeBienImmobilierScreen';
import DetailsReserationAgent from './screens/dashbord/gerant/DetailsReservations';
import AcceuilScreen from './screens/HomeScreen';
import PolitiqueConfidentialite from './screens/PrivacyPolicy';
import RegisterAgent from './screens/RegisterScreenAgent';
import ResidenceParCommune from './screens/ResidenceScreenCommune';
import DetailsReserationBienAgent from './screens/dashbord/gerant/DetailsReservationsBien';
import ListeBiensImmobilierUser from './screens/dashbord/ListeReservationsBiens';
import ListeBiensReservationImmobilier from './screens/dashbord/gerant/ListeReservationsBiens';
import AddBiensAdmin from './screens/Admin/pages/biens/AddBienScreen';
import DetailsBiensAdmin from './screens/Admin/pages/biens/DetailsBienScreen';
import ListeBiensImmobilierAdmin from './screens/Admin/pages/biens/ListeBienImmobilierScreen';
import ListeBiensReservationImmobilierAdmin from './screens/Admin/pages/biens/ListeReservationsBiens';
import ListeVisiteProprietaireAdmin from './screens/Admin/pages/reservations/ListeVisiteAdminSreen';
import ListeReservationLogementProprietaireAdmin from './screens/Admin/pages/reservations/ListeReservationAdminSreen';
import DetailsReserationProprietaireAdmin from './screens/Admin/pages/reservations/DetailsReservations';
import DetailsVisiteProprietaireAdmin from './screens/Admin/pages/reservations/DetailsVisiteScreenAdmin';
import PasswordRessetConfirm from './screens/PasswordRessetConfirm';
import { useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
Routes,
  Route,
} from "react-router-dom";


function App() {
  const token = useSelector((state) => state.user.token);
  return (
    <Router>
      <Routes>
        <Route path="/residence-etudiants" element={<Home />} />
        <Route path="/biens-immobiliers" element={<ImoblierScreen />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/residence-etudiants" element={<Home />} />
        <Route path="/privacy-policy" element={<PolitiqueConfidentialite />} />
        {/* <Route path="/contact-us" element={<Contact />} /> */}
        <Route path="/ours-residences" element={<Poperties />} />
        <Route path="/ours-residences/commune/:Searchcommune" element={<ResidenceParCommune />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/auth/agent-immobilier/register" element={<RegisterAgent />} />
        <Route
          path="/biens-immobiliers/appartements"
          element={<AppartementScreen />}
        />
        <Route
          path="/biens-immobiliers/magasins"
          element={<MagasinsScreen />}
        />
        <Route
          path="/biens-immobiliers/terrains"
          element={<TerrainsScreen />}
        />
        <Route
          path="/biens-immobiliers/entrepots"
          element={<EntrepotsScreen />}
        />
        <Route
          path="/biens-immobiliers/:id/details"
          element={<DetailsBienImmobilier />}
        />
        <Route path="/auth/login" element={<Login />} />
        <Route path="/auth/register" element={<Register />} />
        <Route path="/auth/password-resset-confirm" element={<PasswordRessetConfirm />} />
        <Route path="/" element={<AcceuilScreen />} />
        {/* <Route
          path="/user/profile"AcceuilScreenPoperties
          element={<PrivateRoute component={ProfileUser} />}
           <PrivateRoute token={token}<ProfileUser /></PrivateRoute>
        /> */}
        <Route
          path="/user/profile"
          element={
            <PrivateRoute token={token}>
              <ProfileUser />
            </PrivateRoute>
          }
        />
        <Route
          path="/details-residence/:IdResidence"
          element={<DetailsPopertie />}
        />
        <Route
          path="/user/agent/details-residence/:IdResidence"
          element={
            <PrivateRoute token={token}>
              <DetailsResidence />
            </PrivateRoute>
          }
        />
        <Route path="/request-password-resset" element={<PasswordResset />} />
        <Route path="/nos-hotels" element={<Hotels />} />
        <Route
          path="/user/signaler-pannes"
          element={
            <PrivateRoute token={token}>
              <Pannes />
            </PrivateRoute>
          }
        />
        <Route
          path="/user/effectuer-paiement"
          element={
            <PrivateRoute token={token}>
              <Paiements />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<Error404 />} />
        <Route
          path="/user/update-password/"
          element={
            <PrivateRoute token={token}>
              <UpdatePassword />
            </PrivateRoute>
          }
        />
        {/* <Route
          path="/reservations/paiement"
          element={<PrivateRoute token={token}><PaimenetScreenUser /></PrivateRoute>}
        /> */}
        <Route path="/reservations/paiement" element={<PaimenetScreenUser />} />
        <Route
          path="/user/reservations/"
          element={
            <PrivateRoute token={token}>
              <Reservations />
            </PrivateRoute>
          }
        />
        <Route
          path="/user/liste-pannes/"
          element={
            <PrivateRoute token={token}>
              <ListePannes />
            </PrivateRoute>
          }
        />
        <Route
          path="/user/details-panne/:IdPanne"
          element={
            <PrivateRoute token={token}>
              <DetailsPannes />
            </PrivateRoute>
          }
        />
        <Route
          path="/user/visites/"
          element={
            <PrivateRoute token={token}>
              <ListeVisites />
            </PrivateRoute>
          }
        />
        <Route
          path="/user/agent/logement/add/"
          element={
            <PrivateRoute token={token}>
              <AddLogementAgent />
            </PrivateRoute>
          }
        />
        <Route
          path="/user/agent/biens/add/"
          element={
            <PrivateRoute token={token}>
              <AddBiensAgent />
            </PrivateRoute>
          }
        />
        <Route
          path="/user/agent/logements/liste/"
          element={
            <PrivateRoute token={token}>
              <ListeBiens />
            </PrivateRoute>
          }
        />
        <Route
          path="/user/agent/biens-immobiliers/liste/"
          element={
            <PrivateRoute token={token}>
              <ListeBiensImmobilier />
            </PrivateRoute>
          }
        />
        <Route
          path="/user/agent/biens-immobiliers/reservations/"
          element={
            <PrivateRoute token={token}>
              <ListeBiensReservationImmobilier />
            </PrivateRoute>
          }
        />
        <Route
          path="/user/agent/visite/liste/"
          element={
            <PrivateRoute token={token}>
              <ListeVisiteAgent />
            </PrivateRoute>
          }
        />
        <Route
          path="/user/agent/pannes/details-panne/:IdPanne"
          element={
            <PrivateRoute token={token}>
              <DetailsPannesAgent />
            </PrivateRoute>
          }
        />
        <Route
          path="/user/agent/reservations/:IdReservation/details-reservations/"
          element={
            <PrivateRoute token={token}>
              <DetailsReserationAgent />
            </PrivateRoute>
          }
        />
        <Route
          path="/user/agent/reservations/:IdBien/details-reservation-bien/"
          element={
            <PrivateRoute token={token}>
              <DetailsReserationBienAgent />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/reservations/:IdBien/details-reservation-bien/"
          element={
            <PrivateRoute token={token}>
              <DetailsReserationBienAgent />
            </PrivateRoute>
          }
        />
        <Route
          path="/user/agent/bien-immobiliers/:IdBien/details"
          element={
            <PrivateRoute token={token}>
              <DetailsBiensAgent />
            </PrivateRoute>
          }
        />

         <Route
          path="/admin/dashbord/bien-immobiliers/:IdBien/details"
          element={
            <PrivateRoute token={token}>
              <DetailsBienImmobilier />
            </PrivateRoute>
          }
        />
        <Route
          path="/user/agent/pannes-signales/liste/"
          element={
            <PrivateRoute token={token}>
              <PannesResidences />
            </PrivateRoute>
          }
        />
        <Route
          path="/user/preferences/add"
          element={
            <PrivateRoute token={token}>
              <CreatePreference />
            </PrivateRoute>
          }
        />
        <Route
          path="/user/agent/liste-reservations/"
          element={
            <PrivateRoute token={token}>
              <ListeReservationAgent />
            </PrivateRoute>
          }
        />
        <Route
          path="/user/liste-reservations/bien-immobilier"
          element={
            <PrivateRoute token={token}>
              <ListeBiensImmobilierUser />
            </PrivateRoute>
          }
        />
        <Route
          path="/user/details-visite/:IdVisite"
          element={
            <PrivateRoute token={token}>
              <DetailsVisite />
            </PrivateRoute>
          }
        />
        <Route
          path="/user/agent/details-visite/:IdVisite"
          element={
            <PrivateRoute token={token}>
              <DetailsVisiteAgent />
            </PrivateRoute>
          }
        />
        <Route
          path="/residence/logement-etudiants/"
          element={<ResidencesEtudiants />}
        />
        <Route
          path="/admin/dashbord/"
          element={
            <PrivateRoute token={token}>
              <DashboardAdmin />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/dashbord/adhesions/add"
          element={
            <PrivateRoute token={token}>
              <CreateAdhesionAdmin />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/dashbord/roles/liste"
          element={
            <PrivateRoute token={token}>
              <ListeRolesAdmin />
            </PrivateRoute>
          }
        />
         <Route
          path="/admin/dashbord/bien-immobilier/liste"
          element={
            <PrivateRoute token={token}>
              <ListeBiensImmobilierAdmin />
            </PrivateRoute>
          }
        />
         <Route
          path="/admin/dashbord/reservations/bien-immobilier/"
          element={
            <PrivateRoute token={token}>
              <ListeBiensReservationImmobilierAdmin />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/dashbord/reservations/logements/proprietaires/"
          element={
            <PrivateRoute token={token}>
              <ListeReservationLogementProprietaireAdmin />
            </PrivateRoute>
          }
        />
         <Route
          path="/admin/dashbord/details-visite/:IdVisite/details"
          element={
            <PrivateRoute token={token}>
              <DetailsVisiteProprietaireAdmin />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/dashbord/details-reservations/:IdReservation/details"
          element={
            <PrivateRoute token={token}>
              <DetailsReserationProprietaireAdmin />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/dashbord/visites/logements/proprietaires/"
          element={
            <PrivateRoute token={token}>
              <ListeVisiteProprietaireAdmin />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/dashbord/roles/create"
          element={
            <PrivateRoute token={token}>
              <CreateRolesAdmin />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/dashbord/logements/create"
          element={
            <PrivateRoute token={token}>
              <AddLogementAdmin />
            </PrivateRoute>
          }
        />
         <Route
          path="/admin/dashbord/biens-immobiliers/create"
          element={
            <PrivateRoute token={token}>
              <AddBiensAdmin />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/dashbord/logements/liste"
          element={
            <PrivateRoute token={token}>
              <ListeLogementAdmin />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/dashbord/reservations/liste"
          element={
            <PrivateRoute token={token}>
              <ListeReservationAdmin />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/dashbord/visites/liste"
          element={
            <PrivateRoute token={token}>
              <ListeVisiteAdmin />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/dashbord/users/create"
          element={
            <PrivateRoute token={token}>
              <CreateUserAdmin />
            </PrivateRoute>
          }
        />
        {/* <Route
          path="/admin/dashbord/logements/details"
          element={<PrivateRoute token={token}><DetailsLogementScreenAdmin /></PrivateRoute>}
        /> */}
        <Route
          path="/admin/dashbord/pannes/liste"
          element={
            <PrivateRoute token={token}>
              <PannesResidencesAdmin />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/dashbord/etudiants/liste"
          element={
            <PrivateRoute token={token}>
              <ListeUserAdmin />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/dashbord/adhesions/liste"
          element={
            <PrivateRoute token={token}>
              <ListeAdhesionsAdmin />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/dashbord/users/particuliers/liste"
          element={
            <PrivateRoute token={token}>
              <ListeParticulierAdmin />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/dashbord/users/proprietaires/liste"
          element={
            <PrivateRoute token={token}>
              <ListeProprietaireAdmin />
            </PrivateRoute>
          }
        />
        <Route path="/user/chats/" element={<SendMessage />} />
        <Route
          path="/admin/dashbord/pannes/:IdPanne/details"
          element={
            <PrivateRoute token={token}>
              <DetailsPannesAdmin />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/dashbord/residences/:IdResidence/details"
          element={
            <PrivateRoute token={token}>
              <DetailsResidenceAdin />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/dashbord/visites/:IdVisite/details"
          element={
            <PrivateRoute token={token}>
              <DetailsVisiteAdmin />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/dashbord/adhesions/:IdLogement/details"
          element={
            <PrivateRoute token={token}>
              <DetailsAdhesionAdmin />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/dashbord/logements/:IdLogement/details"
          element={
            <PrivateRoute token={token}>
              <DetailsLogementScreenAdmin />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/dashbord/reservations/:IdResevation/details"
          element={
            <PrivateRoute token={token}>
              <DetailsReserationAdmin />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/dashbord/bien-immobiliers/:IdBien/details/"
          element={
            <PrivateRoute token={token}>
              <DetailsBiensAdmin />
            </PrivateRoute>
          }
        />
        {/* DetailsVisiteAdmin DetailsReserationAdmin*/}
      </Routes>
    </Router>
  );
}

export default App;
