import Header from "../../../../components/Header/Header";
import Footer from "../../../../components/Footer/Footer"
import SideBarDashbordAdmin from "../../Sidebar"
import DataTableReservationProprietaireAdmin from "./TableReservationAdmin";
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import axios from "axios";
import dns from "../../../../utils/dns";

const ListeReservationLogementProprietaireAdmin = () => {
    const userData = useSelector((state) => state.user.user);
    const token = useSelector((state) => state.user.token);
    const [data, setData] = useState([]);

    const fetchData = async () => {
        try {
            const response = await axios.get(dns +`/api/reservation/`,{
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            }

            );
            const sortedData = response.data.filter((data)=> data?.logement?.libelletypelogement !=="Etudiants").sort((a, b) => {
                // Convertir les dates en objets Date pour pouvoir les comparer
                const idA = a.id
                const idB = b.id
                
                // Comparer les dates et retourner le résultat du tri
                return idB - idA;
            });
            setData(sortedData);
            
        } catch (error) {
        console.error(error);
        }
    };

    useEffect(() => {
        fetchData();
    },[token]);
    
    return (
        <>
            <Header />
                <div className="page-title">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <h1 className="ipt-title">Liste des réservations de logements (Proprietaires)</h1>
                                <span className="ipn-subtitle"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="bg-light">
                    <div className="container-fluid">
                        <div className="row">
                            <SideBarDashbordAdmin />
                            <div class="col-lg-8 col-md-12">
                                <div id="app">
                                <DataTableReservationProprietaireAdmin reservations={data} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            <Footer />
        </>
    )
}

export default ListeReservationLogementProprietaireAdmin;