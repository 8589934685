import Header from "../../../components/Header/Header"
import Footer from "../../../components/Footer/Footer"
import SideBarDashbord from "../common"
import React, {useRef, useState,useEffect} from 'react'
import Swal from 'sweetalert2';
import axios from 'axios'
import dns from "../../../utils/dns";
import clearFormFields from "../../../utils/cleanform";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from 'react-redux';


const DetailsBiensAgent = () => {
    
    const userData = useSelector((state) => state.user.user);
    const token = useSelector((state) => state.user.token);
    const [fileList, setFileList] = useState([]);
    const [localisation, setLocalisation] = useState([]);
    const [typesbiens, setTypeBiens] = useState([]);
    const [biens, setBien] = useState([]);
    const Navigate = useNavigate()
    const bien = useParams()
    const DescriptionInput = useRef(null)
    const ImageInput = useRef(null)
    const ImageInput1 = useRef(null)
    const ImageInput2 = useRef(null)
    const ImageInput3 = useRef(null)
    const ImageInput4 = useRef(null)
    const QuartierInput = useRef(null)
    const CommuneInput = useRef(null)
    const LocalisationInput = useRef(null)
    const TypelogementInput = useRef(null)
    const TarifInput = useRef(null)
    const StatutLogementInput = useRef(null)

    const onFileDrop = (e) => {
        const newFile = e.target.files[0];
        
        if (newFile) {
            const updatedList = [...fileList, newFile];
            setFileList(updatedList);
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        const formdata = new FormData()
        const uploadedFile =  ImageInput.current.files[0] ?? null ;
        const uploadedFile1 = ImageInput1.current.files[0] ?? null;
        const uploadedFile2 = ImageInput2.current.files[0] ?? null;
        const uploadedFile3 = ImageInput3.current.files[0] ?? null;
        const uploadedFile4 = ImageInput4.current.files[0] ?? null;
        
        formdata.append("agent_immobilier", userData.id)
        formdata.append("surface", CommuneInput.current.value)
        formdata.append("type_bien", TypelogementInput.current.value)
        formdata.append("localisation", LocalisationInput.current.value)
        formdata.append("prix", TarifInput.current.value)
        formdata.append("est_disponible", StatutLogementInput.current.value)
        formdata.append("description", DescriptionInput.current.value)
        
        if (uploadedFile){
            formdata.append("image_bien1",  uploadedFile)
        }
        if (uploadedFile1){
            formdata.append("image_bien2", uploadedFile1)
        }
        if (uploadedFile2){
            formdata.append("image_bien3", uploadedFile2)
        }
        if (uploadedFile3){
            formdata.append("image_bien4", uploadedFile3)
        }
        if (uploadedFile4){
            formdata.append("image_bien5", uploadedFile4)
        }

        if (formdata && token) {
            try {
                const response = await axios.patch(dns + `/api/biens/${bien.IdBien}/`, formdata, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${token}`,
                    },
                })

                if (response.status === 200) {
                    Swal.fire({
                        title:'Enregistrerment de residence', 
                        text:`Le bien à été modifier avec succès`, 
                        icon: 'success', 
                        button:'OK',
                        timer: 3000,
                        timerProgressBar:true,
                        didOpen:()=>{
                            Swal.showLoading();
                        }
                    })
                    Navigate('/user/agent/biens-immobiliers/liste')
                } else {
                    Swal.fire({
                        title:'Enregistrerment de residence', 
                        text:`Impossible d'enregistrer votre logement pour le moment`, 
                        icon: 'error', 
                        button:'OK',
                        timer: 3000
                    })
                }
            } catch (error) {
                Swal.fire({
                    title:'Enregistrerment de residence', 
                    text:`Le serveur à rencontrer un problème lors du traitement de votre requête `, 
                    icon: 'error', 
                    button:'OK',
                    timer: 4000
                })
            }
        }
    }

   useEffect(() => {
    const GetBienDetails = async () => {
        try {
            const response = await axios.get(dns + `/api/biens/${bien.IdBien}/`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            if (response.status === 200) {
                console.log(response.data)
                const data = response?.data;
                CommuneInput.current.value = data?.surface
                LocalisationInput.current.value = data?.localisation
                TarifInput.current.value = data?.prix
                DescriptionInput.current.value = data?.description
                TypelogementInput.current.value = data?.type_bien?.id
                StatutLogementInput.current.value = data?.est_disponible
                setBien(response.data)
            }
        } catch (error) {
            console.error(error);
        }
    }

    const GetTypeLogemnts = async () => {
        try {
            const response = await axios.get(dns + `/api/type-biens/`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            if (response.status === 200) {
                setTypeBiens(response.data)
            }
        } catch (error) {
            console.error(error);
        }
    }
       
    GetTypeLogemnts()
    GetBienDetails()
    
}, []); 

    return (

        <>
            <Header />
            <div className="page-title">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <h1 className="ipt-title">Modifier un bein</h1>
                            <span className="ipn-subtitle"></span>
                        </div>
                    </div>
                </div>
            </div>
            <section className="bg-light">
                <div className="container-fluid">
                    <div className="row">
                        <SideBarDashbord />
                        <div class="col-lg-7 col-md-12">
                            <div id="app" >
                                <div class="dashboard-wraper1">
                                    <form class="js-base-form dirty-check" enctype="multipart/form-data" onSubmit={handleSubmit}>
                                        <div class="row">
                                            <div class="col-md">
                                                <div class="dashboard-wraper">
                                                    <div class="main-form">
                                                    <div class="form-body">
                                                        <div class="form-group mb-3">
                                                            <label for="images" class="control-label">Type de bien</label>
                                                                <div  class="dropzone needsclick dz-clickable">
                                                                    <div class="dz-message needsclick">
                                                                        <select class="form-control ui-select is-valid" ref={TypelogementInput} id="type_id" aria-invalid="false">
                                                                            {typesbiens.map((bien) => (
                                                                                <option value={bien?.id}>{ bien?.libelle}</option>
                                                                            ))}
                                                                        </select>    
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form-group mb-3">
                                                            <label for="images" class="control-label">Surface</label>
                                                                <div  class="dropzone needsclick dz-clickable">
                                                                    <div class="dz-message needsclick">
                                                                        <input type="text" ref={CommuneInput}  class="form-control" placeholder='Entrer la surface du bien en m²'/>    
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form-group mb-3">
                                                                <label for="description" class="control-label">Description</label>
                                                                <textarea class="form-control" rows="4" placeholder="Description du bien" data-counter="350" ref={DescriptionInput} cols="50" id="description"></textarea>
                                                            </div>
                                                            <div class="form-group mb-3">
                                                            <label for="images" class="control-label">Image 1</label>
                                                                <div id="multiple-upload" class="dropzone needsclick dz-clickable">
                                                                    <div class="dz-message needsclick">
                                                                        <input type="file" ref={ImageInput} id="" class="form-control" accept="image/*" onChange={onFileDrop} />    
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form-group mb-3">
                                                            <label for="images" class="control-label">Image 2</label>
                                                                <div id="multiple-upload" class="dropzone needsclick dz-clickable">
                                                                    <div class="dz-message needsclick">
                                                                        <input type="file" ref={ImageInput1} id="" class="form-control" accept="image/*" onChange={onFileDrop} />    
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form-group mb-3">
                                                            <label for="images" class="control-label">Image 3</label>
                                                                <div id="multiple-upload" class="dropzone needsclick dz-clickable">
                                                                    <div class="dz-message needsclick">
                                                                        <input type="file" ref={ImageInput2} id="" class="form-control" accept="image/*" onChange={onFileDrop} />    
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form-group mb-3">
                                                            <label for="images" class="control-label">Image 4</label>
                                                                <div  class="dropzone needsclick dz-clickable">
                                                                    <div class="dz-message needsclick">
                                                                        <input type="file" ref={ImageInput3} id="" class="form-control"  accept="image/*" onChange={onFileDrop}/>    
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form-group mb-3">
                                                            <label for="images" class="control-label">Image 5</label>
                                                                <div  class="dropzone needsclick dz-clickable">
                                                                    <div class="dz-message needsclick">
                                                                        <input type="file" ref={ImageInput4} id="" class="form-control" accept="image/*" onChange={onFileDrop}/>    
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {localisation ?
                                                                <div class="form-group mb-3">
                                                                <label for="images" class="control-label">Localisation</label>
                                                                    <div  class="dropzone needsclick dz-clickable">
                                                                        <div class="dz-message needsclick">
                                                                            <input type="text" ref={LocalisationInput}  class="form-control" placeholder='Entrer la localisation'  />    
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                : ''}
                                                            
                                                            <div class="form-group mb-3">
                                                            <label for="images" class="control-label">Prix</label>
                                                                <div  class="dropzone needsclick dz-clickable">
                                                                    <div class="dz-message needsclick">
                                                                        <input type="text" ref={TarifInput}  class="form-control" placeholder='Entrer le prix du bien'/>    
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form-group mb-3">
                                                            <label for="images" class="control-label">Statut du bein</label>
                                                                <div  class="dropzone needsclick dz-clickable">
                                                                    <div class="dz-message needsclick">
                                                                        <select class="form-control ui-select is-valid" ref={StatutLogementInput} id="type_id" aria-invalid="false">
                                                                            <option  value={true}>Disponible</option>
                                                                            <option  value={false}>Indisponible </option>
                                                                        </select>    
                                                                    </div>
                                                                </div>
                                                        </div>
                                                        <button type="submit" className="btn btn-primary fw6 mt-4">Enregistrer</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>   
            <Footer />
        </>
    )
}

export default DetailsBiensAgent;