import SideBarDashbord from "./common"
import Header from "../../components/Header/Header"
import Footer from "../../components/Footer/Footer"
import axios from 'axios'
import React, { useEffect ,useState,useRef} from 'react'
import dns from "../../utils/dns"
import Swal from "sweetalert2"
import { useSelector,useDispatch } from 'react-redux';
import { loginSuccess, setUserToken, setConnected} from "../../store/reducers/userSlice";
const ProfileUser = () => {
    //const  Navigate = useNavigate()
    const dispatch = useDispatch()
    const formRef = useRef(null);
    const [nblogements, setNombreLogement] = useState('');
    const [nbreservations, setNombreReservation] = useState('');
    const [nbpannes, setNombrepannes] = useState('');
    const LastNameInput  = useRef(null)
    const FirstNameInput = useRef(null)
    const phoneNumberInput = useRef(null)
    const EmailInput = useRef(null)
    const SchoolInput = useRef(null)
    const sexInput = useRef(null)
    const PrenomsParentsInput = useRef(null)
    const NompropietaireInput = useRef(null)
    const userData = useSelector((state) => state.user.user);
    const token = useSelector((state) => state.user.token);
    const ContactParentsInput = useRef(null)
    const ContactPaimentInput = useRef(null)
    const NiveauInput = useRef(null)
    const [data, setData] = useState([]);

    const GetuserLogement = async () => {
        try {
            const response = await axios.get(dns +'/api/logement/user/',{
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            }
            );
            setNombreLogement(response.data.length);
        } catch (error) {
        console.error(error);
        }
    }

    const GetuserPannes= async () => {
        try {
            const response = await axios.get(dns +`/api/biens/proprietaire/${userData.id}/`,{
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            }
            );
            setNombrepannes(response.data.length);
        } catch (error) {
        console.error(error);
        }
    }

    const GetuserReservation = async () => {
        try {
            const response = await axios.get(dns +`/api/reservation/proprietaire/${userData.id}/`,{
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            }
            );
            setNombreReservation(response.data.length);
        } catch (error) {
        console.error(error);
        }
    }

    const FecthUser = async () => {
        try {
            if (token) {
                if (userData.libelle_role === "Etudiant") {
                    LastNameInput.current.value = userData.last_name
                    FirstNameInput.current.value = userData.first_name
                    phoneNumberInput.current.value = userData.numero_telephone
                    EmailInput.current.value = userData.email
                    SchoolInput.current.value = userData.etablissement
                    ContactParentsInput.current.value = userData.contact_parent
                    PrenomsParentsInput.current.value = userData.noms_prenoms_parent
                }else {
                    LastNameInput.current.value = userData.last_name
                    FirstNameInput.current.value = userData.first_name
                    phoneNumberInput.current.value = userData.numero_telephone
                    EmailInput.current.value = userData.email
                    ContactPaimentInput.current.value = userData.contact_payement

                }
                if (userData.libelle_role==="Proprietaire"){
                    NompropietaireInput.current.value=userData.noms_proprietaire
                }
            }
        } catch (error) {
            console.log(error)
        }
    }
    
    const UpdateProfile = async (event) => {
        event.preventDefault()
        const formdata = new FormData()
        if (userData.libelle_role === "Etudiant") {
            formdata.append('username', phoneNumberInput.current.value)
            formdata.append('first_name', FirstNameInput.current.value)
            formdata.append('last_name', LastNameInput.current.value)
            formdata.append('email', EmailInput.current.value)
            formdata.append('numero_telephone',phoneNumberInput.current.value)
            formdata.append('noms_prenoms_parent',PrenomsParentsInput.current.value)
            formdata.append('contact_parent',ContactParentsInput.current.value)
            formdata.append('niveau_etude', NiveauInput.current.value)
            formdata.append('genre', sexInput.current.value)///
            formdata.append('etablissement', SchoolInput.current.value)
        }else if (userData.libelle_role==="Proprietaire") {
            formdata.append('username', phoneNumberInput.current.value)
            formdata.append('first_name', FirstNameInput.current.value)
            formdata.append('last_name', LastNameInput.current.value)
            formdata.append('email', EmailInput.current.value)
            formdata.append('numero_telephone',phoneNumberInput.current.value)
            formdata.append('contact_payement',ContactPaimentInput.current.value)
            formdata.append('genre', sexInput.current.value)///
            formdata.append('noms_proprietaire', NompropietaireInput.current.value)///
        } else {
            formdata.append('username', phoneNumberInput.current.value)
            formdata.append('first_name', FirstNameInput.current.value)
            formdata.append('last_name', LastNameInput.current.value)
            formdata.append('email', EmailInput.current.value)
            formdata.append('numero_telephone',phoneNumberInput.current.value)
            formdata.append('genre', sexInput.current.value)///
        }
        //formdata.append('genre', sexInput.current.value)

        if (formdata && token) {
            try {
                const response = await axios.patch(dns + `/api/auth/api/profile/`, formdata, {
                        headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                if (response.status === 200) {
                    console.log(response.data)
                    dispatch(loginSuccess(response.data));
                    //dispatch(setUserToken(response.data.token));
                    Swal.fire({
                        title:'Mise à jour du profil', 
                        text:`Vos informations ont été modifiés avec succès`, 
                        icon: 'success', 
                        button:'OK',
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                        },
                    })
                } else {
                    Swal.fire({
                        title:'Mise à jour du profil', 
                        text:`Un problème est survenue lors de la modification de vos informations`, 
                        icon: 'error', 
                        button:'OK',
                        timer: 4000
                    })
                }
                
            } catch (error) {
                 Swal.fire({
                    title:'Mise à jour du profil', 
                    text:`Le serveur à rencontrer un problème lors du traitement de votre requête `, 
                    icon: 'error', 
                    button:'OK',
                    timer: 4000
                })
            }
        }
    }
    useEffect(()=>{
        FecthUser();
        GetuserLogement();
        GetuserPannes();
        GetuserReservation();
    },[])
    return (
        <>
            <Header />
            <div class="page-title">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <h1 class="ipt-title">Bienvenue sur votre profil</h1>
                            <span class="ipn-subtitle"></span>
                        </div>
                    </div>
                </div>
            </div>
            <section className="bg-light">
                <div className="container-fluid">
                    <div className="row">
                        <SideBarDashbord />
                        <div class="col-lg-8 col-md-12">
                            <div id="app">
                                {userData.libelle_role === "Proprietaire" ?
                                    <div>
                                    <div class="row">
                                        <div class="col-lg-4 col-md-6 col-sm-12">
                                            <div class="dashboard-stat widget-1">
                                                <div class="dashboard-stat-content">
                                                <h4>{ nblogements}</h4>
                                                <span>Logements</span>
                                                </div>
                                                <div class="dashboard-stat-icon"><i class="bi bi-house-check-fill"></i></div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-12">
                                            <div class="dashboard-stat widget-2">
                                                <div class="dashboard-stat-content">
                                                    <h4>{ nbreservations}</h4>
                                                <span>Réservations</span>
                                                </div>
                                                <div class="dashboard-stat-icon"><i class="bi bi-list-stars"></i></div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-12">
                                            <div class="dashboard-stat widget-3">
                                                <div class="dashboard-stat-content">
                                                <h4>{ nbpannes}</h4>
                                                    <span>Biens immobiliers</span>
                                                </div>
                                                <div class="dashboard-stat-icon">
                                                    <i class="bi bi-house"></i></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : 
                                ''}
                                <div class="dashboard-wraper settings crop-avatar">
                                    <div class="form-submit">
                                        <div class="row">
                                            <div class="col-12">
                                                <h4 class="with-actions">Informations de votre compte</h4>
                                            </div>
                                        </div>
                                        <div class="row">
                                        <div class="col-lg-9 order-lg-0">
                                            <form  id="setting-form" ref={formRef} onSubmit={UpdateProfile}>
                                                    <div class="form-group">
                                                        <label for="first_name">Prénom</label>
                                                        <input type="text" ref={LastNameInput} id="first_name" required="required"  class="form-control" aria-required="true" />
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="last_name">Nom</label>
                                                        <input type="text" ref={FirstNameInput} id="last_name" required="required"  class="form-control" aria-required="true" />
                                                    </div>
                                                    
                                                <div class="form-group"><label for="phone">Numéro</label>
                                                    <input type="text" ref={phoneNumberInput} id="phone" required="required"  class="form-control" aria-required="true" />
                                                </div>
                                                <div class="form-group">
                                                    <label for="email">Adresse email</label>
                                                    <input type="email" ref={EmailInput} id="email"  placeholder="" required="required"  class="form-control" aria-required="true" />
                                                </div>
                                                <div class="form-group">
                                                    <label for="gender">Sexe</label>
                                                    <select ref={sexInput} id="gender" class="form-control is-valid" aria-invalid="false" disabled>
                                                        <option value={userData.genre}>{userData.genre==="M" ? 'Homme' : "Femme"}</option>
                                                    </select>
                                                    </div>
                                                    {/* user.niveau_etude */}
                                                    {userData.libelle_role === "Etudiant" ? (
                                                        <div class="form-group">
                                                            <label for="niveau_etude">Niveau d'étude</label>
                                                            <select ref={NiveauInput} id="niveau_etude" class="form-control is-valid" aria-invalid="false">
                                                                <option value={userData.niveau_etude}>{userData.niveau_etude}</option>
                                                                {userData.niveau_etude !== "Bts" && <option value="Bts">Bts</option>}
                                                                {userData.niveau_etude !== "Licence" && <option value="Licence">Licence</option>}
                                                                {userData.niveau_etude !== "Master" && <option value="Master">Master</option>}
                                                                {userData.niveau_etude !== "Doctorat" && <option value="Doctorat">Doctorat</option>}
                                                            </select>
                                                        </div>
                                                    ) : ''}
                                                    {userData.libelle_role === "Etudiant" ? (
                                                        <div class="form-group">
                                                            <label for="contact_parent">Contacts des parents</label>
                                                            <input type="text" ref={ContactParentsInput} id="contact_parent"  placeholder="ex: 0747358020" required="required"  class="form-control" aria-required="true" />
                                                        </div>) :userData.libelle_role === "Proprietaire" ?
                                                        (<div class="form-group">
                                                            <label for="contact_parent">Contacts de paiment</label>
                                                            <input type="text" ref={ContactPaimentInput} id="contact_parent"  placeholder="ex: 0747358020" required="required"  class="form-control" aria-required="true" />
                                                        </div>) : ''
                                                    }
                                               
                                                {userData.libelle_role === "Etudiant" ? (
                                                    <div class="form-group">
                                                    <label for="contact_parent">Nom du parent</label>
                                                    <input type="text" ref={PrenomsParentsInput} id="contact_parent"  placeholder="ex: Konan kouadio" required="required"  class="form-control" aria-required="true" />
                                                </div>
                                                ):''}
                                                {userData.libelle_role === "Etudiant" ? (
                                                    <div class="form-group">
                                                    <label for="etablissement">Etablissement</label>
                                                    <input type="text" ref={SchoolInput} id="etablissement"  placeholder="ex: Université de ....." required="required"  class="form-control" aria-required="true" />
                                                </div>
                                                ):''}
                                                {userData.libelle_role === "Proprietaire" ?(
                                                    <>
                                                        <div class="form-group">
                                                            <label for="contact_parent">Nom de votre structure</label>
                                                            <input type="text" ref={NompropietaireInput} id="contact_parent"  placeholder="ex: Prestige service" required="required"  class="form-control" aria-required="true" />
                                                        </div>
                                                        <p className="mt-2">NB * : Veuilez entrer le nom votre structure pour mieux etre référencer sur Résidence IT</p>
                                                    </>
                                                ) : ""}

                                                <button type="submit" class="btn btn-primary fw6">Modifier mes infos</button>
                                            </form>
                                        </div>
                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default ProfileUser