import React, { useState, useEffect } from 'react';
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';
import axios from 'axios';
import { useSelector } from 'react-redux';
import dns from '../../../../../utils/dns';
import { InputText } from 'primereact/inputtext'; // Importez InputText
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
const DataTableUserAdmin = ({ users }) => {
    const [loading, setLoading] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [user, setUser] = useState({});
    const token = useSelector((state) => state.user.token);
    const [visible, setVisible] = useState(false);
    const filteredusers = users.filter((user) =>
    user.username.toLowerCase().includes(searchText.toLowerCase())||
    user.first_name.toLowerCase().includes(searchText.toLowerCase())||
    user.last_name.toLowerCase().includes(searchText.toLowerCase())||
    user.etablissement.toLowerCase().includes(searchText.toLowerCase())
        
  );
  const footerContent = (
        <div>
            {/* <Button label="No" icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" /> */}
            <Button label="Fermer" icon="bi bi-check" onClick={() => setVisible(false)} autoFocus />
        </div>
    );
    const DeleteLogement = (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: true
        });

        swalWithBootstrapButtons.fire({
            title: 'Etes vous sur de supprimer ce compte ?',
            text: "Vous ne pourrez pas revenir en arrière !",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirmer',
            cancelButtonText: 'Annuler!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(dns + `/api/auth/api/user/${id}/`, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    }
                }).then((response) => {
                    if (response.status === 204) {
                        Swal.fire({
                            title: 'Suppression de compte',
                            text: `Le compte a été supprimé avec succès`,
                            icon: 'success',
                            button: 'OK',
                            timer: 4000
                        });
                        window.location.reload();
                    } else {
                        Swal.fire({
                            title: 'Suppression de compte',
                            text: `Nous avons rencontré une erreur lors du traitement de votre requête`,
                            icon: 'error',
                            button: 'OK',
                            timer: 4000
                        });
                    }
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                swalWithBootstrapButtons.fire(
                    'Annulé',
                    "L'action a échoué :)",
                    'warning'
                );
            }
        });
    }


    const DetailsUser = ()=>{
        setVisible(true)
    }

    useEffect(() => {
        // Simulate an asynchronous data loading process
        setTimeout(() => {
            setLoading(false);
        }, 3000); // Simulating a 2-second loading time
    }, []);

    return (
        <>
            <div className='card-body'>
                <div className="table-responsive">
                    <Card>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col">
                            <label htmlFor="searchText">Rechercher :</label>
                            <InputText
                                id="searchText"
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                        </div>
                    </div>
                        <DataTable value={filteredusers} loading={loading} sortOrder={1} sortField='first_name' paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} emptyMessage="Aucune donnée disponible pour le moment">
                        {/* <Column field="id" header="#ID" /> */}
                            <Column field="username" header="Numero" />
                            <Column field="first_name" header="Nom" />
                            <Column field="last_name" header="Prénoms" />
                            <Column field="email" header="Email"  />
                            {/* <Column field="numero_telephone" header="Numero" /> */}
                            <Column field="genre" header="Genre"  />
                            {/* <Column field="libelle_role" header="Role" /> */}
                            <Column field="niveau_etude" header="Niveau" />
                            <Column header="Actions" body={(rowData) => (
                                <div className="fr-grid-footer-flex-right">
                                    {/* <Link to="#" className="prt-view" onClick={() => DetailsUser()}>
                                        <i className="bi bi-eye"></i>
                                    </Link> */}
                                    <Dialog header="Details de l'utilisateur" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)} footer={footerContent}>
                                        <p className="m-0">
                                        {user && (
                                            <>
                                                <p><strong>Numéro:</strong> {user.username}</p>
                                                <p><strong>Nom:</strong> {user.first_name}</p>
                                                <p><strong>Prénoms:</strong> {user.last_name}</p>
                                                <p><strong>Role:</strong> {user.libelle_role}</p>
                                                <p><strong>Genre:</strong> {user.genre}</p>
                                                {user.niveau_etude ? (
                                                    <p><strong>Niveau:</strong> {user.niveau_etude}</p>
                                                ):""}
                                                 {user.etablissement ? (
                                                    <p><strong>Université:</strong> {user.etablissement}</p>
                                                ):""}
                                                {user.noms_prenoms_parent ? (
                                                    <p><strong>Nom du parent:</strong> {user.noms_prenoms_parent}</p>
                                                ):""}
                                                {user.contact_parent ? (
                                                    <p><strong>Contact du parent:</strong> {user.contact_parent}</p>
                                                ):""}
                                                {user.noms_proprietaire ? (
                                                    <p><strong>Structure:</strong> {user.noms_proprietaire}</p>
                                                ):""}
                                                {/* Ajoutez d'autres champs que vous souhaitez afficher */}
                                            </>
                                        )}

                                        </p>
                                    </Dialog>
                                    <Button type="button" icon="bi bi-eye" className="prt-view" style={{ marginLeft: '5px' }}  onClick={() =>{
                                        setUser(rowData);
                                        DetailsUser()
                                    }}/>
                                    <Link to="#" className="prt-danger" style={{ marginLeft: 2 + "px" }} onClick={() => { DeleteLogement(rowData.id) }}>
                                        <i className="bi bi-trash"></i>
                                    </Link>
                                </div>
                            )} />
                        </DataTable>
                        
                    </Card>
                </div>
           </div>
        </>
    )
}

export default DataTableUserAdmin;
