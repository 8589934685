import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import { NavLink,Link,useNavigate } from "react-router-dom"
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import dns from "../utils/dns";
import banner from '../public/img/banners/banner-6.png'
import ResidenceItem from "../components/ResidenceItemScreen"
import LoadingSpinnerCircular from "../components/spiner"
const AcceuilScreen = () => {
    const [data, setData] = useState([]);
    const [residences, setResidence] = useState([]);
    const Navigate = useNavigate()

    const generateSlug = (str) =>{
        // Convertir la chaîne en minuscules
        let slug = str.toLowerCase();
    
        // Remplacer les espaces par des tirets
        slug = slug.replace(/\s+/g, '-');
    
        // Supprimer les caractères spéciaux et accents
        slug = slug.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    
        // Gérer les caractères non alphanumériques
        slug = slug.replace(/[^\w-]/g, '');
        return slug;
    }

     useEffect(() => {
        fetchData();
     }, []);
    
    const fetchData = async () => {
    try {
      const response = await axios.get(dns +'/api/logement-list/');
      const dataList = response.data.filter((residence) => residence?.libelletypelogement === "Residences").reverse();
        // Trier le tableau par la propriété "date_creation" de la plus récente à la plus ancienne
        dataList.sort((a, b) => {
        // Convertir les dates en objets Date pour pouvoir les comparer
        const dateA = new Date(a.date_creation);
        const dateB = new Date(b.date_creation);
        
        // Comparer les dates et retourner le résultat du tri
        return dateB - dateA;
    });

      const firstThreeItems = dataList;
      const counts = {};
      for (const logement of dataList) {
          const commune = logement?.commune_logement;

          if (counts[commune]) {
              counts[commune].count += 1;
          } else {
              counts[commune] = { count: 1,  image: null };
          }

          // Ajoutez le chemin d'image uniquement si elle n'a pas déjà été ajoutée
          if (!counts[commune].image) {
              const imageUrl = logement[`image_logement`];
              if (imageUrl) {
                  counts[commune].image = imageUrl;
              }
          }
          }

      const listeDeValeurs = Object.keys(counts).map(commune => ({ 
          commune, 
          nb: counts[commune].count, 
          images: counts[commune].image
      }));

      //console.log(listeDeValeurs);
      setResidence(listeDeValeurs);
        // }
      setData(firstThreeItems);
    } catch (error) {
      console.error(error);
    }
    };
    return (
        <>
            <Header />
            <div id="app"> 
                <div id="ismain-homes">
                <div class="ck-content">
                    <div>
                        <div class="image-cover hero-banner"
                            style={{backgroundImage:`url(${banner})` ,backgroundRepeat: 'no-repeat', background:"#2540a2" }}>
                            <div class="container">
                                <div class="row justify-content-center">
                                    <div class="col-lg-9 col-md-11 col-sm-12">
                                        <div class="inner-banner-text text-center" style={{color:'#ffff' }}>
                                            <h2 style={{color:'#ffff' }}>Bienvenue sur Residence IT</h2>
                                            <p class="lead-i">Nous mettons a votre diposition des chambres meublées ,situés à quelques minutes de marche de votre position géographique.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        <div>
                            <section class="bg-light">
                                <div class="container">
                                    <div class="row justify-content-center">
                                        <div class="col-lg-12 text-center">
                                            <div class="sec-heading center">
                                                <h2>Decouvrez  de bons endroits avec Residence IT</h2>
                                                <p>Votre escapade parfaite commence ici : découvrez nos résidences et hôtels d'exception.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row list-layout">
                                            {data.length >0 ? data.slice(0, 3).map((item, index) => (
                                                <div class="col-lg-4 col-md-6 col-sm-12" key={index}>
                                                    <div class="property-listing property-2 "
                                                    >
                                                        <div class="listing-img-wrapper">
                                                            <div class="list-img-slide">
                                                                <div class="click">
                                                                    <div>
                                                                        <Link to={`/details-residence/${item?.id}`}>
                                                                            <img src={item?.image_logement} class="img-fluid mx-auto lazy" alt=""/>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        <div class="listing-detail-wrapper">
                                                            <div class="listing-short-detail-wrap">
                                                                <div class="listing-short-detail">
                                                                    <div class="list-price d-flex justify-content-between">
                                                                        <span>
                                                                        <span class="prt-types rent">{item?.statut_logement}</span>
                                                                        </span>
                                                                        <h6 class="listing-card-info-price">
                                                                            {item?.tarif_mensuel?.toString().replace(".00", "")} cfa 
                                                                        </h6>
                                                                    </div>
                                                                        <h4 class="listing-name">
                                                                        <Link to={`/details-residence/${item?.id}`}>{item?.quartier_logement}</Link>
                                                                        </h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="listing-detail-footer">
                                                            <div class="footer-first">
                                                                <div class="foot-location d-flex">
                                                                    <img src="https://resido.thesky9.com/themes/resido/img/pin.svg" width="18" alt=""/>
                                                                    {item?.commune_logement}
                                                                </div>
                                                            </div>
                                                            <div className="listing-detail-btn">
                                                                <Link to={`/details-residence/${item?.id}`} className="more-btn">Voir</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )) :  <LoadingSpinnerCircular />}
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-sm-12 text-center">
                                            <Link to="/ours-residences" className="btn btn-theme-light rounded">Voir plus</Link>
                                        </div>
                                    </div>
                                    <div class="row list-layout mt-4">
                                        <h2 className="text-center mb-4">Decouvrez nos résidences  meublés et spacieuses</h2>
                                        <p className="text-center mb-4">Luxe, confort, et service exceptionnel : offrez-vous une expérience de séjour incomparable avec Residence IT.</p>
                                        {data.length >0 ? data.slice(3,9).map((item, index) => (
                                            <ResidenceItem key={index} residence={item}/>
                                        )):<LoadingSpinnerCircular />
                                        }
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12 col-sm-12 text-center">
                                                <NavLink to="/ours-residences" className="btn btn-theme-light rounded">Voir nos résidences</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div>
                            <section>
                                <div class="container">
                                    <div class="row justify-content-center">
                                        <div class="col-lg-7 col-md-10 text-center">
                                            <div class="sec-heading center">
                                                <h2>Découvrez nos Résidences par Communes</h2>
                                                <p>
                                                    Nos résidences sont équipées d'une gamme de services et de commodités pour vous garantir pour un séjour sans souci.
                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                <div class="row list-layout">
                                    {residences.length>0 ? residences.map((residence) => (
                                        <div class="col-lg-4 col-md-4">
                                            <div class="location-property-wrap">
                                                <div class="location-property-thumb">
                                                    <Link to={`/ours-residences/commune/${generateSlug(residence?.commune)}`}>
                                                        <img src={residence?.images} style={{height:270 +"px"}}
                                                        class="w-100 lazy" alt={residence?.commune}/>
                                                    </Link>
                                                </div>
                                                    <div class="location-property-content">
                                                        <div class="lp-content-flex">
                                                        <h4 class="lp-content-title">{residence?.commune}</h4>
                                                        <span>
                                                            {residence?.nb}
                                                            {residence?.nb=== 1 ? "  logement" : " logements" }
                                                        </span>
                                                    </div>
                                                    <div class="lp-content-right">
                                                        <Link to="/ours-residences"
                                                            class="lp-property-view">
                                                            <i class="bi bi-arrow-right"></i>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )):<LoadingSpinnerCircular />}
                                </div>
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12 text-center">
                                        <NavLink to="/ours-residences" className="btn btn-theme-light rounded">Voir les résidences</NavLink>
                                    </div>
                                </div>
                                </div>
                            </section>
                        </div>
                        <div>
                    </div>
                </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default AcceuilScreen