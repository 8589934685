import SideBarDashbord from "./common"
import Header from "../../components/Header/Header"
import Footer from "../../components/Footer/Footer"
import axios from 'axios'
import React, { useEffect ,useState,useRef} from 'react'
import { useNavigate,useParams } from 'react-router-dom'
import dns from "../../utils/dns"
import Swal from 'sweetalert2'
import clearFormFields from "../../utils/cleanform"
import { useSelector } from 'react-redux';

const DetailsVisite = () => {
    const Navigate = useNavigate();
    const [DetailsVisite, SetDetailsVisite] = useState({})
    const [user_id, setUser_id] = useState('');
    const Visite = useParams();
    const formRef = useRef(null);
    const userData = useSelector((state) => state.user.user);
    const token = useSelector((state) => state.user.token);
    const DateVisiteInput = useRef(null)
    const LieuxRdvInput = useRef(null)
    const PrixvisiteInput = useRef(null)
    const ObservationInput = useRef(null)
    const Statut_visiteInput = useRef(null)
    

    const handleSubmit = async (e) => { 
        e.preventDefault();
        const formData = new FormData();
        formData.append('client', userData.id);
        formData.append('date_visite', DateVisiteInput.current.value);
        formData.append('observations', ObservationInput.current.value);
        formData.append('statut_visite', Statut_visiteInput.current.value);
        formData.append('lieu_rendez_vous', LieuxRdvInput.current.value);
        formData.append('prix_visite',  PrixvisiteInput.current.value);
        
        try {
            axios.patch(dns + `/api/visites/${Visite.IdVisite}/`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                }
            })
            .then(res => {
                if (res.status === 200) {
                    clearFormFields(formRef)
                    Swal.fire({
                        title: 'Modification de visite',
                        text: `Votre visite à été modifer avec succès`,
                        icon: 'success',
                        button: 'OK',
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                        },
                    })
                    .then(Navigate("/user/visites/"));
                } else {
                    Swal.fire({
                        title:'Modification de visite', 
                        text:`Nous avons rencontré un problème lors du traitement de votre requête`, 
                        icon: 'error', 
                        button:'OK',
                        timer: 4000
                    })
               }
            })
            .catch(error => { 
                console.error(error.message);
            })
        } catch (error) {
        console.error(error);
        }
    }
    
    useEffect(() => { 
        const GetDetailsVisite = async () => {
            try {
                axios.get(dns + `/api/visites/${Visite.IdVisite}/`,{
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                })
                .then(res => {
                    if (res.status === 200) {
                        SetDetailsVisite(res.data)
                        console.log(res.data)
                        DateVisiteInput.current.value = res.data.date_visite
                        ObservationInput.current.value = res.data.observations
                        Statut_visiteInput.current.value = res.data.statut_visite
                        LieuxRdvInput.current.value = res.data.lieu_rendez_vous
                        //PrixvisiteInput.current.value = 2000;
                    } else {
                        Swal.fire({
                            title: 'Details panne',
                            text: `Cette panne n'existe pas!`,
                            icon: 'error',
                            button: 'OK',
                            timer: 3000
                        })
                        .then(Navigate("/user/visites/"))
                        }
                    })
            } catch (error) {
                console.error("error: ", error)
            }
        };
        GetDetailsVisite();
        //FecthUserProfile();
    }, [Visite,Navigate]) // Include entityId as a dependency in the dependency array

    return (
        <>
            <Header />
            <div className="page-title">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <h1 className="ipt-title">Details de la visite</h1>
                            <span className="ipn-subtitle"></span>
                        </div>
                    </div>
                </div>
            </div>
             <section className="bg-light">
                <div className="container-fluid">
                    <div className="row">
                        <SideBarDashbord />
                        <div className="col-lg-8 col-md-12">
                            <div id="app">
                                <div className="dashboard-wraper settings crop-avatar">
                                    <div className="form-submit">
                                        <div className="row">
                                        <div className="col-12">
                                            <h4 className="with-actions">Details sur la visite</h4>
                                        </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-5 order-lg-0">
                                                <form onSubmit={handleSubmit} noValidate ref={formRef}>
                                                    <div className="form-group">
                                                        <label htmlFor="pannes">Date de visite</label>
                                                        <input type="date"  ref={DateVisiteInput}  className="form-control" />
                                                    </div>
                                                    {/* <div className="form-group">
                                                        <label htmlFor="pannes">Prix de visite</label>
                                                        <input type="text"  ref={PrixvisiteInput}  className="form-control"  disabled/>
                                                    </div> */}
                                                <div className="form-group">
                                                        <label htmlFor="pannes">Lieux du rendez-vous</label>
                                                        <input type="text"  ref={LieuxRdvInput}  className="form-control"  disabled/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="comment">Observation</label>
                                                        <textarea ref={ObservationInput}  rows="5" placeholder="Observations.." className="form-control" disabled></textarea>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="pannes">Statut</label>
                                                        <select  id="pannes"  className="form-control is-valid" aria-invalid="false" ref={Statut_visiteInput}>
                                                            <option value={DetailsVisite.statut_visite}> {DetailsVisite.statut_visite === "planifiee" ? "Planifiée" : DetailsVisite.statut_visite === "effectuee" ? 'Effectuée' : 'Annulée '}</option>
                                                            {DetailsVisite.statut_visite !== "planifiee" && (
                                                                        <option value="planifiee">Planifiée</option>
                                                        )}
                                                        {DetailsVisite.statut_visite !== "effectuee" && (
                                                                        <option value="effectuee">Effectuée</option>
                                                        )}
                                                        {DetailsVisite.statut_visite !== "annulee " && (
                                                                        <option value="annulee">Annulée </option>
                                                            )}
                                                        </select>
                                                    </div>
                                                    <button type="submit" className="btn btn-primary fw6">Modifier</button>
                                                </form>
                                            </div>
                                        <div className="col-lg-7">
                                            <h4 className="with-actions text-center mb-3">Informations du logement</h4>
                                            <p >
                                                <h5>Commune : {DetailsVisite?.logement?.commune_logement}</h5>
                                                <h5>Quartier : {DetailsVisite?.logement?.quartier_logement}</h5>
                                                    <p>{DetailsVisite?.logement?.description}</p>
                                                    <p>Statut : {DetailsVisite?.logement?.statut_logement}</p>
                                                </p>
                                                <div id="clSev" class="panel-collapse collapse show">
                                                    <div class="block-body">
                                                        <ul class="list-gallery-inline">
                                                            <li><img src={`https://backend.residenceit.com`+DetailsVisite?.logement?.image_logement}   className="img-fluid mx-auto lazy entered loaded"   alt=""  /></li>
                                                            <li><img src={`https://backend.residenceit.com`+DetailsVisite?.logement?.image_logement1}  className="img-fluid mx-auto lazy entered loaded"   alt="" /></li>
                                                            <li><img src={`https://backend.residenceit.com`+DetailsVisite?.logement?.image_logement2}  className="img-fluid mx-auto lazy entered loaded"   alt="" /></li>
                                                            <li><img src={`https://backend.residenceit.com`+DetailsVisite?.logement?.image_logement3}  className="img-fluid mx-auto lazy entered loaded"   alt=""  /></li>
                                                            <li><img src={`https://backend.residenceit.com`+DetailsVisite?.logement?.image_logement4}  className="img-fluid mx-auto lazy entered loaded"   alt=""  /></li>
                                                            <li><img src={`https://backend.residenceit.com`+DetailsVisite?.logement?.image_logement5}  className="img-fluid mx-auto lazy entered loaded"   alt="" /></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default DetailsVisite;