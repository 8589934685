import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";
const Error404 = () => {
    
    return (
        <>
            <Header />
                <div id="app">
                    <section>
                        <div className="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-6 col-md-10">
                                <div class="text-center">
                                    <img src="https://resido.thesky9.com/themes/resido/img/404.png" class="img-fluid" alt="" />
                                </div>
                                <div>
                                    <br></br>
                                    <h4>Cela a pu se produire en raison de plusieurs raisons :</h4>
                                    <ul>
                                        <li>La page que vous avez demandée n'existe pas.</li>
                                        <li>Le lien sur lequel vous avez cliqué n'existe plus.</li>
                                        <li>La page peut avoir été déplacée vers un nouvel emplacement.</li>
                                        <li>Une erreur a pu se produire.</li>
                                        <li>Vous n'êtes pas autorisé à consulter la ressource demandée.</li>
                                    </ul>
                                    <strong>Veuillez réessayer dans quelques minutes, ou retournez à la page d'accueil en <Link  to="/">cliquant ici</Link>.</strong>
                                    <br />
                                </div>
                                <div class="text-center">
                                    <Link class="btn btn-theme" to="/">Retour a l'accueil</Link>
                                </div>
                            </div>pa
                            </div>
                        </div>
                    </section>
                </div>
            <Footer />
        </>
    )

}

export default Error404;