export const nombrepieces = [
    {key: 1, value: "1"},
    {key: 2, value: "2"},
    {key: 3, value: "3"},
    {key: 4, value: "4"},
    {key: 5, value: "5"},
    {key: 6, value: "6"},
    {key: 7, value: "7"},
    {key: 8, value: "8"},
    {key: 9, value: "9"},
    {key: 10, value: "10"},
]

export const TarifsOptions = [
    {"key": 5000, "value": 5000},
    {"key": 10000, "value": 10000},
    {"key": 15000, "value": 15000},
    {"key": 20000, "value": 20000},
    {"key": 25000, "value": 25000},
    {"key": 30000, "value": 30000},
    {"key": 35000, "value": 35000},
    {"key": 40000, "value": 40000},
    {"key": 45000, "value": 45000},
    {"key": 50000, "value": 50000},
    {"key": 55000, "value": 55000},
    {"key": 60000, "value": 60000},
    {"key": 65000, "value": 65000},
    {"key": 70000, "value": 70000},
    {"key": 75000, "value": 75000},
    {"key": 80000, "value": 80000},
    {"key": 85000, "value": 85000},
    {"key": 90000, "value": 90000},
    {"key": 95000, "value": 95000},
    {"key": 100000, "value": 100000},
    {"key": 105000, "value": 105000},
    {"key": 110000, "value": 110000},
    {"key": 115000, "value": 115000},
    {"key": 120000, "value": 120000},
    {"key": 125000, "value": 125000},
    {"key": 130000, "value": 130000},
    {"key": 135000, "value": 135000},
    {"key": 140000, "value": 140000},
    {"key": 145000, "value": 145000},
    {"key": 150000, "value": 150000},
    {"key": 155000, "value": 155000},
    {"key": 160000, "value": 160000},
    {"key": 165000, "value": 165000},
    {"key": 170000, "value": 170000},
    {"key": 175000, "value": 175000},
    {"key": 180000, "value": 180000},
    {"key": 185000, "value": 185000},
    {"key": 190000, "value": 190000},
    {"key": 195000, "value": 195000},
    {"key": 200000, "value": 200000},
    {"key": 205000, "value": 205000},
    {"key": 210000, "value": 210000},
    {"key": 215000, "value": 215000},
    {"key": 220000, "value": 220000},
    {"key": 225000, "value": 225000},
    {"key": 230000, "value": 230000},
    {"key": 235000, "value": 235000},
    {"key": 240000, "value": 240000},
    {"key": 245000, "value": 245000},
    {"key": 250000, "value": 250000},
    {"key": 255000, "value": 255000},
    {"key": 260000, "value": 260000},
    {"key": 265000, "value": 265000},
    {"key": 270000, "value": 270000},
    {"key": 275000, "value": 275000},
    {"key": 280000, "value": 280000},
    {"key": 285000, "value": 285000},
    {"key": 290000, "value": 290000},
    {"key": 295000, "value": 295000},
    {"key": 300000, "value": 300000},
    {"key": 305000, "value": 305000},
    {"key": 310000, "value": 310000},
    {"key": 315000, "value": 315000},
    {"key": 320000, "value": 320000},
    {"key": 325000, "value": 325000},
    {"key": 330000, "value": 330000},
    {"key": 335000, "value": 335000},
    {"key": 340000, "value": 340000},
    {"key": 345000, "value": 345000},
    {"key": 350000, "value": 350000},
    {"key": 355000, "value": 355000},
    {"key": 360000, "value": 360000},
    {"key": 365000, "value": 365000},
    {"key": 370000, "value": 370000},
    {"key": 375000, "value": 375000},
    {"key": 380000, "value": 380000},
    {"key": 385000, "value": 385000},
    {"key": 390000, "value": 390000},
    {"key": 395000, "value": 395000},
    {"key": 400000, "value": 400000},
    {"key": 405000, "value": 405000},
    {"key": 410000, "value": 410000},
    {"key": 415000, "value": 415000},
    {"key": 420000, "value": 420000},
    {"key": 425000, "value": 425000},
    {"key": 430000, "value": 430000},
    {"key": 435000, "value": 435000},
    {"key": 440000, "value": 440000},
    {"key": 445000, "value": 445000},
    {"key": 450000, "value": 450000},
    {"key": 455000, "value": 455000},
    {"key": 460000, "value": 460000},
    {"key": 465000, "value": 465000},
    {"key": 470000, "value": 470000},
    {"key": 475000, "value": 475000},
    {"key": 480000, "value": 480000},
    {"key": 485000, "value": 485000},
    {"key": 490000, "value": 490000},
    {"key": 495000, "value": 495000},
    {"key": 500000, "value": 500000}
  ]