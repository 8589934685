import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import React ,{useContext,useEffect, useState} from 'react'
import  {useNavigate,Link ,useParams} from 'react-router-dom'
import axios from 'axios'
import dns from '../utils/dns'
import ResidenceItem from "../components/ResidenceItemScreen"
import LoadingSpinnerCircular from "../components/spiner"
const ResidenceParCommune = () => {

    //recherche des residences
    const [residences,setResidences] = useState([])
    const [loading, SetLoading] = useState(true);
    const [keyword, setKeyword] = useState('');
    // Step 1: Define the pagination state
    //recuperer la commune dans l'url 
    const commune = useParams(); 
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 6; // You can adjust the number of items per page
    const totalPages = Math.ceil(residences.length / itemsPerPage);
    const [filteredHousingList, setFilteredHousingList] = useState([]);
    const handleSearch = (event) => {
        const { name, value } = event.target;
        setKeyword(value);
        const filteredList = residences.filter(housing => (
            housing.commune_logement.toLowerCase().includes(value.toLowerCase())  ||
            housing.description.toLowerCase().includes(value.toLowerCase()) ||
            housing.tarif_mensuel.toString().includes(value) ||
            housing.quartier_logement.toLowerCase().includes(value.toLowerCase())
        ))
        
        setFilteredHousingList(filteredList);
    };

    const  decodeSlug = (slug) =>{
        // Remplacer les tirets par des espaces
        let decodedStr = slug.replace(/-/g, ' ');
        // Convertir la chaîne en majuscules
        decodedStr = decodedStr.toLowerCase();
    
        // Remettre les accents
        decodedStr = decodedStr.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    
        return decodedStr;
    }
    
    // Step 2: Paginate the data
    const paginatedHotels = residences.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );
    // Function to handle the pagination navigation
    const handlePageChange = (pageNumber) => {
        // Ensure the page number is within the valid range
        if (pageNumber < 1) {
        pageNumber = 1;
        } else if (pageNumber > totalPages) {
        pageNumber = totalPages;
        }
        setCurrentPage(pageNumber);
    };
    useEffect(() => {
        SetLoading(true)
        const FetchResidences = async () => {
            try {
                const {data} = await axios.get(`${dns}/api/logement-list/`,{
                    headers: {
                        'Content-Type': 'application/json',
                        //'Authorization': 'Bearer ' + token
                    },
                })
                const res = data.filter((residence) => {
                    const normalizedCommune = decodeSlug(commune.Searchcommune.toLowerCase());
                    const normalizedResidenceCommune = residence?.commune_logement.toLowerCase();
                  
                    return normalizedResidenceCommune.includes(normalizedCommune) &&
                      (residence?.libelletypelogement === 'Hotels' || residence?.libelletypelogement === 'Residences');
                  }).reverse();
                  

                console.log(res)
                setResidences(res)
                SetLoading(false)
            } catch (error) {
                 console.log(error)
            }
        }
    FetchResidences()
        },[])
    return (
        <>
            <Header />
                <div id="app">
                    <div class="page-title">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-lg-10 col-md-12">
                                    <h1 class="ipt-title">Nos residences de {decodeSlug(commune.Searchcommune)}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section class="gray">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                <div class="filter_search_opt">
                                    <a href="javascript:void(0);" className="open_search_menu">
                                        Rechercher un logement
                                        <i class="ml-2 bi bi-house"></i>
                                    </a>
                                </div>
                                </div>
                         </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12 list-layout">
                                    <div class="row justify-content-center">
                                    <div class="col-lg-12 col-md-12">
                                        <div class="item-sorting-box">
                                            <div class="item-sorting clearfix">                                                
                                                { filteredHousingList.length > 0 ? 
                                                    <div class="left-column pull-left">
                                                        <h4 class="m-0">
                                                            {filteredHousingList.length} résultats correspondent a votre recherche
                                                        </h4>
                                                    </div>
                                                    :
                                                    <div class="left-column pull-left">
                                                        <h4 class="m-0">
                                                           Liste des residences disponibles
                                                        </h4>
                                                    </div>
                                                }
                                            </div> 
                                            <div class="item-sorting-box-right">
                                               
                                                 <div class="sorting-by">
                                                    <input type="text" value={keyword} onChange={handleSearch}  class="form-control select2-hidden-accessible"  placeholder="Rechercher une residence ici...." />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="row">
                                        {keyword === "" && paginatedHotels.length > 0 ? (
                                            paginatedHotels.map((residence) => (
                                            <ResidenceItem key={residence.id} residence={residence} />
                                            ))
                                        ) : paginatedHotels.length <= 0 ? (
                                            <LoadingSpinnerCircular />
                                        ) : (
                                            filteredHousingList.map((residence) => (
                                            <ResidenceItem key={residence.id} residence={residence} />
                                            ))
                                        )}
                                        {keyword !== "" && filteredHousingList.length === 0 && (
                                            <div className="row" role="alert">
                                                <h4 className="text-center">Aucun résultat ne correspond à la recherche.</h4>
                                            </div>
                                        )}
                                    </div>

                                {residences.length > 4 ?
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <nav class="d-flex justify-content-center pt-3" aria-label="Page navigation">
                                                <nav>
                                                   <ul class="pagination">
                                                    {/* "Previous" button */}
                                                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                        <span
                                                        className="page-link"
                                                        onClick={() => handlePageChange(currentPage - 1)}
                                                        disabled={currentPage === 1}
                                                        >‹</span>
                                                    </li>
                                                    {Array.from({ length: totalPages }).map((_, index) => (
                                                        <li
                                                        key={index}
                                                        className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
                                                        >
                                                        <span
                                                            className="page-link"
                                                            onClick={() => handlePageChange(index + 1)}
                                                        >
                                                            {index + 1}
                                                        </span>
                                                        </li>
                                                    ))}

                                                    {/* "Next" button */}
                                                    <li
                                                        className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}
                                                    >
                                                        <span
                                                        className="page-link"
                                                        onClick={() => handlePageChange(currentPage + 1)}
                                                        disabled={currentPage === totalPages}
                                                        >
                                                        ›
                                                        </span>
                                                    </li> 
                                                </ul>
                                                </nav>
                                            </nav>
                                        </div>
                                    </div> : ''}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            <Footer />
        </>
    )
}

export default ResidenceParCommune;
