import SideBarDashbord from "./common"
import Header from "../../components/Header/Header"
import Footer from "../../components/Footer/Footer"

const Paiements = () => {
    
    return (
        <>
            <Header />
            <div class="page-title">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <h1 class="ipt-title">Effectuer un paiement</h1>
                            <span class="ipn-subtitle"></span>
                        </div>
                    </div>
                </div>
            </div>
             <section className="bg-light">
                <div className="container-fluid">
                    <div className="row">
                            <SideBarDashbord />
                            <div class="col-lg-8 col-md-12">
                                <div id="app">
                                    <div class="dashboard-wraper settings crop-avatar">
                                        <div class="form-submit">
                                            <div class="row">
                                            <div class="col-12">
                                                <h4 class="with-actions">Veuillez remplir le formulaire</h4>
                                            </div>
                                            </div>
                                            <div class="row">
                                            <div class="col-lg-8 order-lg-0">
                                                <form action="#" id="setting-form" method="POST" novalidate="novalidate">
                                                        <div class="form-group">
                                                            <label for="last_name">Payer via</label>
                                                            <select  id="gender" class="form-control is-valid" aria-invalid="false">
                                                            <option>Paiements</option>
                                                            <option value={1}>Payer avec wave</option>
                                                            <option value={2}>Payer avec Orange</option>
                                                            <option value={3}>Payer avec Mtn</option>
                                                            <option value={4}>Payer avec Moov</option>
                                                        </select>
                                                        </div>
                                                        <div class="form-group">
                                                            <label for="username">Numéro</label>
                                                            <input type="text" placeholder="Numéro de téléphone*" autoFocus  className="form-control" />
                                                        </div>
                                                    <button type="submit" className="btn btn-primary fw6">Enregistrer</button>
                                                </form>
                                            </div>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Paiements;