import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom"
import Swal from "sweetalert2"
import axios from "axios"
import dns from "../../../../utils/dns";
import { useSelector } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';

const DataTableReservationProprietaireAdmin = ({ reservations }) => {
    const [loading, setLoading] = useState(true);
    const userData = useSelector((state) => state.user.user);
    const token = useSelector((state) => state.user.token);

    useEffect(() => {
        // Simulate an asynchronous data loading process
        setTimeout(() => {
            setLoading(false);
        }, 2000); // Simulating a 2-second loading time
    }, []);

    const DeleteReservation = (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: true
        })

        swalWithBootstrapButtons.fire({
            title: 'Etes vous sûr de supprimer cette reservation ?',
            text: "Vous ne pourrez pas revenir en arrière !",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirmer',
            cancelButtonText: 'Annuler!',
            reverseButtons: true
        })
        .then((result) => {
            if (result.isConfirmed) {
                axios.delete(dns + `/api/reservation/${id}/`, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    }
                })
                .then((response) => {
                    if (response.status === 204) {
                        Swal.fire({
                            title: 'Suppression de reservation',
                            text: `Votre reservation a été supprimée avec succès`,
                            icon: 'success',
                            button: 'OK',
                            timer: 4000
                        })
                        window.location.reload();
                    } else {
                        Swal.fire({
                            title: 'Suppression de reservation',
                            text: `Nous avons rencontré une erreur lors du traitement de votre requête`,
                            icon: 'error',
                            button: 'OK',
                            timer: 4000
                        })
                    }
                })
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                swalWithBootstrapButtons.fire(
                    'Annulé',
                    "l'action a échoué:)",
                    'warning'
                )
            }
        })
    }

    const AnnulerReservation = (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: true
        })

        swalWithBootstrapButtons.fire({
            title: "Etes vous sûr d'annuler cette reservation ?",
            text: "Vous ne pourrez pas revenir en arrière !",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirmer',
            cancelButtonText: 'Annuler!',
            reverseButtons: true
        })
        .then((result) => {
            if (result.isConfirmed) {
                const formdata = new FormData();
                formdata.append("client",userData.id)
                formdata.append("logement_id", id)
                formdata.append("statut_reservation", "annulee")
                axios.post(dns + `/api/reservation/${id}/annulee/`,formdata, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    }
                })
                .then((response) => {
                    if (response.status === 201) {
                        Swal.fire({
                            title: 'Annulation de reservation',
                            text: `Votre reservation a été annulée avec succès`,
                            icon: 'success',
                            button: 'OK',
                            timer: 4000
                        })
                    } else {
                        Swal.fire({
                            title: 'Annulation de reservation',
                            text: `Vous ne pouvez pas annuler cette reservation car la visite a déjà été effectuée`,
                            icon: 'error',
                            button: 'OK',
                            timer: 4000
                        })
                    }
                })
                .catch(error => {
                    console.error("error :", error)
                    Swal.fire({
                        title: 'Annulation de reservation',
                        text: `Le serveur a rencontré une erreur lors du traitement de votre requête`,
                        icon: 'error',
                        button: 'OK',
                        timer: 4000
                    })
                })
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                swalWithBootstrapButtons.fire(
                    'Annulé',
                    "l'action a échoué:)",
                    'warning'
                )
            }
        })
    }

    return (
        <div className="card-body table-responsive">
            <DataTable value={reservations} sortOrder={1} sortField='commune_logement' loading={loading} paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} emptyMessage="Aucune donnée disponible pour le moment">
                {/* <Column field="id" header="#ID"></Column> */}
                <Column field="client.username" header="Client"></Column>
                <Column field="logement.contact_payement" header="Proprietaire"></Column>
                <Column field="logement.nom_proprietaire" header="Structure"></Column>
                <Column field="logement.commune_logement" header="Commune"></Column>
                <Column field="logement.quartier_logement" header="Logement"></Column>
                <Column field="date_reservation" header="Date" body={(rowData) => rowData?.date_reservation?.split('T')[0]}></Column>
                <Column field="date_creation" header="Créer le" body={(rowData) => rowData?.date_reservation?.split('T')[0]}></Column>
                <Column field="statut_reservation" header="Statut" body={(rowData) => rowData?.statut_reservation ==="en_attente" ? "En attente":rowData?.statut_reservation ==="confirmee" ? "Confirmée":"Annulee "}></Column>
                <Column body={(rowData) => (
                    <div className="fr-grid-footer-flex-right">
                        <Link to={`/admin/dashbord/details-reservations/${rowData?.id}/details`} className="prt-view">
                            <i className="bi bi-eye"></i>
                        </Link>
                        <Button type="button" icon="bi bi-trash" className="prt-danger" style={{ marginLeft: '5px' }} onClick={() => DeleteReservation(rowData.id)} />
                        {/* <Button type="button" icon="bi bi-times-circle" className="prt-warning" style={{ marginLeft: '5px' }} onClick={() => AnnulerReservation(rowData.id)} /> */}
                    </div>
                )}></Column>
            </DataTable>
        </div>
    )
}

export default DataTableReservationProprietaireAdmin;
