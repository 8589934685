import Header from "../../../../components/Header/Header"
import Footer from "../../../../components/Footer/Footer"
import PageTitle from "../../components/PageTitle"
import SideBarDashbordAdmin from "../../Sidebar"
import { useState,useEffect } from "react"
import dns from "../../../../utils/dns"
import Swal from "sweetalert2"
import axios from "axios"
import { useSelector } from "react-redux"
import { useNavigate,useParams } from "react-router-dom"

const DetailsAdhesionAdmin = () => {
    const [logements,SetLogement] = useState([])
    const [users,Setusers] = useState([])
    const logement_idInput = useState(null)
    const Navigate = useNavigate()
    const logement = useParams()
    const client_idInput = useState(null)
    const date_amenagementInput = useState(null)
    const date_de_sortieInput = useState(null)
    const statut_adhesionInput = useState(null)
    const token = useSelector((state) =>state.user.token)

    const HandleSubmit = async(e)=>{
        e.preventDefault()

        let data = {
            logement_id: logement_idInput.current.value,
            client_id: client_idInput.current.value,
            date_amenagement: date_amenagementInput.current.value,
            date_de_sortie: date_de_sortieInput.current.value,
            statut_adhesion: statut_adhesionInput.current.value
        }

        try {
            const res = await axios.patch(dns + `/api/adhesion/`,data,{
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })

            if (res.status===200){
                //console.log(res.data)
                Swal.fire({
                    title: "Modification d'adhesion",
                    text: "L'adhesion a été modifiée avec succès!",
                    icon: 'success',
                    button: 'OK',
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                })
                //clearFormFields(); // Clear the form fields
                Navigate('/admin/dashbord/adhesions/liste'); // Redirect to the login page
            }
        } catch (error) {
            console.log(error)
            Swal.fire({
                title: "Modification d'adhesion",
                text: 'Nous avons rencontrer un problème lors du traitement de votre requête!',
                icon: 'error',
                button: 'OK',
                timer: 3000,
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading();
                },
            })
        }

    }

    
    useEffect(()=>{
        const GetLogements = async(e)=>{
            try {
                const res = await axios(dns + `/api/logement/`,{
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                if (res.status===200){
                    SetLogement(res.data)
                }
            } catch (error) {
                console.log(error)
            }
    
        }
        const GetallUsers = async ()=>{
            try {
                const res = await axios.get(dns +`/api/auth/users/`,{
                    headers:{
                        "Content-Type":"application/json",
                        Authorization:`Bearer ${token}`
                    }
                })
                if (res.status===200){
                    Setusers(res.data)
                }
            } catch (error) {
                console.error(error)
            }
        }
        
        const GetAdhesion = async()=>{
            try {
                
                const res = await axios.get(dns + `/api/adhesion/${logement.IdLogement}/`,{
                    headers:{
                        "Content-Type":"application/json",
                        Authorization:`Bearer ${token}`
                    }
                })
    
                if (res.status===200){
                    client_idInput.current.value = res.data.client.id;
                    logement_idInput.current.value = res.data.logement.id;
                    date_amenagementInput.current.value = res.data.date_amenagement;
                    date_de_sortieInput.current.value = res.data.date_de_sortie;
                    statut_adhesionInput.current.value = res.data.statut_adhesion;
                }
            } catch (error) {
                console.log(error)
            }
        }

        GetLogements()
        GetallUsers()
        GetAdhesion()
    }, [token])

    return (
        <>
            <Header />
            <PageTitle title={"Créer une Adhesion"} />
            <section className="gray-simple">
                <div className="container-fluid">
                    <div className="row">
                        <SideBarDashbordAdmin />
                        <div className="col-lg-8 col-md-12">
                            <div id="app">
                                <div className="row">
                                <div className="dashboard-wraper settings crop-avatar">
                                    <div className="form-submit">
                                            <div className="row">
                                                <div className="col-12">
                                                    <h4 className="with-actions">Modifier une adhesion une adhesion</h4>
                                                </div>
                                            </div>
                                        <div className="row">
                                            <form onSubmit={HandleSubmit}>
                                                <div className="form-group">
                                                    <label htmlFor="comment">Logement</label>
                                                    <select id="select-type" data-placeholder="Type" ref={logement_idInput}
                                                        class="form-control select2-hidden-accessible"
                                                        data-select2-id="select2-data-select-type" tabindex="-1" aria-hidden="true">
                                                        <option value="" data-select2-id="select2-data-8-zbq2">-- Selectionner un logement --</option>
                                                        {logements.map((logement) => (
                                                            <option value={logement.id} key={logement.id}>
                                                                {logement.quartier_logement} -- {logement.commune_logement}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="comment">Utilisateur</label>
                                                    <select id="select-type" data-placeholder="Type" ref={client_idInput}
                                                        class="form-control select2-hidden-accessible"
                                                        data-select2-id="select2-data-select-type" tabindex="-1" aria-hidden="true">
                                                        <option value="" data-select2-id="select2-data-8-zbq2">-- Selectionner un utilisateur --</option>
                                                        {users.map((user) => (
                                                            <option value={user.id} key={user.id}>
                                                                {user.username}--{user.frist_name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="comment">Date d'amenagement</label>
                                                    <input type="date"  className="form-control"  required ref={date_amenagementInput}/>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="comment">Date de sortie</label>
                                                    <input type="date"  className="form-control"  required ref={date_de_sortieInput}/>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="comment">Statut</label>
                                                    <select id="select-type" data-placeholder="Type" ref={statut_adhesionInput}
                                                        class="form-control select2-hidden-accessible"
                                                        data-select2-id="select2-data-select-type" tabindex="-1" aria-hidden="true">
                                                        <option data-select2-id="select2-data-8-zbq2">-- Selectionner le statut --</option>
                                                        <option value="actif">
                                                            Actif
                                                        </option>
                                                        <option value="inactif">
                                                            Inactif 
                                                        </option>
                                                    </select>
                                                </div>
                                                {/* <Calendar/> */}
                                                <button type="submit" className="btn btn-primary fw6">Enregistrer</button>
                                            </form>

                                        </div>
                                    </div>
                                    
                                </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default DetailsAdhesionAdmin;