import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import App from './App';
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css"; 
import reportWebVitals from './reportWebVitals';
import store,{persistor} from './store/store';
import { Provider } from 'react-redux';
import { PrimeReactProvider } from 'primereact/api';
import { PersistGate } from 'redux-persist/integration/react';
import Notification from './notification';

const root = ReactDOM.createRoot(document.getElementById('root'));
// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.register('/firebase-messaging-sw.js')
//     .then((registration) => {
//       console.log('Service Worker registered with scope:', registration.scope);
//     })
//     .catch((error) => {
//       console.error('Service Worker registration failed:', error);
//     });
// }

root.render(
  <React.StrictMode>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <PrimeReactProvider value={{ unstyled: true }}>
            <Notification />
            <App />
          </PrimeReactProvider>
        </PersistGate>
      </Provider>
  </React.StrictMode>
);

reportWebVitals();
