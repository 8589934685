import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import { useRef } from "react"
import Swal from "sweetalert2"
import axios from "axios"
import dns from "../utils/dns"
import {useNavigate, } from "react-router-dom"

const PasswordRessetConfirm = () => {
    const newPasswordInput = useRef(null);
    const passwordConfirnput = useRef(null);
    const Navigate = useNavigate()
    const url = window.location.href;
    const urlParams = new URLSearchParams(new URL(url).search);
    // Get the value of 'token' parameter
    const token = urlParams.get('token');
    // Get the value of 'uidb' parameter
    const uidb64 = urlParams.get('uidb64');
    const newPassword = newPasswordInput?.current?.value;
    const passwordConfirm = newPasswordInput?.current?.value;

   
    const SendEmailLink = async(e) => {
        e.preventDefault();
       
        if (newPassword !== passwordConfirm){
            Swal.fire({
                title:'Modification du mot de passe', 
                text:`Les deux mots de passe doivent etre identiques`, 
                icon: 'error', 
                button:'OK',
                timer: 3000,
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading()
                },
            })

        }else{
            if (newPassword.length >=8){
                let data ={
                    password: newPassword,
                    token: token,
                    uidb64: uidb64,
                }
                
                try {
                    const response = await axios.patch(dns + `/api/auth/password_reset_complete/`, data);
                    if (response.status === 200){
                        Swal.fire({
                            title:'Modification du mot de passe', 
                            text:`Votre mot de passe a été modifier avec succès`, 
                            icon: 'success', 
                            button:'OK',
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: () => {
                                Swal.showLoading()
                            },
                        })
    
                        Navigate('/auth/login');
                    }else{
                        Swal.fire({
                            title:'Modification du mot de passe', 
                            text:`Informations non valides, veuillez cliquez sur le lien dans le mail`, 
                            icon: 'error', 
                            button:'OK',
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: () => {
                                Swal.showLoading()
                            },
                        })
                    }
                } catch (error) {
                    Swal.fire({
                        title:'Modification du mot de passe', 
                        text:`Informations non valides, veuillez recliquez sur le lien reçu dans le mail`, 
                        icon: 'error', 
                        button:'OK',
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                        },
                    })
                }
            }else{
                Swal.fire({
                    title:'Modification du mot de passe', 
                    text:`Les mots de passe doivent contenir au moins 8 caractères`, 
                    icon: 'error', 
                    button:'OK',
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: () => {
                        Swal.showLoading()
                    },
                })
            }
        }
        
            
    }

    return (
        <>
            <Header />
                <div id="app">
                    <section>
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-md-6">
                                    <div class="modal-content">
                                        <div class="modal-body">
                                            <h2 class="text-center">Modification du mot de passe </h2>
                                            <form onSubmit={SendEmailLink} class="simple-form">
                                                <div class="form-group">
                                                    <div class="input-with-icon">
                                                        <input id="email" type="text" ref={newPasswordInput} required="required" placeholder="Le nouveau mot de passe" class="form-control" />
                                                        <i class="bi bi-lock"></i>
                                                    </div>
                                                    <div class="input-with-icon mt-3">
                                                        <input id="email" type="text" ref={passwordConfirnput} required="required" placeholder="Confirmer Le nouveau mot de passe" class="form-control" />
                                                        <i class="bi bi-lock"></i>
                                                    </div>
                                                </div>
                                                <div class="form-group mb-0">
                                                    <button type="submit" class="btn btn-md full-width btn-primary rounded">
                                                        Changer  mon mot de passe
                                                    </button> 
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            <Footer />
        </>
    )
}

export default PasswordRessetConfirm