import TableRolesAdmin from "./TableRoles"
import Header from "../../../../components/Header/Header"
import Footer from "../../../../components/Footer/Footer"
import PageTitle from "../../components/PageTitle"
import SideBarDashbordAdmin from "../../Sidebar"
import { useRef } from "react"
import dns from "../../../../utils/dns"
import axios from "axios"
import { useSelector } from "react-redux"
import Swal from "sweetalert2"
import { useNavigate } from "react-router-dom"

const CreateRolesAdmin = () => {
    const LibelleInput = useRef(null)
    const token = useSelector((state)=> state.user.token)
    const  Navigate = useNavigate()
    const HandleSubmit = async(e)=>{
        e.preventDefault()
        let data={
            libelle_role:LibelleInput.current.value
        }

        try {
            const res = await axios.post(dns + `/api/auth/role/`,data,{
                headers:{
                    "Content-Type":"application/json",
                    Authorization:`Bearer ${token}`
                }
            })
            
            if (res.staus===200){
                Swal.fire({
                    title:'Création de role', 
                    text:`Votre compte a été créer avec succes!`, 
                    icon: 'success', 
                    button:'OK',
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: () => {
                        Swal.showLoading()
                    },
                }).then(() => {
                    //clearFormFields(); // Clear the form fields
                    Navigate('/auth/login'); // Redirect to the login page
                });
            }

        } catch (error) {
            console.error(error)
            Swal.fire({
                title:'Création de role', 
                text:`Nous avons rencontrer un problème lors de la creations du role!`, 
                icon: 'error', 
                button:'OK',
                timer: 3000,
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading()
                },
            })
        }
    }
    return (

        <>
            <Header />
            <PageTitle title={"Ajouter un rôle"} />
            <section className="gray-simple">
                <div className="container-fluid">
                    <div className="row">
                        <SideBarDashbordAdmin />
                        <div className="col-lg-8 col-md-12">
                            <div id="app">
                                <div className="dashboard-wraper settings crop-avatar">
                                    <div className="form-submit">
                                            <div className="row">
                                            <div className="col-12">
                                                <h4 className="with-actions">Enregsitrer un rôle</h4>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <form onSubmit={HandleSubmit}>
                                                <div className="form-group">
                                                    <label htmlFor="comment">Libelle de du role</label>
                                                    <input type="text" placeholder="Libelle du role.." className="form-control" ref={LibelleInput} required/>
                                                </div>
                                                <button type="submit" className="btn btn-primary fw6">Enregistrer</button>
                                            </form>

                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default CreateRolesAdmin