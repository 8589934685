import TableRolesAdmin from "./TableRoles"
import Header from "../../../../components/Header/Header"
import Footer from "../../../../components/Footer/Footer"
import PageTitle from "../../components/PageTitle"
import SideBarDashbordAdmin from "../../Sidebar"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import axios from "axios"
import dns from "../../../../utils/dns"
import { useSelector } from 'react-redux';
import { InputText } from 'primereact/inputtext';


const ListeRolesAdmin = () => {
    const userData = useSelector((state) => state.user.user)
    const token = useSelector((state) => state.user.token);
    const [roles, setRoles] = useState([]);
    const GetRoles = async () => {
        try {
            const response = await axios.get(dns + `/api/auth/role/`, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`,
                },
            })

            if (response.status === 200) {
                console.log(response.data)
                setRoles(response.data)
            }
        } catch (error) {
            console.error(error);
        }
    }

    
    useEffect(() => {
        GetRoles()
        
    }, []);
    return (

        <>
            <Header />
            <PageTitle title={"Liste des roles"} />
            <section className="gray-simple">
                <div className="container-fluid">
                    <div className="row">
                        <SideBarDashbordAdmin />
                        <div className="col-lg-8 col-md-12">
                            <div id="app">
                                <div className="row">
                                    <TableRolesAdmin  roles={roles}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default ListeRolesAdmin