import axios from "axios"
import dns from "../../utils/dns"
import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import BienSreen from "../../screens/biens/BienListeParTypeSreen";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import LoadingSpinnerCircular from "../../components/spiner";
import { TarifsOptions } from "../../screens/helpers"

const AppartementScreen = () => {
    const [biens, setBiens] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [allSearch, setAllSearch] = useState('')
    const [minTarif, setMinTarif] = useState(0)
    const [maxTarif, setMaxTarif] = useState(0)

    const GetBiens = async () => {
        const response = await axios.get(dns + `/api/bien-list/`, {
            headers: {
                'Content-Type': 'multipart/form-data',
                // Authorization: `Bearer ${token}`,
            },
        })
        if (response.status === 200) {
            const data = response.data.filter((bien)=> bien.type_bien.libelle==="Appartements")
            data.sort((a, b) => {
                // Convertir les dates en objets Date pour pouvoir les comparer
                const idA = a.id
                const idB = b.id
                
                // Comparer les dates et retourner le résultat du tri
                return idB - idA;
            });
            setBiens(data);
        }
        
    }

    useEffect(() => {
        GetBiens();
    },[])

    let filteredAppartements = [];

        if (allSearch == '') {
            filteredAppartements = biens;
          } else {
            filteredAppartements = biens.filter((bien) =>
              Object.values(bien).some(
                (value) => typeof value === 'string' && value.toLowerCase().includes(allSearch.toLowerCase())
              )
            );
        }
        
        if (minTarif == 0 || maxTarif == 0) {
            filteredAppartements = filteredAppartements;
          } else {
            
            filteredAppartements = filteredAppartements.filter((bien) => {
                if (bien && bien.prix) {
                return (bien.prix >= minTarif && bien.prix <= maxTarif )
                }
                return false;
            });
        }

    const itemsPerPage = 4; // You can adjust the number of items per page
    // Calculate the total number of pages
    const totalPages = Math.ceil(filteredAppartements.length / itemsPerPage);
    // Step 2: Paginate the data
    const paginatedAppart = filteredAppartements.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );
    // Function to handle the pagination navigation
     const handlePageChange = (pageNumber) => {
        // Ensure the page number is within the valid range
        if (pageNumber < 1) {
        pageNumber = 1;
        } else if (pageNumber > totalPages) {
        pageNumber = totalPages;
        }
        setCurrentPage(pageNumber);
    };

    
    


    return (
        <>
            <Header />
                <div id="app">
                     <div class="page-title">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <h1 class="ipt-title">Nos appartements</h1>
                                    <span class="ipn-subtitle"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="gray mb-50">
                        <div className="container">
                            <div className="row">
                                <div class="col-lg-12 col-md-12 list-layout">
                                <div class="row justify-content-center">

                                    <div class="col-lg-12 col-md-12">
                                        <div class="item-sorting-box">
                                            <div class="item-sorting clearfix">                                                
                                                { filteredAppartements.length > 0 ? 
                                                    <div class="left-column pull-left">
                                                        <h4 class="m-0">
                                                            {filteredAppartements.length} résultats correspondent a votre recherche
                                                        </h4>
                                                    </div>
                                                    :
                                                    <div class="left-column pull-left">
                                                        <h4 class="m-0">
                                                           Liste des appartements disponibles
                                                        </h4>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <div class="item-sorting-box">
                                            <div class="item-sorting-box-right">
                                                 <div class="sorting-by">
                                                    <input type="text" value={allSearch} onChange={(e)=> setAllSearch(e.target.value)}  class="form-control select2-hidden-accessible"  placeholder="Rechercher un appartement ici...." />
                                                </div>
                                            </div>

                                            
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12">
                                    <div class="item-sorting-box">
                                        <div class="item-sorting-box-right">
                                                 <div class="sorting-by">
                                                
                                                <select class="form-control ui-select is-valid" onChange={(e) => setMinTarif(e.target.value)} id="type_id" aria-invalid="false">
                                                    <option value={0}>Montant minimum / Voir tout</option>
                                                    {TarifsOptions.map((tarif, index)=>(
                                                        <option key={index} value={tarif.value}>{tarif.key}</option>
                                                    ))}
                                                </select>
                                                </div>
                                            </div>

                                            <div class="item-sorting-box-right">
                                                 <div class="sorting-by">
                                                
                                                <select class="form-control ui-select is-valid" onChange={(e) => setMaxTarif(e.target.value)} id="type_id" aria-invalid="false">
                                                    <option value={0}>Montant maximum / Voir tout</option>
                                                    {TarifsOptions.map((tarif, index)=>(
                                                        <option key={index} value={tarif.value}>{tarif.key}</option>
                                                    ))}
                                                </select>
                                                </div> 
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            <div class="row">
                            {paginatedAppart.length>0 ? (paginatedAppart.map((appart) => (
                                <BienSreen key={appart.id} bien={appart} />
                            ))
                       
                            ) : paginatedAppart.length <= 0 ? (
                                <LoadingSpinnerCircular />
                            ) : (
                                filteredAppartements.map((appart) => (
                                <BienSreen key={appart.id} bien={appart} />
                                ))
                            )}
                    
                            {filteredAppartements.length === 0 && (
                                <div className="row" role="alert">
                                    <h4 className="text-center">Aucun résultat ne correspond à la recherche.</h4>
                                </div>
                            )}

                            {filteredAppartements.length > 2 ?
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <nav class="d-flex justify-content-center pt-3" aria-label="Page navigation">
                                            <nav>
                                                <ul class="pagination">
                                                    {/* "Previous" button */}
                                                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                        <span
                                                        className="page-link"
                                                        onClick={() => handlePageChange(currentPage - 1)}
                                                        disabled={currentPage === 1}
                                                        >‹</span>
                                                    </li>
                                                    {Array.from({ length: totalPages }).map((_, index) => (
                                                        <li
                                                        key={index}
                                                        className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
                                                        >
                                                        <span
                                                            className="page-link"
                                                            onClick={() => handlePageChange(index + 1)}
                                                        >
                                                            {index + 1}
                                                        </span>
                                                        </li>
                                                    ))}

                                                    {/* "Next" button */}
                                                    <li
                                                        className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}
                                                    >
                                                        <span
                                                        className="page-link"
                                                        onClick={() => handlePageChange(currentPage + 1)}
                                                        disabled={currentPage === totalPages}
                                                        >
                                                        ›
                                                        </span>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </nav>
                                    </div>
                                </div>
                                : ''
                            }
                             </div>
                             </div>
                            </div>
                        </div>
                    </section>
                </div>
            <Footer />
           
        </>
    )
}

export default AppartementScreen;