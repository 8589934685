import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Link } from "react-router-dom"
import { useSelector } from 'react-redux';

const DataTablePanne = ({ pannes }) => {
    const [loading, setLoading] = useState(true);
    const userData = useSelector((state) => state.user.user);

    useEffect(() => {
        // Simulate an asynchronous data loading process
        setTimeout(() => {
            setLoading(false);
        }, 2000); // Simulating a 2-second loading time
    }, []);

    const statutPanneTemplate = (rowData) => {
        return (
            <>
                {rowData.statut_panne === "en_attente" ? "En attente" : rowData.statut_panne === "en_cours" ? 'En cours' : 'Résolue'}
            </>
        );
    }

    const typePanneTemplate = (rowData) => {
        switch (rowData.type_panne_id) {
            case 1:
                return "Plomberie";
            case 2:
                return "Menuiserie";
            case 3:
                return "Charpenterie";
            case 4:
                return "Mobiliers";
            case 5:
                return "Electricité";
            default:
                return "";
        }
    }

    return (
        <div className="card-body table-responsive">
            <DataTable value={pannes} paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} loading={loading} emptyMessage="Aucune donnée disponible pour le moment">
                {/* <Column field="id" header="#ID"></Column> */}
                <Column field="description_panne" header="Description"></Column>
                <Column field="date_creation" header="Date" body={(rowData) => rowData?.date_creation?.split('T')[0]}></Column>
                <Column field="type_panne.libelle_type_panne" header="Type de panne" body={typePanneTemplate}></Column>
                <Column field="statut_panne" header="Statut" body={statutPanneTemplate}></Column>
                <Column body={(rowData) => (
                    <div className="fr-grid-footer-flex-right">
                        <Link to={`/user/details-panne/${rowData?.id}/`} className="prt-view">
                            <i className="bi bi-plus-circle-fill"></i>
                        </Link>
                    </div>
                )}></Column>
            </DataTable>
        </div>
    )
}

export default DataTablePanne;
