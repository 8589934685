import SideBarDashbord from "./common"
import Header from "../../components/Header/Header"
import Footer from "../../components/Footer/Footer"
import axios from "axios"
import dns from "../../utils/dns"
import React ,{useEffect, useState} from 'react'
import Swal from "sweetalert2"
import non_data from '../../public/img/non-data.png'
import { Link, useNavigate } from "react-router-dom";
import no_data from '../../public/img/non-data.png'
import DataTableVisites from "../../components/TableVisite"
import { useSelector } from 'react-redux';
import PaginatorBasicDemo from "../../components/DataTableSreen"

const ListeVisites = () => {

    const [visites, SetVisites] = useState([]);
    const userData = useSelector((state) => state.user.user);
    const token = useSelector((state) => state.user.token);
    const Navigate = useNavigate();

    const GetVisites = () => {
        if (token) {
            const response = axios.get(dns + `/api/visites/${userData.id}/visites/`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                }
            })
            response.then((res) => {
                if (res.status === 200) {
                    const responseData = res.data
                    responseData.sort((a, b) => {
                        // Convertir les dates en objets Date pour pouvoir les comparer
                        const idA = a.id
                        const idB = b.id
                        
                        // Comparer les dates et retourner le résultat du tri
                        return idB - idA;
                    });
                    SetVisites(responseData)
                } 
            })
            .catch(error => {
                console.error("nous avons rencontrer une erreur:", error)
            })
        } else {
            Swal.fire({
                title: 'Connexion requise',
                text: `Vous devez être connecté pour acceder a cette vue`,
                icon: 'error',
                button: 'OK',
                timer: 4000
            })
            .then(
                Navigate("/auth/login")
            )
        }
    }

    
    useEffect(() => {
        GetVisites();
    }, [])
    return (
        <>
            <Header />
            <div class="page-title">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <h1 class="ipt-title">Liste des visites</h1>
                            <span class="ipn-subtitle"></span>
                        </div>
                    </div>
                </div>
            </div>
            <section className="gray-simple">
                <div className="container-fluid">
                    <div className="row">
                        <SideBarDashbord />
                        <div className="col-lg-8 col-md-12">
                            <div id="app">
                                <div className="row">
                                    <DataTableVisites visites={visites}/>
                                    </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default ListeVisites;