/* eslint-disable react-hooks/exhaustive-deps */
import Header from "../../components/Header/Header"
import Footer from "../../components/Footer/Footer"
import { useParams,useNavigate } from "react-router-dom";
import React, { useEffect, useState ,useRef } from 'react';
import dns from "../../utils/dns";
import axios from "axios";
import { useSelector } from "react-redux";
import logo from "../../public/img/logo.png"
import Swal from 'sweetalert2';
import clearFormFields from "../../utils/cleanform";
import "../../screens/modale.css"

const DetailsBienImmobilier = () => {
    const token = useSelector((state) => state.user.token);
    const userData = useSelector((state) => state.user.user);
    const bienImmobielier = useParams();
    const [bien, setBien] = useState({});
    const [loading, setLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [caption, setCaption] = useState('');
    const  Navigate = useNavigate()
    const DateInput = useRef(null);
    const formRef = useRef(null);
    const LieuxRdvInput = useRef(null);
    const currentUrl = window.location.href;
    const number = ""
    const whatsappMessage = `Bonjour je suis interssé par l'un de vos biens.\nLien du lien Cliquez ici pour voir le bien: ${currentUrl} `;
    const whatsappLink = bien?.contact_payement?.startsWith("+225") ?`https://api.whatsapp.com/send?phone=${bien?.contact_payement}&text=${encodeURIComponent(whatsappMessage)}` : `https://api.whatsapp.com/send?phone=225${bien?.contact_payement}&text=${encodeURIComponent(whatsappMessage)}`;
    const MessageInput = useRef(null); 
    // Get the modal
    var modal = document.getElementById("myModal");
    const openModal = (imageSrc, altText) => {
        modal.style.display = "block";
        setSelectedImage(imageSrc);
        setCaption(altText);
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(false);
        modal.style.display = "none";
    };

    const ReservationsBien = async () => {
        setLoading(true)
        if (token) {
            const formData = new FormData();
            formData.append('bien_id', bienImmobielier?.id);
            formData.append('statut_reservation', "en_attente");
            formData.append('client_id', userData?.id);
            try {
                axios.post(dns + `/api/reservation-biens/create_reservation/`,formData,{
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then(res => {
                    if (res.status === 201) {
                        setLoading(true)
                        Swal.fire({
                            title:'Réservation de bien', 
                            text:`Votre avons notifié le propriétaire de ce bien que vous êtes interessé`, 
                            icon: 'success', 
                            button:'OK',
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: () => {
                                Swal.showLoading()
                            },
                        })
                        .then(Navigate("/user/liste-reservations/bien-immobilier"))
                    } else {
                        setLoading(false)
                        Swal.fire({
                            title:'Réservation de bien', 
                            text:`Nous avons rencontré un problème lors du traitement de votre requête`, 
                            icon: 'error', 
                            button:'OK',
                            timer: 3000
                        })
                    }
                })
                .catch(error => { 
                    console.error(error);
                    setLoading(false)
                    Swal.fire({
                        title:'Creation de visite', 
                        text:`Nous avons rencontré un problème lors du traitement de votre requête`, 
                        icon: 'error', 
                        button:'OK',
                        timer: 3000
                    })
                })
            } catch (error) {
                console.error(error);
                setLoading(false)
                Swal.fire({
                    title:'Réservation de bien', 
                    text:`Nous avons rencontré un problème lors du traitement de votre requête`, 
                    icon: 'error', 
                    button:'OK',
                    timer: 3000
                })
            }
        } else {
            setLoading(false)
            Swal.fire({
                title:'Réservation de bien', 
                text:`Vous devez etre connecter pour effectuer cette action. `, 
                icon: 'error', 
                button:'OK',
                timer: 4000
            })
            .then(Navigate(`/auth/login?from=/biens-immobiliers/${bienImmobielier?.id}/details/`))
        }
    }

    const MakeApointement = async (e) => {
        e.preventDefault();

        if (token) {
            if (userData.libelle_role !== "Proprietaire" || userData.libelle_role !== "Admin"){
                const date = DateInput.current.value;
                const message = MessageInput.current.value;
                const formData = new FormData();
                const prix_visite = 2000;
                formData.append('date_visite', date);
                formData.append('statut_visite', "planifiee");
                formData.append('lieu_rendez_vous',  LieuxRdvInput.current.value);
                formData.append('observations', message);
                formData.append('prix_visite', prix_visite);
                formData.append('client_id', userData.id);
                formData.append('logement_id',bienImmobielier?.id);
                 try {
                axios.post(dns + `/api/visites/`,formData,{
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then(res => {
                    if (res.status === 201) {
                        clearFormFields(formRef);
                        Swal.fire({
                            title:'Creation de visite', 
                            text:`Votre soumission à été envoyer avec succès`, 
                            icon: 'success', 
                            button:'OK',
                            timer: 3000,
                            timerProgressBar:true,
                            didOpen:()=>{
                                Swal.showLoading();
                            }
                        })
                        .then(Navigate("/user/visites/"))
                    } else {
                        Swal.fire({
                            title:'Creation de visite', 
                            text:`Nous avons rencontré un problème lors du traitement de votre requête`, 
                            icon: 'error', 
                            button:'OK',
                            timer: 3000
                        })
                    }
                })
                .catch(error => { 
                    console.error(error);
                    Swal.fire({
                        title:'Creation de visite', 
                        text:`Nous avons rencontré un problème lors du traitement de votre requête`, 
                        icon: 'error', 
                        button:'OK',
                        timer: 3000,
                        timerProgressBar:true,
                        didOpen:()=>{
                            Swal.showLoading();
                        }
                    })
                })
            } catch (error) {
                console.error(error);
                Swal.fire({
                    title:'Creation de visite', 
                    text:`Nous avons rencontré un problème lors du traitement de votre requête`, 
                    icon: 'error', 
                    button:'OK',
                    timer: 3000,
                    timerProgressBar:true,
                    didOpen:()=>{
                        Swal.showLoading();
                    }
                })
            }
            }else{
                Swal.fire({
                    title:'Réservation de residence', 
                    text:`Vous n'êtes pas autorisé a faire cette action.`, 
                    icon: 'error', 
                    button:'OK',
                    timer: 5000,
                    timerProgressBar:true,
                    didOpen:()=>{
                        Swal.showLoading();
                    }
                })
            }
        } else {
            Swal.fire({
                title:'Creation de visite', 
                text:`Vous devez etre connecter pour demander une visite`, 
                icon: 'error', 
                button:'OK',
                timer: 4000,
                timerProgressBar:true,
                didOpen:()=>{
                    Swal.showLoading();
                }
            })
            .then(Navigate(`/auth/login?from=/details-residence/${bienImmobielier?.id}`))
        }
    }

    useEffect(() => {
        const fetchEntityDetails = async () => {
            try {
                const response = await axios.get(dns + `/api/bien/${bienImmobielier?.id}/`, {
                    headers: {
                        'Content-Type': 'application/json',
                        // Authorization: `Bearer ${token}`,
                    },
                });

                if (response.status === 200) {
                    setBien(response.data);
                    //console.log(response.data); // Use this for debugging
                    LieuxRdvInput.current.value = response?.data?.localisation;
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchEntityDetails();
    }, [bienImmobielier, token]);

    return (
        <>
            <Header />
            <div id="app">
                <section className="bg-title">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-11 col-md-12">
                                <div className="property_block_wrap style-4">
                                    <div className="prt-detail-title-desc">
                                        {/* <span className="prt-types sale rent">Disponible</span> */}
                                        {bien?.est_disponible ? <span className="prt-types sale rent">Disponible</span> : <span className="prt-types sale rent">Indisponible</span>}
                                        <h3 className="text-light">{bien?.localisation} ({bien?.libelle_type_bien})</h3>
                                        {bien?.surface && <span><i className="bi bi-geo-alt"></i> {bien?.surface?.toString().replace(".00", "")} m²</span>}
                                        {bien?.prix && <h3 className="prt-price-fix">{bien?.prix?.toString().replace(".00", "")} fcfa</h3>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="property-detail gray-simple">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-8 col-md-12 col-sm-12">
                                <div class="property_block_wrap style-2">
                                    <div class="property_block_wrap_header">
                                        <a data-bs-toggle="collapse" data-parent="#loca" data-bs-target="#clSix" aria-controls="clSix" href="javascript:void(0);" aria-expanded="true" class="collapsed">
                                            <h4 class="property_block_title">Description</h4>
                                        </a>
                                        <div id="clTwo" class="panel-collapse collapse show">
                                            <div class="block-body">
                                                <p>{bien?.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="property_block_wrap style-2">
                                    <div class="property_block_wrap_header">
                                        <a data-bs-toggle="collapse" data-parent="#clSev" data-bs-target="#clSev" aria-controls="clOne" href="javascript:void(0);" aria-expanded="true" class="collapsed">
                                            <h4 className="property_block_title">Photos du bien</h4>
                                        </a>
                                    </div>
                                    <div id="clSev" class="panel-collapse collapse show">
                                        <div class="block-body">
                                            <div class="clearfix">
                                                <p> NB: cliquez sur une image pour l'afficher.</p>
                                            </div>
                                            <ul class="list-gallery-inline">
                                                <li><img src={bien?.image_bien1}  className="img-fluid mx-auto lazy entered loaded" alt=""   onClick={() => openModal(bien?.image_bien1, "Image Alt Text")}/></li>
                                                <li><img src={bien?.image_bien2}  className="img-fluid mx-auto lazy entered loaded" alt=""   onClick={() => openModal(bien?.image_bien2, "Image Alt Text")} /></li>
                                                <li><img src={bien?.image_bien3}  className="img-fluid mx-auto lazy entered loaded"  alt=""  onClick={() => openModal(bien?.image_bien3, "Image Alt Text")}/></li>
                                                <li><img src={bien?.image_bien4}  className="img-fluid mx-auto lazy entered loaded"  alt=""  onClick={() => openModal(bien?.image_bien4, "Image Alt Text")}/></li>
                                                <li><img src={bien?.image_bien5}  className="img-fluid mx-auto lazy entered loaded" alt=""   onClick={() => openModal(bien?.image_bien5, "Image Alt Text")}/></li>
                                            </ul>
                                             <button type="button" className="btn btn-primary fw6 mt-4" onClick={ReservationsBien} disabled={loading}>{loading ? "Reservation en cours..." : "Reserver ce bien"}</button>
                                        </div>
                                    </div>
                                    <div id="myModal" class="modal">
                                        <span class="close" onClick={closeModal}>&times;</span>
                                            <img class="modal-content" id="img01" src={selectedImage} alt={caption}/>
                                        <div id="caption"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 col-sm-12">
                                <div class="like_share_wrap b-0">
                                    <ul class="like_share_list justify-content-center">
                                        <li class="social_share_list">
                                            <a href={whatsappLink} data-bs-toggle="tooltip" data-original-title="Share" class="btn btn-likes"  target="_blank" rel="noopener noreferrer">
                                                <i class="bi bi-whatsapp"></i>
                                                Whatsapp
                                            </a> 
                                            <div class="social_share_panel">
                                                <a href={whatsappLink}  target="_blank" class="cl-linkedin" rel="noopener noreferrer">
                                                    <i class="bi bi-whatsapp"></i>
                                                </a>
                                            </div>
                                        </li> 
                                        <li>
                                            <a href={`tel:+225${bien?.contact_payement}`} data-id="12" data-bs-toggle="tooltip" data-original-title="Save" class="btn btn-likes add-to-wishlist">
                                            <i class="bi bi-phone-fill"></i>Appeler</a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="details-sidebar">
                                    <div class="sides-widget">
                                        <div class="sides-widget-header">
                                            <div class="agent-photo"><img src={logo} alt="" /></div>
                                                <div class="sides-widget-details">
                                                <h4><a href="#"> {bien?.agent_immobilier ? bien?.agent_immobilier:"Residence IT team"}</a></h4>
                                                <a href={`tel:+225${bien?.contact_payement}`}><span><i class="lni-phone-handset"></i>+225{bien?.contact_payement}</span></a>
                                            </div>
                                            <div class="clearfix"></div>
                                        </div>
                                        <div class="sides-widget-body simple-form">
                                            <form onSubmit={MakeApointement} id="consult-form" class="consult-form" ref={formRef}>
                                                <div class="row">
                                                    <div class="form-group">
                                                        <label htmlFor="">Date de visite</label>
                                                        <input ref={DateInput} id="date" type="date"  required="required" class="form-control" />
                                                    </div>
                                                    <div class="form-group">
                                                        <label htmlFor="">Lieu de visite</label>
                                                        <input type="text" ref={LieuxRdvInput} placeholder="Lieux du rendez-vous *" data-validation-engine="validate[required]" data-errormessage-value-missing="Please enter phone number!" class="form-control" disabled/>
                                                    </div>
                                                    {/* <div class="form-group"><input  ref={PrixInput}  type="text"  class="form-control" value="5000 cfa"/></div> */}
                                                    {/* <div class="form-group"><input type="text" placeholder="Subject *"   class="form-control" /></div> */}
                                                    <div class="form-group">
                                                        <textarea ref={MessageInput} rows="5" placeholder="Message *" class="form-control" ></textarea>
                                                    </div>
                                                    <div class="form-group">
                                                       <button type="submit" class="btn btn-black btn-md rounded full-width">Prendre rendez-vous</button>
                                                    </div>
                                                    {/* <div class="clearfix">
                                                        <p> NB: le prix de la visite est de 2000f</p>
                                                    </div> */}
                                                    <br /> 
                                                    <div class="alert alert-success text-success text-left" style={{ display: "none" }}><span></span></div>
                                                    <div class="alert alert-danger text-danger text-left" style={{ display: "none" }}><span></span></div>
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </>
    )
}

export default DetailsBienImmobilier;
