import Header from "../../../../../components/Header/Header"
import Footer from "../../../../../components/Footer/Footer"
import PageTitle from "../../../components/PageTitle"
import SideBarDashbordAdmin from "../../../Sidebar"
import DataTableParticuliersAdmin from "./TableParticulerListe"
import axios from "axios"
import dns from "../../../../../utils/dns"
import { useState,useEffect } from "react"
import { useSelector } from "react-redux"
const ListeParticulierAdmin = () => {
    const [users, SetUsers] = useState([])
    const token = useSelector((state)=> state.user.token)
    const GetallUsers = async ()=>{
        try {
            const res = await axios.get(dns +`/api/auth/users/`,{
                headers:{
                    "Content-Type":"application/json",
                    Authorization:`Bearer ${token}`
                }
            })
            if (res.status===200){
                const sortedData = res?.data?.filter((user)=>user?.libelle_role === "SimpleClient").sort((a, b) => {
                    // Convertir les dates en objets Date pour pouvoir les comparer
                    const idA = a.id
                    const idB = b.id
                    
                    // Comparer les dates et retourner le résultat du tri
                    return idB - idA;
                });
                SetUsers(sortedData)
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(()=>{
        GetallUsers()
    })
    return (

        <>
            <Header />
            <PageTitle title={"Liste des particuliers"} />
            <section className="gray-simple">
                <div className="container-fluid">
                    <div className="row">
                        <SideBarDashbordAdmin />
                        <div className="col-lg-8 col-md-12">
                            <div id="app">
                                <DataTableParticuliersAdmin users={users} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default ListeParticulierAdmin