import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import { useNavigate,NavLink, Link } from 'react-router-dom'
import React, {useEffect, useRef,useState} from 'react'
import Swal from 'sweetalert2';
import axios from 'axios'
import dns from '../utils/dns'
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
const RegisterAgent = () => {
    const Navigate =   useNavigate()
    const [loading, setLoading] = useState(false);
    const [roles, setRole] = useState([])
    const [selectedRole, setSelectedRole] = useState('Proprietaire');
    const LastNameInput = useRef(null)
    const FirstNameInput = useRef(null)
    const phoneNumberInput = useRef(null)
    const EmailInput = useRef(null)
    const passwordInput = useRef(null)
    const passwordInput2 = useRef(null)
    const nomProprietaireInput = useRef(null)
    const sexInput = useRef(null)
    const [phone, setPhone] = useState('');
    const [contactPaiement, setContactPaiement] = useState('');
    const handleUserTypeChange = (e) => {
        setSelectedRole(e.target.value);
    }
    
    const inputStyles = {
        backgroundClip: 'initial',
        border: '1px solid #e0ecf5',
        boxShadow: 'none',
        fontSize: '16px',
        height: '56px',
        padding: '.5rem .75rem'
      };

    const GetRoles = async () => {
        try {
            const response = await axios.get(dns + `/api/auth/role/`)
            if (response.status === 200) {
                const rolesSansAdmin = response.data.filter(role => role.libelle_role !== "Admin" && role.libelle_role !=='Etudiant' && role.libelle_role !=="Administrateur" && role.libelle_role !=='SimpleClient').reverse();
                //console.log(rolesSansAdmin)
                setRole(rolesSansAdmin)
            }
        } catch (error) {
            
        }
    }

    const submitHandler = async (e) => {
        e.preventDefault()
        setLoading(true)
        const selectedRoleId = roles.find(role => role.libelle_role === selectedRole)?.id;
        const formData = new FormData();
        if (selectedRole) {
            formData.append('last_name', LastNameInput.current.value);
            formData.append('first_name', FirstNameInput.current.value);
            formData.append('username', phone);
            formData.append('numero_telephone', phone);
            formData.append('email', EmailInput.current.value);
            formData.append('password', passwordInput.current.value);
            formData.append('noms_proprietaire', nomProprietaireInput.current.value);
            formData.append('contact_payement', contactPaiement);
            formData.append('genre', sexInput.current.value);
            formData.append('role', selectedRoleId);
        } 
        const password = passwordInput.current.value
        const password2 = passwordInput2.current.value
        if (password === password2) {
            try {
                const response = await axios.post(dns + '/api/auth/register', formData)
                if (response.status === 201) {
                    setLoading(false)
                    Swal.fire({
                        title:'Création de compte', 
                        text:`Le compte agent immobilier à été créer avec succes!`, 
                        icon: 'success', 
                        button:'OK',
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                        },
                    })
                    //clearFormFields(); // Clear the form fields
                    Navigate('/auth/login'); // Redirect to the login page
                }else {
                    setLoading(false)
                    Swal.fire({
                        title:'Création de compte', 
                        text:`Nous avons rencontré un problème lors du traitement de votre requête`, 
                        icon: 'error', 
                        button:'OK',
                        timer: 3000
                    })
                }
                    
            } catch (error) {
                setLoading(false)
                const errorResponse = error?.request?.response
                console.error(errorResponse);
                
                Swal.fire({
                    title:'Création de compte', 
                    text:`Le serveur à rencontrer une erreur lors du traitement de votre requête`, 
                    icon: 'error', 
                    button:'OK',
                    timer: 4000
                })
            }

        } else {
            setLoading(false)
            Swal.fire({
                title:'Mot de passe', 
                text:`Les deux mots de passes doivent être identiques`, 
                icon: 'error', 
                button:'OK',
                timer: 3000
            })
        }

    }

    useEffect(() => {
       GetRoles()
    }, []);
    return (
        <>
            <Header />
            <div id="app">
                <section>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10">
                                <div id="sign-up" className="modal-content">
                                    <div class="modal-body">
                                        <h2 class="text-center">Inscription Agent immobilier</h2>
                                        <br/> 
                                        <form onSubmit={submitHandler} class="simple-form">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6">
                                                    <div class="form-group">
                                                        <div class="input-with-icon">
                                                            <input id="first_name" type="text" ref={LastNameInput}  required="required" autoFocus="autofocus" placeholder="Entrer votre prénom" class="form-control" />
                                                            <i class="bi bi-person"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            <div class="col-lg-6 col-md-6">
                                                <div class="form-group">
                                                    <div class="input-with-icon">
                                                        <input id="last_name" type="text" ref={FirstNameInput} required="required" placeholder="Entrer entrer votre nom" class="form-control" />
                                                        <i class="bi bi-person"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6">
                                                <div class="form-group">
                                                    <div class="input-with-icon"><input id="email" type="email" ref={EmailInput}  required="required" placeholder="Entrer votre adresse email" class="form-control" /> <i class="bi bi-envelope"></i></div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div class="form-group">
                                                    <select ref={sexInput} id="gender" class="form-control is-valid" aria-invalid="false" required>
                                                        <option>-- Selectioner votre genre --</option>
                                                        <option value="M">Homme</option>
                                                        <option value="F">Femme</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6">
                                                <div class="form-group">
                                                <label class="bi bi-phone"> Numéro de télephone</label>
                                                        <PhoneInput
                                                            defaultCountry="ci"
                                                            value={phone}
                                                            onChange={(phone) => setPhone(phone)}
                                                            required
                                                            placeholder="Entrez votre numéro"
                                                            inputClassName="form-control"
                                                            style={inputStyles}
                                                            className="form-control"
                                                        />
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6">
                                                <div class="form-group">
                                                    <div class="input-with-icon">
                                                        <input id="username" type="text" ref={nomProprietaireInput} required="required" placeholder="Entrer le nom de votre structure" class="form-control" />
                                                        <i class="bi bi-bank"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6">
                                                <div class="form-group">
                                                <label class="bi bi-phone"> Contact de paiements</label>
                                                        <PhoneInput
                                                            defaultCountry="ci"
                                                            value={contactPaiement}
                                                            onChange={(contactpaiement) => setContactPaiement(contactpaiement)}
                                                            required
                                                            inputClassName="form-control"
                                                            style={inputStyles}
                                                            className="form-control"
                                                        />
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-6 col-md-6">
                                                <div class="form-group">
                                                    <div class="input-with-icon">
                                                        <select class="form-control is-valid" aria-invalid="false" value={selectedRole} onChange={handleUserTypeChange}>
                                                        <option>-- Cliquez pour slectionner --</option>
                                                            {roles.map(role => (
                                                                <option key={role?.id} value={role?.libelle_role}>{ role?.libelle_role}</option>
                                                            ))}   
                                                        </select> 
                                                        <i class="bi bi-person"></i>
                                                    </div>
                                                </div>
                                                </div>
                                            <div class="col-lg-6 col-md-6">
                                                <div class="form-group">
                                                    <div class="input-with-icon">
                                                        <input id="password" type="password" ref={passwordInput} required="required" placeholder="Entrer votre mot de passe" class="form-control" />
                                                            <i class=" bi bi-lock"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6">
                                                <div class="form-group">
                                                    <div class="input-with-icon">
                                                        <input id="password-confirm" type="password" ref={passwordInput2} required="required" placeholder="Confirmer votre mot de passe" class="form-control" />
                                                        <i class="bi bi-lock"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                                <div className="form-group">
                                                    <button type="submit" className="btn btn-md full-width  btn-primary rounded" disabled={loading}>
                                                        {loading ? <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span> : null}
                                                        {loading ? "Inscription en cours..." : "Je m'inscrirs"}
                                                    </button>
                                                </div>
                                            <div className="form-group text-center">
                                                <p>Vous être  déja un agent immobilier sur Résidence IT?
                                                    <NavLink to="/auth/login" className="link d-block d-sm-inline-block text-sm-left text-center" style={{marginLeft : 4 + 'px'}}>  Connexion</NavLink>
                                                </p>
                                                
                                            </div>
                                            <div className="text-center"></div>
                                        </form>
        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </>
    )
}

export default RegisterAgent