import SideBarDashbord from "./common"
import Header from "../../components/Header/Header"
import Footer from "../../components/Footer/Footer"
import axios from "axios"
import dns from "../../utils/dns"
import React ,{useEffect, useState} from 'react'
import Swal from "sweetalert2"
import non_data from '../../public/img/non-data.png'
import { Link, useNavigate } from "react-router-dom";
import no_data from '../../public/img/non-data.png'
import DataTableVisites from "../../components/TableVisite"
import { useSelector } from 'react-redux';
import MessageComponent from "../../components/MessageScreen"
const SendMessage = () => {
    
    return (
        <>
            <Header />
            <div class="page-title">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <h1 class="ipt-title">Messagerie</h1>
                            <span class="ipn-subtitle"></span>
                        </div>
                    </div>
                </div>
            </div>
            <section className="gray-simple">
                <div className="container-fluid">
                    <div className="row">
                        <SideBarDashbord />
                        <div className="col-lg-8 col-md-12">
                            <div id="app">
                                <div className="row">
                                   <MessageComponent />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}


export default SendMessage