import { Link, useLocation } from "react-router-dom"
import axios from 'axios'
import React, { useEffect ,useState,useRef} from 'react'
import { useNavigate } from 'react-router-dom'
import dns from "../../utils/dns"
import user_picture from '../../public/img/user.png'
import Swal from "sweetalert2"
import { useSelector,useDispatch } from 'react-redux';
import { logout, setConnected,setUserToken } from "../../store/reducers/userSlice";
function isActive(routeName, location) {
  return location.pathname === routeName;
}

const SideBarDashbordAdmin = () => {
    const location = useLocation();
    const  Navigate = useNavigate()
    const userData = useSelector((state) => state.user.user);
    const token = useSelector((state) => state.user.token);
    const dispatch = useDispatch()
    const logoutHandler = () => { 
        axios.post(dns + `/api/auth/logout`)
        .then(res =>{
            if (res.status === 201) {
                dispatch(logout());
                dispatch(setUserToken(""));
                dispatch(setConnected({connected : 0}));
                Swal.fire({
                    title:'Deconnexion', 
                    text:`Vous avez été deconnecté avec succès`, 
                    icon: 'success', 
                    button:'OK',
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: () => {
                        Swal.showLoading()
                    },
                }).then(Navigate("/auth/login"))
               
            } else {
                 Swal.fire({
                    title:'Deconnexion', 
                    text:`Nus avons rencontrer un problème lors du traitement de votre requête`, 
                    icon: 'error', 
                    button:'OK',
                    timer: 3000
                })
            }
        })
        .catch(error => {
            console.error(error);
        })
        
    }

    useEffect(()=>{
       // console.log(userData)//FecthUserProfile()userData.libelle_role === "Proprietaire" ||Client/user/agent/visite/liste/
    },[])
    return (
        <>
            <div class="col-lg-3 col-md-12 ml-0">
                <div class="simple-sidebar sm-sidebar">
                    <div class="sidebar-widgets">
                        <div class="dashboard-navbar">
                            <div class="d-user-avater">
                                <img src={userData.picture ? userData.picture : user_picture } alt="Kouame corneille" className="img-fluid avater" style={{ width: 150 + 'px' }} />
                                <h4>{userData.username}</h4>
                                <span> <b>{userData.libelle_role}</b> </span>
                            </div>
                            <div class="d-navigation">
                                <ul>
                                    <li className={`${isActive('/admin/dashbord/', location) ? 'active' : ''}`}>
                                        <Link to='/admin/dashbord/'>
                                            <i class="bi bi-speedometer  mr1"></i>
                                            Tableau de bord
                                        </Link>
                                    </li>
                                    <li className={`${isActive('/admin/dashbord/logements/create', location) ? 'active' : ''}`}>
                                        <Link to="/admin/dashbord/logements/create">
                                            <i class="bi bi-plus-circle-fill"></i>
                                          Ajouter un logement
                                        </Link>
                                    </li>
                                    <li className={`${isActive('/admin/dashbord/biens-immobiliers/create', location) ? 'active' : ''}`}>
                                        <Link to="/admin/dashbord/biens-immobiliers/create">
                                            <i class="bi bi-plus-circle-fill"></i>
                                          Ajouter un bien immobilier
                                        </Link>
                                    </li>
                                    <li className={`${isActive('/admin/dashbord/users/create', location) ? 'active' : ''}`}>
                                        <Link to="/admin/dashbord/users/create">
                                            <i class="bi bi-plus-circle"></i>
                                          Ajouter un utilisateur
                                        </Link>
                                    </li>
                                    <li className={`${isActive('/admin/dashbord/etudiants/liste', location) ? 'active' : ''}`}>
                                        <Link to="/admin/dashbord/etudiants/liste">
                                            <i class="bi bi-people-fill"></i>
                                          Liste des Etudiants
                                        </Link>
                                    </li>
                                    <li className={`${isActive('/admin/dashbord/users/proprietaires/liste', location) ? 'active' : ''}`}>
                                        <Link to="/admin/dashbord/users/proprietaires/liste">
                                            <i class="bi bi-people-fill"></i>
                                          Liste des propietaires
                                        </Link>
                                    </li>
                                    <li className={`${isActive('/admin/dashbord/users/particuliers/liste', location) ? 'active' : ''}`}>
                                        <Link to="/admin/dashbord/users/particuliers/liste">
                                            <i class="bi bi-people-fill"></i>
                                          Liste des clients
                                        </Link>
                                    </li>
                                    {/* <li className={`${isActive('/admin/dashbord/roles/create', location) ? 'active' : ''}`}>
                                        <Link to="/admin/dashbord/roles/create">
                                            <i class="bi bi-shield-fill-plus"></i>
                                          Ajouter un role
                                        </Link>
                                    </li> */}
                                    {/* <li className={`${isActive('/admin/dashbord/roles/liste', location) ? 'active' : ''}`}>
                                        <Link to="/admin/dashbord/roles/liste">
                                            <i class="bi bi-shield-fill-plus"></i>
                                          Liste des roles
                                        </Link>
                                    </li> */}
                                    <li className={`${isActive('/admin/dashbord/logements/liste', location) ? 'active' : ''}`}>
                                        <Link to="/admin/dashbord/logements/liste">
                                           <i class="bi bi-houses-fill"></i>
                                          Liste des residences (etudiants)
                                        </Link>
                                    </li>
                                   
                                    
                                    <li className={`${isActive('/admin/dashbord/pannes/liste', location) ? 'active' : ''}`}>
                                        <Link to="/admin/dashbord/pannes/liste">
                                            <i class="bi bi-exclamation-circle-fill"></i>
                                          Liste des pannes (etudiants)
                                        </Link>
                                    </li>
                                    <li className={`${isActive('/admin/dashbord/reservations/liste', location) ? 'active' : ''}`}>
                                        <Link to="/admin/dashbord/reservations/liste">
                                            <i class="bi bi-list-stars"></i>
                                          Liste des reservations (etudiants)
                                        </Link>
                                    </li>
                                    <li className={`${isActive('/admin/dashbord/visites/liste', location) ? 'active' : ''}`}>
                                        <Link to="/admin/dashbord/visites/liste">
                                            <i class="bi bi-card-checklist"></i>
                                          Liste des visites (etudiants)
                                        </Link>
                                    </li>
                                    <li className={`${isActive('/admin/dashbord/bien-immobilier/liste', location) ? 'active' : ''}`}>
                                        <Link to="/admin/dashbord/bien-immobilier/liste">
                                           <i class="bi bi-houses-fill"></i>
                                          Liste des bien immobiliers (Proprietaires)
                                        </Link>
                                    </li>
                                    <li className={`${isActive('/admin/dashbord/reservations/bien-immobilier/', location) ? 'active' : ''}`}>
                                        <Link to="/admin/dashbord/reservations/bien-immobilier/">
                                            <i class="bi bi-list-stars"></i>
                                          Liste des biens réservés
                                        </Link>
                                    </li>
                                   
                                     <li className={`${isActive('/admin/dashbord/visites/logements/proprietaires/', location) ? 'active' : ''}`}>
                                        <Link to="/admin/dashbord/visites/logements/proprietaires/">
                                            <i class="bi bi-card-checklist"></i>
                                          Liste des visites (Proprietaires)
                                        </Link>
                                    </li>
                                     <li className={`${isActive('/admin/dashbord/reservations/logements/proprietaires/', location) ? 'active' : ''}`}>
                                        <Link to="/admin/dashbord/reservations/logements/proprietaires/">
                                            <i class="bi bi-card-checklist"></i>
                                          Liste des reservations (Proprietaires)
                                        </Link>
                                    </li>

                                    <li className={`${isActive('/admin/dashbord/adhesions/liste', location) ? 'active' : ''}`}>
                                        <Link to="/admin/dashbord/adhesions/liste">
                                            <i class="bi bi-card-checklist"></i>
                                          Liste des adhesions
                                        </Link>
                                    </li>
                                    {/* <li className={`${isActive('/user/update-password', location) ? 'active' : ''}`}>
                                        <Link to="/user/update-password">
                                            <i class="bi bi-lock"></i>
                                          Changer le mot de passe
                                        </Link>
                                    </li> */}
                                    {/* <li className={`${isActive('/', location) ? 'active' : ''}`}>
                                        <Link to="#">
                                            <i class="bi bi-bell"></i>
                                          Notifications (4)
                                        </Link>
                                    </li> */}
                                    {/* <li className={`${isActive('/user/chats/', location) ? 'active' : ''}`}>
                                        <Link to="/user/chats/">
                                            <i class="bi bi-chat-dots-fill"></i>
                                          Messages (2)
                                        </Link>
                                    </li> */}
                                    {/* <li className={`${isActive('/', location) ? 'active' : ''}`}>
                                        <Link to="#">
                                            <i class="bi bi-cash-coin"></i>
                                          Historique de paiments
                                        </Link>
                                    </li> */}
                                    <li>
                                        <a className="no-underline mr2 black-50 hover-black-70 pv1 ph2 db" href="#"  title="Logout">
                                            <i class="bi bi-box-arrow-right mr1"></i>
                                            <span onClick={() => logoutHandler}>Déconnexion</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default SideBarDashbordAdmin;
