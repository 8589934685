const MessageComponent = () => {
    return (
        <>
            <div id="reviewWrapper">
                <div class="property_block_wrap style-2">
                    <div class="property_block_wrap_header">
                        <a data-bs-toggle="collapse" data-parent="#comment" data-bs-target="#clTen" aria-controls="clTen" href="javascript:void(0);" aria-expanded="true">
                            <h4 class="property_block_title">Ecrivez un message</h4>
                        </a>
                    </div>
                    <div id="clTen" class="panel-collapse collapse show">
                        <div class="block-body">
                            <form  accept-charset="UTF-8" class="form--review-product">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <div class="form-group">
                                            <select  id="" class="form-control">
                                                <option value="">Selectionner un utilisateur</option>
                                                <option value="">Kouame</option>
                                           </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <div class="form-group">
                                            <textarea name="comment" class="form-control ht-80" placeholder="Ecrivez votre message ici....." ></textarea>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <div class="form-group">
                                            <button class="btn btn-theme-light-2 rounded" type="submit" >Envoyer le message</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MessageComponent;