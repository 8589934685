import { useEffect,useRef, useState } from "react";
import { NavLink,useNavigate,useLocation} from "react-router-dom"
import logo from '../../public/img/logo.png'
import Swal from "sweetalert2";
import axios from "axios";
import { useSelector,useDispatch} from 'react-redux';
import { logout, setConnected,setUserToken } from "../../store/reducers/userSlice";
import dns from "../../utils/dns";
//import logo from "../../storage/general/logo.png"
import Notification from "../../notification";
function isActive(routeName, location) {
  return location.pathname === routeName;
}

const Header = () => {
    const toggle = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch()
    const userData = useSelector((state) => state.user.user);
    const token = useSelector((state) => state.user.token);
    const Navigate = useNavigate()
    const location = useLocation();
    const handleClick = () => {
        setIsOpen(true);
    };
    const handleClickClose = () => {
        setIsOpen(false);
    };

    const logoutHandler = () => { 
        axios.post(dns + `/api/auth/logout`)
        .then(res => {
            console.log(res)
            if (res.status === 200) {
                dispatch(logout());
                dispatch(setUserToken(""));
                dispatch(setConnected({connected : 0}));
                Swal.fire({
                    title:'Deconnexion', 
                    text:`Vous avez été deconnecté avec succès`, 
                    icon: 'success', 
                    button:'OK',
                    timer: 3000
                })
                Navigate("/auth/login")
            } else {
                 Swal.fire({
                    title:'Deconnexion', 
                    text:`Nous avons rencontrer un problème lors du traitement de votre requête`, 
                    icon: 'error', 
                    button:'OK',
                    timer: 3000
                })
            }
        })
        .catch(error => {
            console.log(error)
        })
        
    }
    
        useEffect(() => {
            ///
        }, [] 
        );
    return (
        <>
            <div id="main-wrapper" >
                <div className="topbar bg-brand p-2 d-none d-sm-block">
                    <div className="container">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="topbar-right d-flex align-items-center">
                                <div className="topbar-wishlist">
                                    {/* <a class="text-white" href="#"><i class="fas fa-heart"></i> Wishlist(<span class="wishlist-count">0</span>)</a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header header-light head-shadow">
                    <div className="container">
                        <input type="checkbox" hidden id="nav_check" />
                        <Notification />
                        <nav id="navigation" className="navigation navigation-landscape">
                            <div className="nav-header">
                                <NavLink to='/' className="nav-brand">
                                    <img className="logo" src={logo} alt=""/>
                                </NavLink>  
                            <div className="nav-toggle" ref={toggle}  onClick={handleClick}></div>
                            </div>
                                <div className={`nav-menus-wrapper ${isOpen ? 'nav-menus-wrapper-open' : ''}`} style={{ transitionProperty: 'left' }}>
                                    <span className="nav-menus-wrapper-close-button" onClick={handleClickClose}>✕</span>
                                <ul  className="nav-menu">
                                    <li className="menu-item-has-children">
                                        <NavLink to='/' className={`${isActive('/', location) ? 'active_menu' : ''}`}> ACCUEIL</NavLink>
                                    </li>
                                    <li className="menu-item-has-children">
                                        <NavLink to='/ours-residences' className={`${isActive('/ours-residences', location) ? 'active_menu' : ''}`}> RESIDENCES</NavLink>
                                    </li>
                                    <li className="menu-item-has-children">
                                        <NavLink to='/nos-hotels' className={`${isActive('/nos-hotels', location) ? 'active_menu' : ''}`}> HOTELS</NavLink>
                                    </li>
                                    <li className="menu-item-has-children">
                                        <NavLink to='/residence-etudiants' className={`${isActive('/residence-etudiants', location) ? 'active_menu' : ''}`}> ETUDIANTS</NavLink>
                                    </li>
                                    <li className="menu-item-has-children">
                                        <NavLink to='/biens-immobiliers' className={`${isActive('/biens-immobiliers', location) ? 'active_menu' : ''}`}> IMMOBILIER</NavLink>
                                    </li>
                                    {/* <li class="menu-item-has-children">
                                            <NavLink to='/about-us'> A PROPOS</NavLink>
                                    </li> */}
                                    <li className="menu-item-has-children">
                                        <NavLink to="/contact-us" className={`${isActive('/contact-us', location) ? 'active_menu' : ''}`}>
                                            CONTACT
                                        </NavLink>
                                    </li>
                                    <li  className="menu-item-has-children">
                                        <NavLink to='/about-us' className={`${isActive('/about-us', location) ? 'active_menu' : ''}`}>A PROPOS</NavLink>
                                    </li>
                                </ul>
                                <ul className="nav-menu nav-menu-social align-to-right">
                                    {token !== "" ? (
                                        // L'utilisateur est connecté, affichez le lien de déconnexion
                                        <>
                                            {userData.libelle_role === "Admin" ? (
                                            <li>
                                                <NavLink to="/admin/dashbord/" className="text-success mr-2">
                                                    Mon compte
                                                </NavLink>
                                            </li>
                                            ) :
                                            (<li className="nav-submenu-open">
                                                <NavLink to="/user/profile" className="text-success mr-2">
                                                    Mon compte
                                                </NavLink>
                                                </li>
                                            )}
                                            
                                            <li className="add-listing">
                                            
                                                <NavLink to="#" onClick={logoutHandler}>
                                                <i className="bi bi-box-arrow-right mr-3"></i>
                                                    Déconnexion
                                                </NavLink>
                                            </li>
                                        </>
                                    ) : (
                                        // L'utilisateur n'est pas connecté, affichez les liens de connexion et d'inscription
                                        <>
                                            <li>
                                                <NavLink to="/auth/login" className="text-success mr-2">
                                                    Se connecter
                                                </NavLink>
                                            </li>
                                            <li className="add-listing">
                                                <NavLink to="/auth/register">
                                                    <i className="bi bi-person-fill-add m-1"></i>
                                                    S'enregistrer
                                                </NavLink>
                                            </li>
                                        </>
                                    )}
                                </ul>
                                <div className="clearfix"></div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>        
        </>
    )
}

export default Header