import DataTableLogementsAdmin from "./TableLogements";
import Header from "../../../../components/Header/Header"
import Footer from "../../../../components/Footer/Footer"
import PageTitle from "../../components/PageTitle"
import SideBarDashbordAdmin from "../../Sidebar";
import { useState, useEffect } from "react";
import dns from "../../../../utils/dns";
import axios from "axios"
import { useSelector } from "react-redux";
export default function ListeAdhesionsAdmin(){
    const [logements, SetLogements] = useState([])
    const token = useSelector((state) => state.user.token);
    

    useEffect(() => {
        const GetResidences = async () => {
            try {
                const response = await axios.get(dns +'/api/logement/', {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                });
                const sortedData = response.data.filter((logement)=>logement.libelletypelogement === "Etudiants").sort((a, b) => {
                    // Convertir les dates en objets Date pour pouvoir les comparer
                    const idA = a.id
                    const idB = b.id
                    
                    // Comparer les dates et retourner le résultat du tri
                    return idB - idA;
                });
                SetLogements(sortedData);
            } catch (error) {
                console.error(error);
            }
        };
    
        GetResidences();
    }, [token]);  // Removed logements from the dependency array
    
    return (
        <>
            <Header />
                <PageTitle title={"Liste des logements (etudiants)"}/>
                <section className="bg-light">
                    <div className="container-fluid">
                        <div className="row">
                            <SideBarDashbordAdmin />
                            <div class="col-lg-8 col-md-12">
                                <div id="app">
                                    <DataTableLogementsAdmin Logements={logements} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            <Footer />
        </>
    )
}