import { SpinnerCircular } from 'spinners-react'
import React, {useState} from 'react'

const LoadingSpinnerCircular = () => {
    const [loading, SetLoading] = useState(true);
    
    return (
        <>
            {loading ? <div className="m-0 d-flex h-screen justify-content-center align-items-center  w-screen">
                <SpinnerCircular speed={100} size={100} color="blue" />
            </div> : ""}
        </>
    )
}

export default LoadingSpinnerCircular