const clearFormFields = (formRef) => {
  if (formRef.current) {
    const formElements = formRef.current.elements;
    for (let i = 0; i < formElements.length; i++) {
      const element = formElements[i];
      if (element.tagName === "INPUT" || element.tagName === "SELECT" || element.tagName === "TEXTAREA") {
        if (element.type === "checkbox" || element.type === "radio") {
          element.checked = false; // For checkboxes and radio buttons
        } else {
          element.value = ""; // For input, select, and textarea fields
        }
      }
    }
  }
};

export default clearFormFields;

