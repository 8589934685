import Header from "../../../components/Header/Header"
import Footer from "../../../components/Footer/Footer"
import SideBarDashbord from "../common"
import React, {useRef, useState,useEffect} from 'react'
import Swal from 'sweetalert2';
import axios from 'axios'
import dns from "../../../utils/dns";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import 'leaflet/dist/leaflet.css'; // Import Leaflet CSS
import 'leaflet-draw/dist/leaflet.draw.css'; // Import Leaflet Draw CSS
import './map.css'


const AddBiensAgent = () => {
    
    const userData = useSelector((state) => state.user.user);
    const token = useSelector((state) => state.user.token);
    const [fileList, setFileList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [typesbiens, setTypeBiens] = useState([]);
    const Navigate = useNavigate()
    const DescriptionInput = useRef(null)
    const CommuneInput = useRef(null)
    const LocalisationInput = useRef(null)
    const TypelogementInput = useRef(null)
    const TarifInput = useRef(null)
    const StatutLogementInput = useRef(null)

    const onFileDrop = (e) => {
        const newFile = e.target.files[0];
        setFileList(newFile);
        
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        setLoading(true)
        const formdata = new FormData()
        const uploadedFile =  fileList[0] ?? null;
        const uploadedFile1 = fileList[1] ?? null;
        const uploadedFile2 = fileList[2] ?? null;
        const uploadedFile3 = fileList[3] ?? null;
        const uploadedFile4 = fileList[4] ?? null;
        
        formdata.append("agent_immobilier", userData.id)
        formdata.append("surface", CommuneInput.current.value)
        formdata.append("type_bien", TypelogementInput.current.value)
        formdata.append("localisation", LocalisationInput.current.value)
        formdata.append("prix", TarifInput.current.value)
        formdata.append("est_disponible", StatutLogementInput.current.value)
        formdata.append("description", DescriptionInput.current.value)

        if(uploadedFile){
            formdata.append("image_bien1",  uploadedFile)
        }
        if(uploadedFile1){
            formdata.append("image_bien2", uploadedFile1)
        }
        if (uploadedFile2){
            formdata.append("image_bien3", uploadedFile2)
        }
        if (uploadedFile3){
            formdata.append("image_bien4", uploadedFile3)
        }
        if (uploadedFile4){
            formdata.append("image_bien5", uploadedFile4)
        }
        
        if (formdata && token) {
            try {
                const response = await axios.post(dns + `/api/biens/`, formdata, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${token}`,
                    },
                })

                if (response.status === 201) {
                    setLoading(false)
                    Swal.fire({
                        title:'Enregistrerment de bien', 
                        text:`Le bien à été enregistrer avec succès`, 
                        icon: 'success', 
                        button:'OK',
                        timer: 3000,
                        timerProgressBar:true,
                        didOpen:()=>{
                            Swal.showLoading()
                        }
                    })
                    Navigate('/user/agent/biens-immobiliers/liste/')
                } else {
                    setLoading(false)
                    Swal.fire({
                        title:'Enregistrerment de bien', 
                        text:`Impossible d'enregistrer votre bien pour le moment`, 
                        icon: 'error', 
                        button:'OK',
                        timer: 3000
                    })
                }
            } catch (error) {
                setLoading(false)
                Swal.fire({
                    title:'Enregistrerment de bien', 
                    text:`Le serveur à rencontrer un problème lors du traitement de votre requête `, 
                    icon: 'error', 
                    button:'OK',
                    timer: 4000
                })
            }
        }
    }

    
   
   useEffect(() => {
    const GetTypeLogemnts = async () => {
        
        try {
            const response = await axios.get(dns + `/api/type-biens/`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            if (response.status === 200) {
                setTypeBiens(response.data)
            }
        } catch (error) {
            console.error(error);
        }
    }
       
    GetTypeLogemnts()
    
}, [typesbiens]); 

    return (

        <>
            <Header />
            <div className="page-title">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <h1 className="ipt-title">Ajouter un bein</h1>
                            <span className="ipn-subtitle"></span>
                        </div>
                    </div>
                </div>
            </div>
            <section className="bg-light">
                <div className="container-fluid">
                    <div className="row">
                        <SideBarDashbord />
                        <div class="col-lg-7 col-md-12">
                            <div id="app" >
                                <div class="dashboard-wraper1">
                                    <form class="js-base-form dirty-check" enctype="multipart/form-data" onSubmit={handleSubmit}>
                                        <div class="row">
                                            <div class="col-md">
                                                <div class="dashboard-wraper">
                                                    <div class="main-form">
                                                    <div class="form-body">
                                                        <div class="form-group mb-3">
                                                            <label for="images" class="control-label">Type de bien</label>
                                                                <div  class="dropzone needsclick dz-clickable">
                                                                    <div class="dz-message needsclick">
                                                                        <select class="form-control ui-select is-valid" required ref={TypelogementInput} id="type_id" aria-invalid="false">
                                                                            {typesbiens.map((bien) => (
                                                                                <option data-code="sale" value={bien.id}>{ bien.libelle}</option>
                                                                            ))}
                                                                        </select>    
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form-group mb-3">
                                                            <label for="images" class="control-label">Surface</label>
                                                                <div  class="dropzone needsclick dz-clickable">
                                                                    <div class="dz-message needsclick">
                                                                        <input type="text" ref={CommuneInput} class="form-control" placeholder='Entrer la surface du bien en m²'/>    
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form-group mb-3">
                                                                <label for="description" class="control-label">Description</label>
                                                                <textarea class="form-control" rows="4" required placeholder="Description du bien" data-counter="350" ref={DescriptionInput} cols="50" id="description"></textarea>
                                                            </div>
                                                            <div class="form-group mb-3">
                                                            <label for="images" class="control-label">Selectionner les images *</label>
                                                                <div id="multiple-upload" class="dropzone needsclick dz-clickable">
                                                                    <div class="dz-message needsclick">
                                                                        <input type="file" required multiple id="" class="form-control" accept="image/*" onChange={onFileDrop} />    
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                                <div class="form-group mb-3">
                                                                <label for="images" class="control-label">Localisation</label>
                                                                    <div  class="dropzone needsclick dz-clickable">
                                                                        <div class="dz-message needsclick">
                                                                            <input type="text" ref={LocalisationInput} required class="form-control" placeholder='Entrer la localisation ex:koumassi, ramblaie'/>    
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            
                                                            <div class="form-group mb-3">
                                                            <label for="images" class="control-label">Prix</label>
                                                                <div  class="dropzone needsclick dz-clickable">
                                                                    <div class="dz-message needsclick">
                                                                        <input type="number" ref={TarifInput} required class="form-control" placeholder='Entrer le prix du bien'/>    
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form-group mb-3">
                                                            <label for="images" class="control-label">Statut du bein</label>
                                                                <div  class="dropzone needsclick dz-clickable">
                                                                    <div class="dz-message needsclick">
                                                                        <select class="form-control ui-select is-valid" defaultValue={true} ref={StatutLogementInput} id="type_id" aria-invalid="false">
                                                                            <option  value={true}>Disponible</option>
                                                                            <option  value={false}>Indisponible </option>
                                                                        </select>    
                                                                    </div>
                                                                </div>
                                                        </div>
                                                        <button type="submit" className="btn btn-primary fw6 mt-4" disabled={loading}>
                                                             {loading ? <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span> : null}
                                                            {loading ? "Enregistrement en cours..." : "Enregistrer"}
                                                        </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>   
            <Footer />
        </>
    )
}

export default AddBiensAgent;