// store.js
import { configureStore } from '@reduxjs/toolkit';
import userReducer from './reducers/userSlice'; // Create this slice
import thunk from 'redux-thunk'
import AsyncStorage from '@react-native-async-storage/async-storage';
import { persistStore, persistReducer } from 'redux-persist';

const persistConfig = {
  key : 'root',
  storage: AsyncStorage,
}

const persistedReducer = persistReducer(persistConfig, userReducer);

const store = configureStore({
  reducer: {
    user: persistedReducer,
    // Add other reducers here if needed
  },
  middleware:[thunk]
});

export const persistor = persistStore(store);

export default store;
