import  {Link } from 'react-router-dom'
const HotelItemOne = ({ hotel }) => {
    return (
        <>
            <div className="col-lg-6 col-md-12" key={hotel.id}>
                <div className="property-listing property-1">
                    <div className="listing-img-wrapper">
                        <Link to={`/details-residence/${hotel.id}`}>
                            <img src={hotel.image_logement} data-src="https://resido.thesky9.com/storage/properties/p-3-400xauto.jpg" alt="2318 Camryns Crossing" className="img-fluid mx-auto lazy entered loaded" data-ll-status="loaded" /></Link> 
                        <div className="icon-actions-wrapper"><a href="JavaScript:Void(0);" data-id="16" className="add-to-wishlist"><i className="far fa-heart"></i>
                        </a>
                        </div>
                    </div>
                    <div className="listing-content">
                        <div className="listing-detail-wrapper-box">
                        <div className="listing-detail-wrapper">
                            <div className="listing-short-detail">
                                <h4 className="listing-name">
                                    <Link to={`/details-residence/${hotel.id}`} >
                                        {hotel.commune_logement + "    /"}
                                    </Link>
                                    {hotel.nombre_pieces ? hotel.nombre_pieces : "x"} pièces
                                </h4>
                            </div>
                            <div className="list-price">
                                <div>
                                    <div data-star="3.15" className="rating_wrap">
                                    </div>
                                    <span className="prt-types sale">{hotel.statut_logement}</span>
                                </div>
                                <h6 className="listing-card-info-price"> {hotel.tarif_mensuel.toString().replace(".00", "")} cfa </h6>
                            </div>
                        </div>
                        </div>
                        <div class="price-features-wrapper">
                        <div class="list-fx-features">
                            {/* <div class="listing-card-info-icon">
                                <div class="inc-fleat-icon">
                                    {hotel.is_male ? <i class="bi bi-gender-male"></i> : <i class="bi bi-gender-female"></i>}
                                </div>
                                {hotel.is_male===true ? "Résidence Homme" : "Résidence Femme"}
                            </div> */}
                            {/* <div class="listing-card-info-icon">
                                <div class="inc-fleat-icon">
                                    <img src="https://resido.thesky9.com/themes/resido/img/move.svg" alt="" width="13" />
                                </div>
                                245 m²
                            </div> */}
                        </div>
                        </div>
                        <div className="listing-footer-wrapper">
                            <div class="footer-first">
                                <div class="foot-location d-flex">
                                    <img
                                        src="https://resido.thesky9.com/themes/resido/img/pin.svg" alt="Hampton, Virginia"
                                        width="18" />
                                    {hotel.quartier_logement}
                                </div>
                            </div>
                            <div className="listing-detail-btn">
                                <Link to={`/details-residence/${hotel.id}`} className="more-btn">Voir</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HotelItemOne