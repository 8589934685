import SideBarDashbord from "./common"
import Header from "../../components/Header/Header"
import Footer from "../../components/Footer/Footer"
import axios from 'axios'
import React, { useEffect ,useState,useRef} from 'react'
import { useNavigate } from 'react-router-dom'
import dns from "../../utils/dns"
import Swal from 'sweetalert2'
import clearFormFields from "../../utils/cleanform"
import { useSelector } from 'react-redux';

const Pannes = () => {
    const Navigate = useNavigate();
    const formRef = useRef(null);
    const [typepannes, SetTypePannes] = useState([]);
    const [adhesions, SetAdhesions] = useState([]);
    const userData = useSelector((state) => state.user.user);
    const token = useSelector((state) => state.user.token);
    const Type_pannesInput = useRef(null);
    const descriptionInput = useRef(null);
    const LogementInput = useRef(null);

    const handleSubmit = async (e) => { 
        e.preventDefault();
        const type_panne = Type_pannesInput.current.value;
        const description_panne = descriptionInput.current.value;
        const formData = new FormData();
        formData.append('client_id', userData.id);
        formData.append('type_panne_id', type_panne);
        formData.append('logement_id', LogementInput.current.value);
        formData.append('description_panne', description_panne);
        formData.append('statut_panne', "en_attente");
        try {
            axios.post(dns + `/api/pannes/`,formData, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                }
            })
            .then(res => {
                if (res.status === 201) {
                    clearFormFields(formRef)
                    Swal.fire({
                        title: 'Signaler une panne',
                        text: `Votre panne à été signaler avec succès à l'administrateur`,
                        icon: 'success',
                        button: 'OK',
                        timer: 3000
                    })
                    .then(Navigate("/user/liste-pannes/"));
                } else {
                    Swal.fire({
                        title:'Signaler une panne', 
                        text:`Nous avons rencontré un problème lors du traitement de votre requête`, 
                        icon: 'error', 
                        button:'OK',
                        timer: 3000
                    })
               }
            })
            .catch(error => { 
                console.error(error.message);
            })
        } catch (error) {
        console.error(error);
        }
    }

    const GetTypePannes = async () => {
        axios.get(dns + `/api/type-pannes/`,{
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        })
        .then(res => {
                if (res.status === 200) {
                    SetTypePannes(res.data)                    
                }
        })
        .catch(error => {
            console.log(error)
        })

    }

    const GetUserAdhesions = () => {
        ///adhesion/{id}/adhesions/
        axios.get(dns + `/api/adhesion/${userData.id}/adhesions/`,{
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        })
        .then(res => {
                if (res.status === 200) {
                    SetAdhesions(res.data)  
                    console.log(res.data)
                }
        })
        .catch(error => {
            console.log(error)
        })
    }
    useEffect(() => { 
        GetTypePannes();
        GetUserAdhesions();
    }, [])

    return (
        <>
            <Header />
            <div className="page-title">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <h1 className="ipt-title">Signaler une panne</h1>
                            <span className="ipn-subtitle"></span>
                        </div>
                    </div>
                </div>
            </div>
             <section className="bg-light">
                <div className="container-fluid">
                    <div className="row">
                            <SideBarDashbord />
                            <div className="col-lg-8 col-md-12">
                                <div id="app">
                                    <div className="dashboard-wraper settings crop-avatar">
                                        <div className="form-submit">
                                            <div className="row">
                                            <div className="col-12">
                                                <h4 className="with-actions">Veuillez remplir le formulaire</h4>
                                            </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-8 order-lg-0">
                                                    <form onSubmit={handleSubmit} noValidate ref={formRef}>
                                                        <div className="form-group">
                                                            <label htmlFor="pannes">Types de pannes</label>
                                                            <select  id="pannes" ref={Type_pannesInput} className="form-control is-valid" aria-invalid="false">
                                                            <option>Type de panne</option>
                                                            {typepannes.map((type) => (
                                                                <option value={type.id}>{type.libelle_type_panne} </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                            <label htmlFor="pannes">Logement</label>
                                                            <select  id="pannes" ref={LogementInput} className="form-control is-valid" aria-invalid="false">
                                                            {adhesions.map((adhesion) => (
                                                                <option value={adhesion.id}>{adhesion.logement.quartier_logement} </option>
                                                            ))}
                                                        </select>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="comment">Description de la panne</label>
                                                            <textarea ref={descriptionInput} id="comment" rows="5" placeholder="je décris la panne.." className="form-control" ></textarea>
                                                        </div>
                                                        <button type="submit" className="btn btn-primary fw6">Signaler  la panne</button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Pannes;