import { Link } from 'react-router-dom'
import dns from '../../utils/dns'
const BienSreen = ({ bien }) => {
    return (
        <>
            <div className="col-lg-6 col-md-12" key={bien?.id}>
                <div className="property-listing property-1">
                    <div className="listing-img-wrapper">
                        <Link to={`/biens-immobiliers/${bien?.id}/details/`}>
                            <img src={bien?.image_bien1} data-src={bien?.image_bien1} alt="" className="img-fluid mx-auto lazy entered loaded" data-ll-status="loaded" />
                        </Link> 
                    </div>
                    <div className="listing-content">
                        <div className="listing-detail-wrapper-box">
                        <div className="listing-detail-wrapper">
                            <div className="listing-short-detail">
                                <h4 className="listing-name">
                                    <Link to={`/biens-immobiliers/${bien?.id}/details/`} >
                                        {bien?.type_bien?.libelle}
                                    </Link>
                                </h4>
                            </div>
                            <div className="list-price">
                                <div>
                                    <div data-star="3.15" className="rating_wrap">
                                        </div>
                                        {bien?.est_disponible ? <span className="prt-types rent">Disponible</span>:  <span className="prt-types sale">Indisponible</span>}
                                        {/* <span className="prt-types rent">{bien?.est_disponible ? "Disponible": "Indisponible"}</span> */}
                                        {/* <span className="prt-types sale">Disponible</span> */}
                                </div>
                                <h6 className="listing-card-info-price"> {bien?.prix?.toString().replace(".00", "")} cfa </h6>
                            </div>
                        </div>
                        </div>
                        <div class="price-features-wrapper">
                        <div class="list-fx-features">
                            
                            <div class="listing-card-info-icon">
                                <div class="inc-fleat-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrows-move" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M7.646.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 1.707V5.5a.5.5 0 0 1-1 0V1.707L6.354 2.854a.5.5 0 1 1-.708-.708l2-2zM8 10a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 14.293V10.5A.5.5 0 0 1 8 10M.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L1.707 7.5H5.5a.5.5 0 0 1 0 1H1.707l1.147 1.146a.5.5 0 0 1-.708.708l-2-2zM10 8a.5.5 0 0 1 .5-.5h3.793l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L14.293 8.5H10.5A.5.5 0 0 1 10 8"/>
                                </svg>
                                </div>
                                {bien?.surface?.toString().replace(".00", "")} m²
                            </div>
                        </div>
                        </div>
                        <div className="listing-footer-wrapper">
                            <div class="footer-first">
                                <div class="foot-location d-flex">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
                                    <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10"/>
                                    <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
                                </svg>
                                    {bien?.localisation}
                                </div>
                            </div>
                            <div className="listing-detail-btn">
                                <Link to={`/biens-immobiliers/${bien?.id}/details/`} className="more-btn">Voir</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BienSreen